import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import GravityFormForm from "../../gravity-forms"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import { trackFormSubmit } from "../../../services/gtm"

const useStyles = makeStyles(theme => styles(theme))

export const GravityformsFormBlock = props => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query formData {
      allGfForm {
        edges {
          node {
            ...GravityFormComponent
          }
        }
      }
      wp {
        themeOptions {
          ThemeOptions {
            forms {
              style
              shape
              fieldColour
            }
          }
        }
      }
    }
  `)

  const formTitle = data.allGfForm.edges.find(
    form => form.node.formId === Number(props.attributes.formId)
  ).node.title

  return (
    <div
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.gravityForms}`}
      data-form-style={
        data.wp.themeOptions.ThemeOptions.forms.style !== "base"
          ? data.wp.themeOptions.ThemeOptions.forms.style
          : null
      }
      data-form-shape={
        data.wp.themeOptions.ThemeOptions.forms.shape !== "base"
          ? data.wp.themeOptions.ThemeOptions.forms.shape
          : null
      }
      data-override-form-field-color={
        data.wp.themeOptions.ThemeOptions.forms.fieldColour !== "background"
          ? data.wp.themeOptions.ThemeOptions.forms.fieldColour
          : "background"
      }
    >
      <GravityFormForm
        id={Number(props.attributes.formId)}
        formData={data.allGfForm}
        lambda={process.env.GATSBY_LAMBDA_ENDPOINT_URL}
        successCallback={() => {
          trackFormSubmit(formTitle)
        }}
        errorCallback={() => {}}
      />
    </div>
  )
}

GravityformsFormBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

// This is the GraphQL Fragment that will be used within Gatsby queries
export const GravityformsFormBlockFragment = graphql`
  fragment GravityformsFormBlock on WpBlock {
    ... on WpGravityformsFormBlock {
      name
      attributes {
        __typename
        ... on WpGravityformsFormBlockAttributes {
          formId
        }
      }
    }
  }
`

export const GravityformsFormBlockPreview = `
  ... on GravityformsFormBlock {
    name
    attributes {
        __typename
        ...on GravityformsFormBlockAttributes {
          formId
        }
      }
  }
  `
