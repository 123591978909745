export default theme => ({ // eslint-disable-line
    statBreakdown: {
        backgroundColor: theme.palette.tertiary.main,
        margin: '0 auto',
        padding: '50px 0',
        [theme.breakpoints.up('md')]: {
            padding: '125px 0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '250px 0',
        },
        '& .wrapper': {
            '& > .MuiTypography-root': {
                marginBottom: 32,
                [theme.breakpoints.up('md')]: {
                    maxWidth: '66.666%',
                    margin: '0 auto 32px',   
                }
            },
            '& > p': {
                marginBottom: 32,
                [theme.breakpoints.up('md')]: {
                    maxWidth: '66.666%',
                    margin: '0 auto',  
                    marginBottom: 100,
                },
            },
            '& .stats': {
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                flex: '0 0 100%',
                maxWidth: '100%',
                '& > div': {
                    flex: '0 0 50%',
                    maxWidth: '50%',
                    [theme.breakpoints.up('md')]: {
                        flex: '1 0 25%',
                        maxWidth: '25%',
                    },
                    '& .number': {
                        fontFamily: theme.headerFont,
                        fontWeight: 'bold',
                        display: 'block',
                        marginBottom: 12,
                        textAlign: 'center',
                        fontSize: 32,
                        lineHeight: 1.25,
                        [theme.breakpoints.up('md')]: {
                            marginBottom: 24,
                        },
                        [theme.breakpoints.up('lg')]: {
                            fontSize: 48,
                            lineHeight: 1.08,
                        }
                    }
                }
            }
        },
    }
})