
export default theme => ({ // eslint-disable-line
    bannerStackedBlock: {
        backgroundColor: theme.palette.primary.main,
        padding: '50px 0',
        [theme.breakpoints.up('md')]: {
            padding: '100px 0 80px',
        },
        '& .wrapper': {
            flexWrap: 'nowrap',
            flexDirection: 'column',
            justifyContent: 'center',
            '& .MuiTypography-root': {
                color: theme.palette.primary.contrastText,
                [theme.breakpoints.up('md')]: {
                    maxWidth: '83.333%',
                    margin: '0 auto 40px'
                }
            },
            '& p': {
                color: theme.palette.primary.contrastText,
                [theme.breakpoints.up('md')]: {
                    maxWidth: '66.666%',
                    margin: '0 auto 40px'
                }
            },
            '& > a': {
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 40,
            },
            '& [data-gatsby-image-wrapper]': {
                margin: '0 12px 0',
                [theme.breakpoints.up('md')]: {
                    marginTop: 80,
                }
            }
        }
    },
})