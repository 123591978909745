import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import Slider from "react-slick/lib";
import styles from "./styles";

const useStyles = makeStyles(theme => styles(theme));

export const BblBannerMessageSliderBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    const data = useStaticQuery(graphql`
    query bannerMessageSliderBlock {
        wp {
            themeOptions {
                ThemeOptions {
                    bannerMessageSlider {
                        borderColour
                        headerColour
                        paragraphColour                    
                    }
                }
            }
        }
    }`);

    let overrides = data.wp.themeOptions.ThemeOptions.bannerMessageSlider;

    const settings = {
        dots: true,
        infinite: true,
        centerMode: false,
        // lazyLoad: true,
        slidesToScroll: 1,
        variableWidth: false,
        arrows: false,
        // focusOnSelect: true,
    }

    return <div className={`${props.attributes.className ? props.attributes.className : ''} ${classes.bannerSlider}`}

        data-override-background={overrides.borderColour !== 'background' ? overrides.borderColour : null}

    >
        <Slider {...settings}>
            {blocks.map((block) => {
                return block;
            })}
        </Slider>
    </div>
};

BblBannerMessageSliderBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const BblBannerMessageSliderBlockFragment = graphql`
    fragment BblBannerMessageSliderBlock on WpBlock {
        ... on WpBblBannerMessageSliderBlock {
            name
            attributes {
                __typename
                ... on WpBblBannerMessageSliderBlockAttributes {
                    className
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblBannerMessageSliderBlockPreview = `
    ... on BblBannerMessageSliderBlock {
        name
        attributes {
            __typename
            ... on BblBannerMessageSliderBlockAttributes {
                className
            }
        }
    }
`;