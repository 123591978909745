export default theme => ({ // eslint-disable-line
    header: {
        display: 'block',
        position: 'relative',
        marginTop: 0,
        maxWidth: 1142,
        width: '100%',
        marginLeft: "auto",
        marginRight: "auto",
        fontFamily: theme.headerFont,
        padding: '0 12px',
        fontWeight: 'bold',
        '&.MuiTypography-h1': {
            fontSize: 48,
            lineHeight: 1.16,
            marginBottom: 8,
            [theme.breakpoints.up('lg')]: {
                fontSize: 68,
                lineHeight: 1.05,
            }
        },
        '&.MuiTypography-h2': {
            fontSize: 32,
            lineHeight: 1.25,
            marginBottom: 8,
            [theme.breakpoints.up('lg')]: {
                fontSize: 48,
                lineHeight: 1.08,
            }
        },
        '&.MuiTypography-h3': {
            fontSize: 28,
            lineHeight: 1.333,
            marginBottom: 12,
            [theme.breakpoints.up('lg')]: {
                fontSize: 32,
                lineHeight: 1.5
            }
        },
        '&.MuiTypography-h4': {
            fontSize: 22,
            lineHeight: 1.45,
            marginBottom: 12,
            [theme.breakpoints.up('lg')]: {
                fontSize: 24,
                lineHeight: 1.33,
            }
        },
        '&.MuiTypography-h5': {
            fontSize: 16,
            lineHeight: 1.5,
            marginBottom: 16,
            [theme.breakpoints.up('lg')]: {
                fontSize: 18,
                lineHeight: 1.33,
            }
        },
        '&.MuiTypography-h6': {
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: '0.032em',
            marginBottom: 16,
        },
        '& a': {
            textDecoration: 'underline !important',
            color: theme.palette.accent.main,
            '&:hover, &:focus': {
                color: theme.palette.accent.dark,
            }
        },
        // Background colours
        '&:not([data-override-background="initial"])': {padding: 12},
    },
    headerTheme: {
        ...theme.headerTheme,
    }
})