export default theme => ({ // eslint-disable-line
    fiftyStackedContent: {
        backgroundColor: theme.palette.background.main,
        padding: '38px 0',
        [theme.breakpoints.up('md')]: {
            padding: '88px 0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '138px 0',
        },
        '& .wrapper': {
            [theme.breakpoints.up('md')]: {
                '& > div': {
                    flex: '0 0 50%',
                    maxWidth: '50%',
                }
            },
            '& .gatsby-image-wrapper': {
                margin: 12,
                marginBottom: 24,
            },
            '& div > a': {
                margin: 0,
                marginLeft: 12,
                marginBottom: 12,
            },
            '& .MuiTypography-root': {
                marginBottom: 24,
            },
            '& p': {
                marginBottom: 32,
            }
        }
    },
})