const styles = theme => ({
  contactInformationMap: {
    padding: "32px 0 64px",
    [theme.breakpoints.up("md")]: {
      padding: "80px 0 160px",
    },
    "& .wrapper": {
      padding: "0 12px",
      "& .address-col, & .hours-col": {
        backgroundColor: theme.palette.tertiary.main,
        padding: 24,
        flex: "0 0 100%",
        maxWidth: "100%",
        [theme.breakpoints.up("sm")]: {
          flex: "0 0 50%",
          maxWidth: "50%",
        },
        [theme.breakpoints.up("md")]: {
          padding: 48,
        },
        [theme.breakpoints.up("lg")]: {
          padding: 64,
          flex: "0 0 33.333%",
          maxWidth: "33.333%",
        },
        "& .MuiTypography-root": {
          marginBottom: 32,
          [theme.breakpoints.up("md")]: {
            marginBottom: 48,
          },
        },
        "& .MuiTypography-root, & p": {
          color: theme.palette.tertiary.contrastText,
          padding: 0,
        },
      },
      "& .address-col": {
        [theme.breakpoints.up("lg")]: {
          marginLeft: "-66.666%",
        },
        "& > p": {
          paddingLeft: "32px !important",
          marginBottom: 32,
          "& svg": {
            color: theme.palette.accent.main,
            marginLeft: -32,
            marginRight: 8,
            display: "inline-block",
            verticalAlign: "bottom",
            position: "absolute",
          },
        },
      },
      "& .hours-col": {
        [theme.breakpoints.up("lg")]: {
          marginLeft: "33.333%",
        },
        "& > p": {
          display: "flex",
          justifyContent: "space-between",
          "& strong": {
            flex: "0 0 100px",
            maxWidth: 100,
          },
        },
      },
      "& .map-col": {
        flex: "0 0 100%",
        maxWidth: "100%",
        minHeight: 320,
        borderTop: "none !important",
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 33.333%",
          maxWidth: "33.333%",
          minHeight: 500,
          marginLeft: "33.333%",
        },
      },
      "& > .gatsby-image-wrapper": {
        borderTop: "5px solid " + theme.palette.accent.main,
      },
    },
    "&.border-primary": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.primary.main,
        },
      },
    },
    "&.border-on-primary": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.primary.contrastText,
        },
      },
    },
    "&.border-primary-highlight": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.primary.highlight,
        },
      },
    },

    "&.border-secondary": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.secondary.main,
        },
      },
    },
    "&.border-on-secondary": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.secondary.contrastText,
        },
      },
    },
    "&.border-secondary-highlight": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.secondary.highlight,
        },
      },
    },

    "&.border-tertiary": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.tertiary.main,
        },
      },
    },
    "&.border-on-tertiary": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.tertiary.contrastText,
        },
      },
    },
    "&.border-tertiary-highlight": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.tertiary.highlight,
        },
      },
    },

    "&.border-accent": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.accent.main,
        },
      },
    },
    "&.border-on-accent": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.accent.contrastText,
        },
      },
    },
    "&.border-accent-highlight": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.accent.highlight,
        },
      },
    },

    "&.border-background": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.palette.background.default,
        },
      },
    },
    "&.border-black": {
      "& .wrapper": {
        "& > .gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.common.black,
        },
      },
    },
    "&.border-white": {
      "& .wrapper": {
        "&.gatsby-image-wrapper": {
          borderTop: "5px solid " + theme.common.white,
        },
      },
    },
  },
})
export default styles
