import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import PreviewImage from "../../PreviewImage"
import Link from "../../Link"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const BblPartsGridBlock = props => {
  const classes = useStyles()

  const [type, setType] = useState(undefined)

  const data = useStaticQuery(graphql`
    query PartsGrid {
      allWpProductCategory {
        edges {
          node {
            id
            name
            slug
            uri
            ProductCategoryExtras {
              type
              image {
                sourceUrl
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 250
                      aspectRatio: 1.25
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: { fit: COVER, cropFocus: CENTER }
                      quality: 90
                    )
                  }
                }
              }
            }
            wpParent {
              node {
                id
              }
            }
          }
        }
      }
      wp {
        themeOptions {
          ThemeOptions {
            partsList {
              backgroundColour
              fieldGroupName
              headerColour
              partBackgroundColour
              partTextColour
            }
            brandLogo {
              backgroundColour
              brandBackgroundColour
              headerColour
              activeIconColour
              textColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.partsList
  let brandOverrides = data.wp.themeOptions.ThemeOptions.brandLogo

  if (
    props.innerBlocks[0].attributes.textColor === "" &&
    overrides.headerColour !== "black"
  ) {
    props.innerBlocks[0].attributes.textColor = overrides.headerColour
  }

  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  )

  return (
    <div
      className={`${classes.partsGrid} ${
        props.attributes.className ? props.attributes.className : ""
      }`}
      data-override-background={
        overrides.backgroundColour !== "background"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="wrapper">
        {blocks.map(block => {
          return block
        })}
        {props.attributes.showFilter && (
          <div className="list-filters">
            <button
              onClick={() => {
                setType(undefined)
              }}
              data-override-background={
                brandOverrides.activeIconColour !== "accent"
                  ? brandOverrides.activeIconColour
                  : "accent"
              }
              data-override-color={
                brandOverrides.backgroundColour !== "background"
                  ? brandOverrides.backgroundColour
                  : "background"
              }
            >
              All
            </button>
            <button
              onClick={() => {
                if (type === "light") {
                  setType(undefined)
                } else {
                  setType("light")
                }
              }}
              data-active={type === "light"}
              data-override-background={
                brandOverrides.activeIconColour !== "accent"
                  ? brandOverrides.activeIconColour
                  : "accent"
              }
              data-override-color={
                brandOverrides.backgroundColour !== "background"
                  ? brandOverrides.backgroundColour
                  : "background"
              }
            >
              Light Vehicles
            </button>
            <button
              onClick={() => {
                if (type === "commercial") {
                  setType(undefined)
                } else {
                  setType("commercial")
                }
              }}
              data-active={type === "commercial"}
              data-override-background={
                brandOverrides.activeIconColour !== "accent"
                  ? brandOverrides.activeIconColour
                  : "accent"
              }
              data-override-color={
                brandOverrides.backgroundColour !== "background"
                  ? brandOverrides.backgroundColour
                  : "background"
              }
            >
              Commercial Vehicles
            </button>
          </div>
        )}
        <div className="part-list grid">
          {data.allWpProductCategory.edges
            .map(edge => edge.node)
            .filter(node => !node.wpParent)
            .filter(node => {
              if (!type) return node
              return node.ProductCategoryExtras.type === type
            })
            .map(node => {
              return (
                <div key={node.id}>
                  <Link
                    to={node.uri}
                    data-override-background={
                      overrides.partBackgroundColour !== "primary"
                        ? overrides.partBackgroundColour
                        : null
                    }
                  >
                    <PreviewImage
                      image={node.ProductCategoryExtras.image.localFile}
                      fallbackUrl={node.ProductCategoryExtras.image.sourceUrl}
                      alt={`${node.name} logo`}
                      fixedPadding="80%"
                    />
                    <span
                      data-override-color={
                        overrides.partTextColour !== "on-primary"
                          ? overrides.partTextColour
                          : null
                      }
                    >
                      {node.name}
                    </span>
                  </Link>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

BblPartsGridBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const BblPartsGridBlockFragment = graphql`
  fragment BblPartsGridBlock on WpBlock {
    ... on WpBblPartsGridBlock {
      name
      attributes {
        __typename
        ... on WpBblPartsGridBlockAttributes {
          className
          showFilter
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblPartsGridBlockPreview = `
    ... on BblPartsGridBlock {
        name
        attributes {
            __typename
            ... on BblPartsGridBlockAttributes {
                className
                showFilter
            }
        }
    }
`
