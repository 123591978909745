import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, navigate } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Pagination from '@material-ui/lab/Pagination';
import themeOptions from "../../../../.brew-cache/theme.json";
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';
import PreviewImage from '../../PreviewImage';

import { CoreHeadingBlock } from "../CoreHeadingBlock";
import { CoreParagraphBlock } from "../CoreParagraphBlock";
import { AcfButtonBlock } from "../AcfButtonBlock";

import TodayIcon from '@material-ui/icons/Today';

const useStyles = makeStyles(theme => styles(theme));

export const BblPostListingCardGridBlock = (props) => {

    const data = useStaticQuery(graphql`
    query PostListingCardGridBlock {
      wp {
          themeOptions {
              ThemeOptions {
                  postListGridBlock {
                      backgroundColour
                      cardBackgroundColour
                      categoryBackgroundColour
                      cardHeaderColour
                      categoryColour
                      cardDateColour
                      cardExcerptColour
                  }
              }
          }
      }
    }
    `);

    const overrides = data.wp.themeOptions.ThemeOptions.postListGridBlock;


    const classes = useStyles();
    /**
     * The blog posts should be passed through from the parent page directly to this component
     * This will only happen on custom page templates like the blog list page
     * Preview will not have the posts information for this page due to how the query works, so if this not set
     * use some placeholder data to populate the posts
     * */
    let { posts, pageNumber, totalPages } = props.pageContext;

    // Toggle output based on Attributes
    // props.attributes.display : list / grid

    if (typeof (posts) === 'undefined') {
        // set some sample data
        posts = [
            {
                "node": {
                    title: "Example Post",
                    date: "2020-01-01 00:00:00",
                    excerpt: "<p>This is just a test post used for previewing the listing page. Real posts will appear here when this page is published.</p>",
                    uri: '/',
                    featuredImage: {
                        node: {
                            mediaItemUrl: ""
                        }
                    }
                }
            }
        ]
    }

    let paginationSlug = `/${themeOptions.blogPath}`;

    if (props.pageContext.catSlug) {
        paginationSlug = paginationSlug + '/' + props.pageContext.catSlug;
    }

    return (
        <div className={`${props.attributes.className ? props.attributes.className : ''} ${classes.postListingCardGrid}`}
            data-override-background={overrides.backgroundColour !== 'background' ? overrides.backgroundColour : null}

        >
            <div className="wrapper">
                {posts.map((post, index) => {
                    return <div className="card" key={post.node.id}
                        data-override-background={overrides.cardBackgroundColour !== 'secondary' ? overrides.cardBackgroundColour : null}

                    >

                        <p className="category"
                            data-override-color={overrides.categoryColour !== 'on-accent' ? overrides.categoryColour : null}
                            data-override-background={overrides.categoryBackgroundColour !== 'accent' ? overrides.categoryBackgroundColour : null}

                        >{post.node.categories?.nodes[0].name}</p>

                        <PreviewImage
                            image={post.node.featuredImage.node.localFile}
                            fallbackUrl={post.node.featuredImage.node.mediaItemUrl}
                            alt={post.node.featuredImage.node.altText}
                            fixedPadding="0%"
                        />

                        <div className="con-wrap">

                            <div className="meta">
                                <p className="date"
                                    data-override-color={overrides.cardDateColour !== 'on-secondary' ? overrides.cardDateColour : null}

                                ><TodayIcon /> {post.node.date}</p>
                            </div>

                            <div className="min-height">
                                <CoreHeadingBlock
                                    attributes={{
                                        textAlign: "left",
                                        anchor: "",
                                        className: "",
                                        content: ReactHtmlParser(post.node.title),
                                        level: 3,
                                        textColor: overrides.textColour !== 'on-secondary' ? overrides.textColour : 'on-secondary',
                                        backgroundColor: "",
                                        __typename: "WpCoreHeadingBlockAttributes",
                                    }}
                                    innerBlocks={[]}
                                />
                                <CoreParagraphBlock
                                    attributes={{
                                        align: "left",
                                        className: "",
                                        content: ReactHtmlParser(post.node.excerpt.replace('<p>', '').replace('</p>', '')),
                                        textColor: overrides.cardExcerptColour !== 'on-secondary' ? overrides.cardExcerptColour : 'on-secondary',
                                        backgroundColor: "",
                                        fontSize: "",
                                        __typename: "WpCoreParagraphBlockAttributes",
                                    }}
                                    innerBlocks={[]}
                                />
                            </div>
                            <div className="links">
                                <AcfButtonBlock
                                    attributes={{
                                        className: "",
                                    }}
                                    block={{
                                        ButtonFields: {
                                            buttonLink: {
                                                target: "",
                                                title: props.attributes.btnText,
                                                url: post.node.uri,
                                            },
                                            buttonStyle: "secondary",
                                            icon: "",
                                            showIcon: "none",
                                        }
                                    }}
                                    innerBlocks={[]}
                                />
                            </div>
                        </div>
                    </div>
                })}
                {totalPages > 1 &&
                    <Pagination count={totalPages} page={pageNumber} onChange={(e, value) => { value === 1 ? navigate(paginationSlug) : navigate(`${paginationSlug}/page/${value}`) }} />
                }
            </div>
        </div>
    )
};

BblPostListingCardGridBlock.propTypes = {
    attributes: PropTypes.object.isRequired
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const BblPostListingCardGridBlockFragment = graphql`
  fragment BblPostListingCardGridBlock on WpBlock {
    ... on WpBblPostListingCardGridBlock {
      name
      attributes {
        __typename
        ... on WpBblPostListingCardGridBlockAttributes {
            className
            btnText
        }
      }
    }
  }`;

export const BblPostListingCardGridBlockPreview = `
... on BblPostListingCardGridBlock {
  name
  attributes {
    __typename
    ... on BblPostListingCardGridBlockAttributes {
      className
      btnText
    }
  }
}
`;