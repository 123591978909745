export default theme => ({ // eslint-disable-line
    managerInfo: {
        padding: '32px 0',
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
            padding: 0
        },
        '& .wrapper': {
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse'
            },
            '& .gatsby-image-wrapper': {
                margin: '0 12px 24px',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 calc(41.666% - 24px)',
                    maxWidth: 'calc(41.666% - 24px)',
                    margin: '-32px 12px',
                },
            },
            '& .info': {
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 58.333%',
                    maxWidth: '58.333%',
                    padding: '30px 0 14px',
                },
                [theme.breakpoints.up('lg')]: {
                    flex: '0 0 50%',
                    maxWidth: '50%',
                },
                '& .tag': {
                    fontSize: 12,
                    fontFamily: theme.bodyFont,
                    textTransform: 'uppercase',
                    letterSpacing: '0.2em',
                    marginBottom: 32,
                    padding: '0 12px',
                    display: 'block',
                    color: theme.palette.secondary.contrastText,
                },
                '& .MuiTypography-root, & p': {
                    color: theme.palette.secondary.contrastText,
                },
                '& p': {
                    position: 'relative',
                    paddingLeft: 36,
                    '&::before': {
                        content: `'"'`,
                        fontSize: '125%',
                        position: 'absolute',
                        top: 0,
                        left: 12,
                        fontStyle: 'italic',
                    }
                }
            }
        }
    },
})