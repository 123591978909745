export default theme => ({ // eslint-disable-line
  advertSlider: {
    '& .slick-slider': {
      '& .slick-slide': {
        '& .gatsby-image-wrapper': {
          width: '100%',
        }
      },
      '& .slick-dots': {
        bottom: 16,
        left: 24,
        right: 24,
        width: 'calc(100% - 48px)',
        display: 'flex !important',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '& li': {
          margin: 8,
          width: 16,
          height: 16,
          overflow: 'hidden',
          '& button': {
            margin: 0,
            padding: 0,
            backgroundColor: theme.palette.common.white,
            borderRadius: 8,
            width: 16,
            height: 16,
            fontSize: 0,
            opacity: .25,
            '&::before': {
              display: 'none',
            }
          },
          '&.slick-active button': {
            opacity: 1,
          }
        }
      }
    },
    '&.contained': {
      '& .slick-slider': {
        margin: '32px auto',
        maxWidth: 1118,
        width: 'calc(100% - 24px)',
        [theme.breakpoints.up('md')]: {
          margin: '72px auto',
        },
        '& .slick-dots': {
          justifyContent: 'center',
        }
      }
    }
  },
})
