export default theme => ({ // eslint-disable-line
    jobsList:{
        backgroundColor: theme.palette.tertiary.main,
        padding: '50px 0 24px',
        [theme.breakpoints.up('md')]: {
            padding: '100px 0 64px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '145px 0 124px',
        },
        '& .wrapper': {
            [theme.breakpoints.up('md')]: {
                maxWidth: 960,
                margin: "0 auto",
            },

            "& > .MuiTypography-root": {
                marginBottom: 32,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 60,
                },
            },
        },
        "& .jobs-list": {
            width: "100%",
            "& .job": {
                "& .date-and-post-type": {
                    padding: "0 12px",
                    display: "flex",
                    justifyContent: "space-between",
                    color: theme.palette.primary.main, 
                    fontWeight: 'bold',
                    letterSpacing: '0.032em',   
                    marginBottom: 26,
                    "& .MuiSvgIcon-root": {
                        margin: "-4px 4px -4px 0px",
                        width: 16,
                        height: 16
                    },           
                },
                
                "& hr": {
                    width: "calc(100% - 20px);",
                    margin: "0 auto",
                    border: 'none',
                    height: 1,
                    backgroundColor: theme.common.white,
                    marginBottom: 24,
                    [theme.breakpoints.up("md")]: {
                        marginBottom: 40,
                    }
                },

                "& p":{
                    [theme.breakpoints.up("md")]: {
                        maxWidth: "90%",
                        marginLeft: 0,
                        marginTop: 32,   
                    },
                },
                
                "& a":{
                    marginLeft: 12,  
                    marginTop: 32, 
                    marginBottom: 44,      
                },
               
                 
            },
            
        }
    }
})