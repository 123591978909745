import React from "react"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export default function SplitPageTitle({
  className,
  background,
  titleBackgroundColour,
  image,
  children,
}) {
  const classes = useStyles()

  return (
    <div
      className={`${className} ${classes.splitPageTitle}`}
      data-override-background={background}
    >
      <div className="wrapper">
        <div className="image-wrap">{image}</div>
        <div
          className="con-wrap"
          data-override-background={titleBackgroundColour}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
