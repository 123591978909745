export default theme => ({ // eslint-disable-line
    jobDetail: {
        padding: '32px 0',
        [theme.breakpoints.up('md')]: {
            padding: '50px 0',
        },
        '& .wrapper': {
            '& .back': {
                flex: '0 0 100%',
                '& button.go-back': {
                    color: theme.palette.accent.main,
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    fontFamily: theme.bodyFont,
                    backgroundColor: 'transparent',
                    padding: '12px 0',
                    border: 'none',
                    letterSpacing: '0.008em',
                    fontSize: 16,
                    marginLeft: 12,
                    marginBottom: 32,
                    marginRight: 'auto',
                    [theme.breakpoints.up('md')]: {
                        fontSize: 18,
                        marginBottom: 60,
                    },
                    '& svg': {
                        width: 16,
                        height: 16,
                        display: 'inline-block',
                        marginRight: 5,
                        marginTop: 2,
                        verticalAlign: 'top',
                        [theme.breakpoints.up('md')]: {
                            marginTop: 4,
                        },
                    }
                },
            },
            '& .content': {
                flex: '0 0 100%',
                maxWidth: '100%',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 83.333%',
                    maxWidth: '83.333%',
                    margin: '0 auto',
                },
                '& p.lead': {
                    marginBottom: 32,
                },
                '& > div': {
                    '& p': {
                        display: 'block',
                        position: 'relative',
                        fontSize: 16,
                        lineHeight: 1.5,
                        marginTop: 0,
                        marginBottom: 16,
                        fontFamily: theme.bodyFont,
                        maxWidth: 1142,
                        width: '100%',
                        marginLeft: "auto",
                        marginRight: "auto",
                        padding: '0 12px',
                        [theme.breakpoints.up('md')]: {
                            width: '90%',
                            marginRight: '10%',
                        }
                    },
                    '& ul, & ol': {
                        display: 'block',
                        position: 'relative',
                        padding: 0,
                        paddingLeft: 28,
                        paddingRight: 12,
                        maxWidth: 1142,
                        width: '100%',
                        marginLeft: "auto",
                        marginRight: "auto",
                        '& li': {
                            marginBottom: 16,
                            paddingLeft: 16,
                            fontSize: 16,
                            lineHeight: 1.5,
                            '&::marker': {
                                color: theme.palette.primary.main,
                                left: 0,
                                width: 34,
                            },
                            '& strong, & a': {
                                fontFamily: theme.headerFont,
                                fontWeight: 'bold',
                            },
                            '& a': {
                                textDecoration: 'underline !important',
                                color: theme.palette.primary.main,
                                '&:hover, &:focus': {
                                    color: theme.palette.primary.dark,
                                }
                            }
                        },
                    },
                    '& > ul': {
                        '& li': {
                            display: 'block',
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                width: 12,
                                height: 12,
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 5,
                                position: 'absolute',
                                top: 6,
                                left: -16,
                            }
                        }
                    }
                },
                '& .button': {
                    marginLeft: 12,
                    marginTop: 32,
                }
            }
        }
    }
})