import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import PreviewImage from '../../PreviewImage';

export const CoreImageBlock = (props) => {
  // console.log(props);

  if (!props.attributes.url) return null;

  if (props.originalContent.indexOf(' osm') === -1) {
    return <PreviewImage image={props.attributes.localFile} fallbackUrl={props.attributes.url} alt={props.attributes.alt} />;
  } else {
    return <div className="credited" style={{ position: 'relative' }}>
      <PreviewImage image={props.attributes.localFile} fallbackUrl={props.attributes.url} alt={props.attributes.alt} />
      <div className="copyright"
        style={{
          color: '#333',
          right: '12px',
          bottom: '16px',
          padding: '2px 5px',
          zIndex: 1,
          position: 'absolute',
          fontSize: 12,
          fontFamily: 'sans-serif',
          backgroundColor: 'rgba(255,255,255,0.5)',
        }}
      >
        © <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer" style={{ color: '#0078a8' }}>OpenStreetMap</a> contributors
      </div>
    </div>
  }
};

CoreImageBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CoreImageBlockFragment = graphql`
  fragment CoreImageBlock on WpBlock {
    ... on WpCoreImageBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpCoreImageBlockAttributes {
          url
          alt
          className
          localFile {
            publicURL
            childImageSharp {
                gatsbyImageData(
                  width: 1660,
                  placeholder: BLURRED,
                  formats: [AUTO, WEBP, AVIF],
                  transformOptions: {fit: COVER, cropFocus: CENTER},
                  quality: 90
                )
            }
          }
        }
      }
    }
  }`;


export const CoreImageBlockPreview = `
  ... on CoreImageBlock {
    name
    originalContent
    attributes {
      __typename
      ... on CoreImageBlockAttributes {
        url
        className
      }
    }
  }`;