export default theme => ({ // eslint-disable-line
  imageSlider: {
    padding: '32px 0',
    [theme.breakpoints.up('md')]: {
      padding: '50px 0',
    },
    '& .wrapper': {
      "& .MuiTypography-root": {
        padding: 12,
      },
      "& .slide-wrap": {
        padding: '0 12px',
        display: "block",
        flex: "0 0 100%",
        maxWidth: "100%",
        "& .slider-main": {
          position: "relative",
          "& .slick-slider": {
            margin: "0 auto",
            width: 'calc(83.333% - 24px)',
            "&.slider-nav": {
              width: "calc(100% + 24px)",
              margin: "12px -12px 56px",
              paddingTop: 12,
              '&.primary-dots .slick-dots li button': { backgroundColor: theme.palette.primary.main},
              '&.on-primary-dots .slick-dots li button': { backgroundColor: theme.palette.primary.contrastText},
              '&.primary-highlight-dots .slick-dots li button': { backgroundColor: theme.palette.primary.highlight},
              '&.secondary-dots .slick-dots li button': { backgroundColor: theme.palette.secondary.main},
              '&.on-secondary-dots .slick-dots li button': { backgroundColor: theme.palette.secondary.contrastText},
              '&.secondary-highlight-dots .slick-dots li button': { backgroundColor: theme.palette.secondary.highlight},
              '&.tertiary-dots .slick-dots li button': { backgroundColor: theme.palette.tertiary.main},
              '&.on-tertiary-dots .slick-dots li button': { backgroundColor: theme.palette.tertiary.contrastText},
              '&.tertiary-highlight-dots .slick-dots li button': { backgroundColor: theme.palette.tertiary.highlight},
              '&.accent-dots .slick-dots li button': { backgroundColor: theme.palette.accent.main},
              '&.on-accent-dots .slick-dots li button': { backgroundColor: theme.palette.accent.contrastText},
              '&.accent-highlight-dots .slick-dots li button': { backgroundColor: theme.palette.accent.highlight},
              "& div.slick-slide": {
                padding: 12,
              },
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.accent.contrastText,
              backgroundColor: theme.palette.accent.main,
              width: 48,
              height: 48,
              zIndex: 2,
              borderRadius: theme.border.radius,
              boxShadow: theme.shadow,
              [theme.breakpoints.up("sm")]: {
                width: 64,
                height: 64,
                margin: '0 -50px',
              },
              [theme.breakpoints.up("md")]: {
                margin: '0 -62px',
              },
              "&:hover": {
                backgroundColor: theme.palette.accent.main,
              },
            },
            '& .slick-dots': {
              bottom: -56,
              left: 24,
              right: 24,
              width: 'calc(100% - 48px)',
              display: 'flex !important',
              flexDirection: 'row',
              justifyContent: 'center',
              '& li': {
                margin: 8,
                width: 16,
                height: 16,
                overflow: 'hidden',
                '& button': {
                  margin: 0,
                  padding: 0,
                  backgroundColor: theme.palette.accent.main,
                  borderRadius: 8,
                  width: 16,
                  height: 16,
                  fontSize: 0,
                  opacity: .25,
                  '&::before': {
                    display: 'none',
                  }
                },
                '&.slick-active button': {
                  opacity: 1,
                }
              }
            }
          },
        },
      },
    },
  },
})
