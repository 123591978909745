export default theme => ({ // eslint-disable-line
    stickyPostSplit: {
        paddingTop: 32,
        paddingBottom: 32,
        backgroundColor: theme.palette.primary.main,
        '& .sticky-post-split': {
            flex: '0 0 calc(100% - 24px)',
            maxWidth: 'calc(100% - 24px)',
            margin: '0 12px',
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            '& .gatsby-image-wrapper': {
                flex: '0 0 100%',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 66.666%',
                },
                [theme.breakpoints.up('lg')]: {
                    flex: '0 0 56.75%',
                }
            },
            '& p.category': {
                fontSize: 12,
                letterSpacing: '0.2em',
                lineHeight: '20px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                marginBottom: 32,
                marginTop: 0,
                textAlign: 'right',
                backgroundColor: theme.palette.accent.main,
                color: theme.palette.accent.contrastText,
                marginLeft: 'auto',
                display: 'inline-block',
                padding: '12px 16px',
                width: 'auto',
                float: 'right',
                position: 'absolute',
                zIndex: 2,
                top: 12,
                left: 12,
                [theme.breakpoints.up('md')]: {
                    top: 20,
                    left: 20,
                }
            },
            '& .con-wrap': {
                zIndex: 2,
                position: 'relative',
                flex: '0 0 100%',
                padding: 24,
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 33.333%',
                    padding: 52,
                },
                [theme.breakpoints.up('lg')]: {
                    flex: '0 0 43.25%',
                },
                '& p.date': {
                    display: 'inline-block',
                    width: '50%',
                    fontSize: 12,
                    letterSpacing: '0.2em',
                    lineHeight: '20px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    color: theme.palette.accent.main,
                    marginBottom: 24,
                    marginTop: 0,
                    [theme.breakpoints.up('md')]: {
                        marginBottom: 32,
                    },
                    '& svg': {
                        width: 16,
                        height: 16,
                        display: 'inline-block',
                        verticalAlign: 'top',
                        marginTop: 2,
                        marginRight: 5,
                    }
                },
                '& .MuiTypography-root': {
                    padding: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 24,
                },
                '& p': {
                    padding: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    lineHeight: 2,
                },
                '& .button': {
                    marginLeft: 0,
                    marginBottom: 0,
                }
            }
        }
    },
})