const styles = theme => ({
  list: {
    display: "block",
    position: "relative",
    padding: 0,
    paddingLeft: 28,
    paddingRight: 12,
    maxWidth: 1142,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    "& li": {
      marginBottom: 16,
      paddingLeft: 16,
      fontSize: 16,
      lineHeight: 1.5,
      "&::marker": {
        color: theme.palette.primary.main,
        left: 0,
        width: 34,
      },
      "& strong, & a": {
        fontFamily: theme.headerFont,
        fontWeight: "bold",
      },
      "& a": {
        textDecoration: "underline !important",
        color: theme.palette.primary.main,
        "&:hover, &:focus": {
          color: theme.palette.primary.dark,
        },
      },
    },
    "&:not(ol)": {
      "& li": {
        display: "block",
        position: "relative",
        "&::before": {
          content: '""',
          width: 12,
          height: 12,
          backgroundColor: "currentColor",
          borderRadius: 5,
          position: "absolute",
          top: 6,
          left: -16,
        },
      },
    },
  },
})
export default styles
