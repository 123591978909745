const styles = theme => ({
  partsGrid: {
    padding: "32px 0",
    [theme.breakpoints.up("md")]: {
      padding: "50px 0 100px",
    },
    "& .MuiTypography-root": {
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        marginBottom: 50,
      },
    },
    "& .part-list": {
      flex: "0 0 100%",
      maxWidth: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      "& > div": {
        padding: 12,
        flex: "0 0 100%",
        maxWidth: "100%",
        [theme.breakpoints.up("sm")]: {
          flex: "0 0 50%",
          maxWidth: "50%",
        },
        "& a": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: theme.common.black,
          textTransform: "uppercase",
          letterSpacing: "0.032em",
          fontSize: 16,
          fontFamily: theme.bodyFont,
          transition: "color .3s ease-in-out",
          width: "100%",
          "& .gatsby-image-wrapper": {
            flex: "0 0 150px",
            maxWidth: "150px",
            marginRight: 12,
          },
          "&:hover, &:focus": {
            color: theme.palette.accent,
          },
        },
      },
      "&.grid": {
        "& > div": {
          flex: "0 0 50%",
          maxWidth: "50%",
          [theme.breakpoints.up("md")]: {
            flex: "0 0 25%",
            maxWidth: "25%",
          },
          [theme.breakpoints.up("md")]: {
            flex: "0 0 16.666%",
            maxWidth: "16.666%",
          },
          "& a": {
            height: "100%",
            backgroundColor: theme.palette.primary.main,
            flexDirection: "column",
            padding: 24,
            alignItems: "flex-start",
            color: theme.palette.primary.contrastText,
            overflow: "hidden",
            borderRadius: theme.border.radius,
            boxShadow: theme.shadow,
            transition: "opacity .3s ease-in-out",
            "& .gatsby-image-wrapper": {
              width: "calc(100% + 50px)",
              maxWidth: "calc(100% + 50px)",
              margin: "-24px -25px 16px",
            },
            "&:hover, &:focus": {
              color: theme.palette.primary.contrastText,
              opacity: 0.8,
            },
          },
        },
      },
    },
    "& .list-filters": {
      flex: "0 0 100%",
      maxWidth: "100%",
      padding: 12,
      [theme.breakpoints.up("md")]: {
        flex: "0 0 50%",
        maxWidth: "50%",
        padding: "40px 12px",
      },
      "& button": {
        fontSize: 16,
        lineHeight: 1.5,
        fontFamily: theme.subHeaderFont,
        fontWeight: "bold",
        display: "inline-block",
        marginRight: 12,
        padding: "8px 24px",
        position: "relative",
        borderRadius: theme.border.radius,
        border: "none",
        transition: "opacity .3s ease-in-out",
        boxShadow: theme.shadow,
        opacity: 1,
        "&:hover, &:focus": {
          opacity: 0.8,
        },
        '&[data-active="true"]': {
          opacity: 0.4,
        },
      },
    },
  },
})
export default styles
