export default theme => ({ // eslint-disable-line
    mediaItemBlock: {
        borderRadius: theme.border.radius,
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
        boxShadow: theme.shadow,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        padding: 24,
        alignItems: 'center',
        marginBottom: 24,
        [theme.breakpoints.up('md')]: {
            padding: 44,
            marginBottom: 32,
        },
        '& .gatsby-image-wrapper': {
            flex: '0 0 100px',
            marginRight: 24,
            [theme.breakpoints.up('md')]: {
                marginRight: 44,
            },
        },
        '& .con-wrap': {
            flex: '0 1 auto',
            '& .MuiTypography-root': {
                padding: 0,
            },
            '& p': {
                padding: 0,
                marginBottom: 8,
            }  
        },
    },
})