export default theme => ({ // eslint-disable-line
    pdfReader: {
      backgroundColor: theme.palette.background.default,
      padding: "40px 0",
      [theme.breakpoints.up("md")]: {
        padding: "80px 0",
      },
      "& canvas": {
        borderRadius: "10px",
        maxWidth: "100%",
        height: "auto !important",
        margin: "0 auto",
      },
  
      "& .wrapper": {
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
          marginTop: "20px",
        },
        "& > p": {
          [theme.breakpoints.up("md")]: {
            maxWidth: "66.666%",
            marginRight: "33.333%",
          },
          [theme.breakpoints.up("lg")]: {
            maxWidth: "41.65%",
            marginRight: "58.35%",
          },
        },
        "& .pdf": {
          flex: "0 0 100%",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          marginTop: 24,
          justifyContent: "space-between",
          "& .annotationLayer": {
            display: "none",
          },
          "& button": {
            height: 44,
            flex: "0 0 44px",
            border: "none",
            position: "relative",
            zIndex: 2,
            margin: "0 12px",
            backgroundColor: theme.palette.primary.main,
            borderRadius: theme.border.radius,
            boxShadow: theme.shadow,
            [theme.breakpoints.up("sm")]: {
              height: "64px",
              flex: "0 0 64px",
              '& svg': {
                width: 48,
                height: 48,
              },
            },
            "&.previous": {
              marginRight: -20,
              [theme.breakpoints.up("sm")]: {
                marginRight: 12,
              },
            },
            "&.next": {
              marginLeft: -20,
              [theme.breakpoints.up("sm")]: {
                marginLeft: 12,
              },
              "& .MuiSvgIcon-root": {
                fill: theme.palette.primary.contrastText,
              },
            },
          },
          "& .react-pdf__Document": {
            flex: "0 0 calc(100% - 48px)",
            maxWidth: "calc(100% - 48px)",
            margin: "0 -20px",
            [theme.breakpoints.up("sm")]: {
              margin: "0 auto",
              flex: "0 0 calc(100% - 248px)",
              maxWidth: "calc(100% - 248px)",
            },
          },
        },
        "& .pageNumberBar": {
          display: "flex",
          flex: "0 0 100%",
          alignItems: "center",
          marginTop: 30,
          "& div": {
            flex: "1 1 0px",
            backgroundColor: theme.palette.primary.main,
            height: 2,
            opacity: 0.3,
            "&.active": {
              opacity: 1,
            },
          },
          "& p": {
            color: theme.palette.primary.main,
            margin: 0,
            marginLeft: 12,
          },
        },
      },
    },
  })
  