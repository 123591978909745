import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const BblTwoColumnTextIntroBlock = (props) => {

    const data = useStaticQuery(graphql`
        query twoColumnTextIntroOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        twoColumnTextIntro {
                            backgroundColour
                            headerColour
                            paragraphColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.twoColumnTextIntro;

    if(props.innerBlocks[0].attributes.textColor === "" && overrides.headerColour !== 'black') {
        props.innerBlocks[0].attributes.textColor = overrides.headerColour;
    }

    if(props.innerBlocks[1].attributes.textColor === "" && overrides.paragraphColour !== 'black') {
        props.innerBlocks[1].attributes.textColor = overrides.paragraphColour;
    }

    if(props.innerBlocks[2].attributes.textColor === "" && overrides.paragraphColour !== 'black') {
        props.innerBlocks[2].attributes.textColor = overrides.paragraphColour;
    }

    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.twoColumnTextIntro} `}
                data-offset={ props.attributes.isOffset }
    >
        <div className="wrapper">
            {blocks.map((block) => {
                return block;
            })}
        </div>
    </div>
};

export const BblTwoColumnTextIntroBlockFragment = graphql`
  fragment BblTwoColumnTextIntroBlock on WpBlock {
    ... on WpBblTwoColumnTextIntroBlock {
        name
        attributes {
            className
            isOffset
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblTwoColumnTextIntroBlockPreview = `
    ... on BblTwoColumnTextIntroBlock {
        name
        attributes {
            className
            isOffset
        }
    }
`;