import React from "react"

const W3wIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 146.2725 146.2725"
      width="48px"
      height="48px"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="artwork">
          <path
            class="cls-2"
            fill="none"
            d="M117.0206,29.2522H29.2549v87.7656h87.7657V29.2522Z"
          />
          <path
            class="cls-1"
            fill="black"
            d="M67.6525,92.3346a2.7455,2.7455,0,0,1-2.602-3.61L76.0212,55.812a2.7429,2.7429,0,0,1,5.2041,1.7356L70.2546,90.46A2.7429,2.7429,0,0,1,67.6525,92.3346Z"
          />
          <path
            class="cls-1"
            fill="black"
            d="M51.1965,92.3346a2.7456,2.7456,0,0,1-2.6021-3.61L59.5651,55.812a2.743,2.743,0,0,1,5.2042,1.7356L53.7985,90.46A2.7429,2.7429,0,0,1,51.1965,92.3346Z"
          />
          <path
            class="cls-1"
            fill="black"
            d="M84.1086,92.3346a2.7456,2.7456,0,0,1-2.6021-3.61L92.4772,55.812a2.743,2.743,0,0,1,5.2042,1.7356L86.7107,90.46A2.743,2.743,0,0,1,84.1086,92.3346Z"
          />
          <rect class="cls-2" fill="none" />
        </g>
      </g>
    </svg>
  )
}

export default W3wIcon
// width="146.2725" height="146.2725"
