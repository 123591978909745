const styles = (theme, overrides) => ({
  brandDetail: {
    padding: "32px 0",
    [theme.breakpoints.up("md")]: {
      padding: "50px 0",
    },
    backgroundColor: `var(--${
      overrides.backgroundColour ? overrides.backgroundColour : "background"
    })`,
    "& .wrapper": {
      [theme.breakpoints.up("md")]: {
        flexDirection: "row-reverse",
        justifyContent: "space-between",
      },
      "& .back": {
        flex: "0 0 100%",
        "& button.go-back": {
          color: theme.palette.accent.main,
          textDecoration: "underline",
          fontWeight: "bold",
          fontFamily: theme.bodyFont,
          backgroundColor: "transparent",
          padding: "12px 0",
          border: "none",
          letterSpacing: "0.008em",
          fontSize: 16,
          marginLeft: 12,
          marginBottom: 32,
          marginRight: "auto",
          [theme.breakpoints.up("md")]: {
            fontSize: 18,
            marginBottom: 60,
          },
          "& svg": {
            width: 16,
            height: 16,
            display: "inline-block",
            marginRight: 5,
            marginTop: 2,
            verticalAlign: "top",
            [theme.breakpoints.up("md")]: {
              marginTop: 4,
            },
          },
        },
      },
      "& .brand-logo": {
        flex: "0 0 100%",
        maxWidth: "100%",
        margin: "0 0 32px",
        padding: "0 12px",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 33.333%",
          maxWidth: "33.333%",
        },
      },
      "& .brand-description": {
        flex: "0 0 100%",
        maxWidth: "0 0 100%",
        marginBottom: 32,
        color: `var(--${
          overrides.textColour ? overrides.textColour : "black"
        })`,
        [theme.breakpoints.up("md")]: {
          flex: "0 0 66.666%",
          maxWidth: "66.666%",
          marginBottom: 80,
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 58.333%",
          maxWidth: "58.333%",
        },
      },
      "& .parts": {
        flex: "0 0 100%",
        maxWidth: "100%",
        "& .MuiTypography-h3": {
          color: `var(--${
            overrides.partsHeaderColour
              ? overrides.partsHeaderColour
              : "primary"
          })`,
        },
        "& .product-cat-list": {
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          "& > a": {
            margin: 12,
            fontWeight: "bold",
            padding: 24,
            color: `var(--${
              overrides.partsTextColour
                ? overrides.partsTextColour
                : "on-primary"
            })`,
            backgroundColor: `var(--${
              overrides.partsBackgroundColour
                ? overrides.partsBackgroundColour
                : "on-primary"
            })`,
            flex: "0 0 50%",
            maxWidth: "50%",
            transition: "opacity .3s ease-in-out",
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: "0.032em",
            "&:hover, &:focus": {
              opacity: 0.8,
            },
            [theme.breakpoints.up("sm")]: {
              flex: "0 0 33.333%",
              maxWidth: "33.333%",
            },
            [theme.breakpoints.up("md")]: {
              flex: "0 0 25%",
              maxWidth: "25%",
            },
            [theme.breakpoints.up("md")]: {
              flex: "0 0 16.666%",
              maxWidth: "16.666%",
            },
          },
        },
      },
    },
  },
})

export default styles
