import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import RoomIcon from "@material-ui/icons/Room"
import PreviewImage from "../../PreviewImage"

import { CoreHeadingBlock } from "../CoreHeadingBlock"
import { CoreParagraphBlock } from "../CoreParagraphBlock"
import { AcfButtonBlock } from "../AcfButtonBlock"

const useStyles = makeStyles(theme => styles(theme))

export const BblBranchListingBlock = props => {
  const data = useStaticQuery(graphql`
    query branchListingBlock {
      allWpBranch(sort: { fields: BranchInformation___name, order: ASC }) {
        nodes {
          id
          uri
          BranchInformation {
            name
            managerForename
            managerSurname
            managerPhoto {
              id
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 110
                    aspectRatio: 1
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                    quality: 90
                  )
                }
              }
            }
          }
        }
      }

      wp {
        themeOptions {
          ThemeOptions {
            branchListing {
              backgroundColour
              branchBackgroundColour
              iconColour
              headerColour
              textColour
            }
          }
        }
      }
    }
  `)

  const overrides = data.wp.themeOptions.ThemeOptions.branchListing

  if (
    props.innerBlocks[0].attributes.textColor === "" &&
    overrides.headerColour !== "black"
  ) {
    props.innerBlocks[0].attributes.textColor = overrides.headerColour
  }

  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()

  return (
    <div
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.branchListing} `}
      data-override-background={
        overrides.backgroundColour !== "background"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="wrapper">
        <CoreHeadingBlock
          attributes={{
            textAlign: "left",
            anchor: "",
            className: "",
            content: props.attributes.branchListingHeading,
            level: 3,
            textColor:
              overrides.headerColour !== "black"
                ? overrides.headerColour
                : "black",
            backgroundColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        <div className="branches">
          {data.allWpBranch.nodes.map(branch => {
            if (props.pageContext.id !== branch.id) {
              return (
                <div
                  className="branch"
                  key={branch.id}
                  data-override-background={
                    overrides.branchBackgroundColour !== "primary"
                      ? overrides.branchBackgroundColour
                      : null
                  }
                >
                  <PreviewImage
                    image={branch.BranchInformation.managerPhoto.localFile}
                    fallbackUrl={branch.BranchInformation.managerPhoto.uri}
                    alt={branch.BranchInformation.managerForename}
                    fixedPadding="100%"
                  />
                  <div className="info">
                    <RoomIcon
                      data-override-color={
                        overrides.iconColour !== "on-primary"
                          ? overrides.iconColour
                          : null
                      }
                    />
                    <CoreHeadingBlock
                      attributes={{
                        textAlign: "left",
                        anchor: "",
                        className: "",
                        content: branch.BranchInformation.name,
                        level: 3,
                        textColor:
                          overrides.textColour !== "on-primary"
                            ? overrides.textColour
                            : "on-primary",
                        backgroundColor: "",
                        __typename: "WpCoreHeadingBlockAttributes",
                      }}
                      innerBlocks={[]}
                    />
                    <CoreParagraphBlock
                      attributes={{
                        align: "left",
                        className: "",
                        content:
                          branch.BranchInformation.managerForename +
                          " " +
                          branch.BranchInformation.managerSurname,
                        textColor:
                          overrides.textColour !== "on-primary"
                            ? overrides.textColour
                            : "on-primary",
                        backgroundColor: "",
                        __typename: "WpCoreParagraphBlockAttributes",
                      }}
                      innerBlocks={[]}
                    />
                    <AcfButtonBlock
                      attributes={{
                        className: "",
                      }}
                      block={{
                        ButtonFields: {
                          buttonLink: {
                            target: "",
                            title: "Find out more",
                            url: branch.uri,
                          },
                          buttonStyle: "secondary",
                          icon: "",
                          showIcon: "none",
                        },
                      }}
                      innerBlocks={[]}
                    />
                  </div>
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
        {blocks.map(block => {
          return block
        })}
      </div>
    </div>
  )
}

export const BblBranchListingBlockFragment = graphql`
  fragment BblBranchListingBlock on WpBlock {
    ... on WpBblBranchListingBlock {
      name
      attributes {
        className
        branchListingHeading
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP Prefixes
export const BblBranchListingBlockPreview = `
    ... on BblBranchListingBlock {
        name
        attributes {
            className
            branchListingHeading
        }
    }
`
