import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const BblIntroGalleryBlock = (props) => {

    const data = useStaticQuery(graphql`
        query introGalleryBlock {
            wp {
                themeOptions {
                    ThemeOptions {
                        introGallery {
                            backgroundColour
                            headerColour
                            paragraphColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.introGallery;

    if(props.innerBlocks[1].attributes.textColor === "" && overrides.headerColour !== 'black') {
        props.innerBlocks[1].attributes.textColor = overrides.headerColour;
    }

    if(props.innerBlocks[2].attributes.textColor === "" && overrides.paragraphColour !== 'black') {
        props.innerBlocks[2].attributes.textColor = overrides.paragraphColour;
    }

    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.introGallery} `}
                data-thin={ props.attributes.isThin }
                data-override-background={overrides.backgroundColour !== 'background' ? overrides.backgroundColour : null}
    >
        <div className="wrapper">
            {blocks[0]}
            <div className="con-wrap">
                {blocks[1]}
                {blocks[2]}
                { props.attributes.hasButton &&
                    blocks[3]
                }
            </div>
        </div>
    </div>
};

export const BblIntroGalleryBlockFragment = graphql`
  fragment BblIntroGalleryBlock on WpBlock {
    ... on WpBblIntroGalleryBlock {
        name
        attributes {
            className
            hasButton
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblIntroGalleryBlockPreview = `
    ... on BblIntroGalleryBlock {
        name
        attributes {
            className
            hasButton
        }
    }
`;