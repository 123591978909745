const styles = theme => ({
  bannerMessage: {
    position: "relative",
    "& .desktop-image": {
      display: "none !important",
      [theme.breakpoints.up("md")]: {
        display: "block !important",
      },
    },
    [theme.breakpoints.up("md")]: {
      "& .mobile-image": {
        display: "none !important",
      },
    },
    "& .wrapper": {
      position: "absolute",
      padding: 12,
      top: 0,
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 2,
      width: "100%",
      alignContent: "flex-end",
      [theme.breakpoints.up("md")]: {
        alignContent: "center",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: -450,
      },
      "& .con-wrap": {
        textShadow: theme.shadow,
        padding: "12px 0",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 66.666%",
          maxWidth: "66.666%",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 50%",
          maxWidth: "50%",
        },
        "& .MuiTypography-root": {
          marginBottom: 32,
        },
        "& > p": {
          marginBottom: 32,
          [theme.breakpoints.up("md")]: {
            marginBottom: 72,
          },
        },
        "& .button": {
          marginLeft: 12,
        },
      },
      '&[data-bg="true"]': {
        "& .con-wrap": {
          backgroundColor: theme.palette.background.default,
          padding: "24px 12px",
          borderRadius: theme.border.radius,
          boxShadow: theme.shadow,
          textShadow: "none",
          [theme.breakpoints.up("md")]: {
            padding: "36px 24px",
          },
          [theme.breakpoints.up("lg")]: {
            padding: "100px 48px",
          },
          [theme.breakpoints.up(1320)]: {
            flex: "0 0 calc(50% + 60px)",
            maxWidth: "calc(50% + 60px)",
            marginLeft: -60,
          },
        },
      },
    },
  },
})
export default styles
