import { graphql } from "gatsby"

import {  CoreParagraphBlockFragment,  CoreParagraphBlockPreview } from "../components/blocks/CoreParagraphBlock/" // eslint-disable-line
import {  CoreHeadingBlockFragment,   CoreHeadingBlockPreview } from "../components/blocks/CoreHeadingBlock" // eslint-disable-line
import {  CoreGalleryBlockFragment,   CoreGalleryBlockPreview } from "../components/blocks/CoreGalleryBlock" // eslint-disable-line
import {  CoreImageBlockFragment,   CoreImageBlockPreview } from "../components/blocks/CoreImageBlock" // eslint-disable-line
import {  CoreListBlockFragment,   CoreListBlockPreview } from "../components/blocks/CoreListBlock" // eslint-disable-line
import {  CoreSpacerBlockFragment,   CoreSpacerBlockPreview } from "../components/blocks/CoreSpacerBlock" // eslint-disable-line

import {  AcfAdvertSliderBlockFragment,  AcfAdvertSliderBlockPreview } from "../components/blocks/AcfAdvertSliderBlock" // eslint-disable-line
import {  AcfBackgroundContainerBlockFragment,  AcfBackgroundContainerBlockPreview } from "../components/blocks/AcfBackgroundContainerBlock" // eslint-disable-line
import {  AcfBackgroundImageContainerBlockFragment,  AcfBackgroundImageContainerBlockPreview } from "../components/blocks/AcfBackgroundImageContainerBlock" // eslint-disable-line
import {  AcfBrewGalleryBlockFragment,  AcfBrewGalleryBlockPreview } from "../components/blocks/AcfBrewGalleryBlock" // eslint-disable-line
import {  AcfButtonBlockFragment,  AcfButtonBlockPreview } from "../components/blocks/AcfButtonBlock" // eslint-disable-line
import {  AcfFacebookPagePluginBlockFragment,  AcfFacebookPagePluginBlockPreview } from "../components/blocks/AcfFacebookPagePluginBlock" // eslint-disable-line
import {  AcfGoogleReviewsBlockFragment,  AcfGoogleReviewsBlockPreview } from "../components/blocks/AcfGoogleReviewsBlock" // eslint-disable-line
import {  AcfGlobalContactInformationImageBlock,  AcfGlobalContactInformationImageBlockPreview } from "../components/blocks/AcfGlobalContactInformationImageBlock" // eslint-disable-line
import {  AcfImageSliderBlockFragment,  AcfImageSliderBlockPreview } from "../components/blocks/AcfImageSliderBlock" // eslint-disable-line
import {  AcfJobsListBlockFragment,  AcfJobsListBlockPreview } from "../components/blocks/AcfJobsListBlock" // eslint-disable-line
import {  AcfLatestPostsGridBlockFragment,  AcfLatestPostsGridBlockPreview } from "../components/blocks/AcfLatestPostsGridBlock" // eslint-disable-line
import {  AcfLatestPostsSliderBlockFragment,  AcfLatestPostsSliderBlockPreview } from "../components/blocks/AcfLatestPostsSliderBlock" // eslint-disable-line
import {  AcfPageListingBlockFragment,  AcfPageListingBlockPreview } from "../components/blocks/AcfPageListingBlock" // eslint-disable-line
import {  AcfPdfReaderBlockFragment,  AcfPdfReaderBlockPreview } from "../components/blocks/AcfPdfReaderBlock" // eslint-disable-line
import {  AcfPeopleGridBlockFragment,  AcfPeopleGridBlockPreview } from "../components/blocks/AcfPeopleGridBlock" // eslint-disable-line 
import {  AcfTimedContainerBlockFragment,  AcfTimedContainerBlockPreview } from "../components/blocks/AcfTimedContainerBlock" // eslint-disable-line 

import {  BblBannerMessageBlockFragment,  BblBannerMessageBlockPreview } from "../components/blocks/BblBannerMessageBlock" // eslint-disable-line
import {  BblBannerMessageSliderBlockFragment,  BblBannerMessageSliderBlockPreview } from "../components/blocks/BblBannerMessageSliderBlock" // eslint-disable-line
import {  BblBannerStackedBlockFragment,  BblBannerStackedBlockPreview } from "../components/blocks/BblBannerStackedBlock" // eslint-disable-line
import {  BblBranchIntroBlockFragment,  BblBranchIntroBlockPreview } from "../components/blocks/BblBranchIntroBlock" // eslint-disable-line
import {  BblBranchListingBlockFragment,  BblBranchListingBlockPreview } from "../components/blocks/BblBranchListingBlock" // eslint-disable-line
import {  BblBrandDetailBlockFragment,  BblBrandDetailBlockPreview } from "../components/blocks/BblBrandDetailBlock" // eslint-disable-line
import {  BblBrandLogosBlockFragment,  BblBrandLogosBlockPreview } from "../components/blocks/BblBrandLogosBlock" // eslint-disable-line
import {  BblCalloutCtaBlockFragment,  BblCalloutCtaBlockPreview } from "../components/blocks/BblCalloutCtaBlock" // eslint-disable-line
import {  BblCardBlockFragment,  BblCardBlockPreview } from "../components/blocks/BblCardBlock" // eslint-disable-line
import {  BblCardRowBlockFragment,  BblCardRowBlockPreview } from "../components/blocks/BblCardRowBlock" // eslint-disable-line
import {  BblContactInformationImageBlockFragment,  BblContactInformationImageBlockPreview } from "../components/blocks/BblContactInformationImageBlock" // eslint-disable-line
import {  BblContactInformationMapBlockFragment,  BblContactInformationMapBlockPreview } from "../components/blocks/BblContactInformationMapBlock" // eslint-disable-line
import {  BblFiftyStackedContentBlockFragment,  BblFiftyStackedContentBlockPreview } from "../components/blocks/BblFiftyStackedContentBlock" // eslint-disable-line
import {  BblFormIntroBlockFragment,  BblFormIntroBlockPreview } from "../components/blocks/BblFormIntroBlock" // eslint-disable-line
import {  BblGoogleMapBlockFragment,  BblGoogleMapBlockPreview } from "../components/blocks/BblGoogleMapBlock" // eslint-disable-line
import {  BblIconRowBlockFragment,  BblIconRowBlockPreview } from "../components/blocks/BblIconRowBlock" // eslint-disable-line
import {  BblIconRowInnerBlockFragment,  BblIconRowInnerBlockPreview } from "../components/blocks/BblIconRowInnerBlock" // eslint-disable-line
import {  BblIntroGalleryBlockFragment,  BblIntroGalleryBlockPreview } from "../components/blocks/BblIntroGalleryBlock" // eslint-disable-line
import {  BblImageContentRowsBlockFragment,  BblImageContentRowsBlockPreview } from "../components/blocks/BblImageContentRowsBlock" // eslint-disable-line
import {  BblImageContentRowsInnerBlockFragment,  BblImageContentRowsInnerBlockPreview } from "../components/blocks/BblImageContentRowsInnerBlock" // eslint-disable-line
import {  BblJobDetailBlockFragment,  BblJobDetailBlockPreview } from "../components/blocks/BblJobDetailBlock" // eslint-disable-line
import {  BblManagerInfoBlockFragment,  BblManagerInfoBlockPreview } from "../components/blocks/BblManagerInfoBlock" // eslint-disable-line
import {  BblMediaItemBlockFragment,  BblMediaItemBlockPreview } from "../components/blocks/BblMediaItemBlock" // eslint-disable-line
import {  BblPageTitleBlockFragment,  BblPageTitleBlockPreview } from "../components/blocks/BblPageTitleBlock" // eslint-disable-line
import {  BblPostBannerBlockFragment,  BblPostBannerBlockPreview } from "../components/blocks/BblPostBannerBlock" // eslint-disable-line
import {  BblPostListingFullWidthBlockFragment,  BblPostListingFullWidthBlockPreview } from "../components/blocks/BblPostListingFullWidthBlock" // eslint-disable-line
import {  BblPostListingCardGridBlockFragment,  BblPostListingCardGridBlockPreview } from "../components/blocks/BblPostListingCardGridBlock" // eslint-disable-line
import {  BblPostWrapBlockFragment,  BblPostWrapBlockPreview } from "../components/blocks/BblPostWrapBlock" // eslint-disable-line
import {  BblPartsGridBlockFragment,  BblPartsGridBlockPreview } from "../components/blocks/BblPartsGridBlock" // eslint-disable-line
import {  BblSideCardBlockFragment,  BblSideCardBlockPreview } from "../components/blocks/BblSideCardBlock" // eslint-disable-line
import {  BblSideCardGridBlockFragment,  BblSideCardGridBlockPreview } from "../components/blocks/BblSideCardGridBlock" // eslint-disable-line
import {  BblSplitPageTitleBlockFragment,  BblSplitPageTitleBlockPreview } from "../components/blocks/BblSplitPageTitleBlock" // eslint-disable-line
import {  BblSplitPostTitleBlockFragment,  BblSplitPostTitleBlockPreview } from "../components/blocks/BblSplitPostTitleBlock" // eslint-disable-line
import {  BblStatBreakdownBlockFragment,  BblStatBreakdownBlockPreview } from "../components/blocks/BblStatBreakdownBlock" // eslint-disable-line
import {  BblStatBreakdownInnerBlockFragment,  BblStatBreakdownInnerBlockPreview } from "../components/blocks/BblStatBreakdownInnerBlock" // eslint-disable-line
import {  BblStickyPostFullWidthBlockFragment,  BblStickyPostFullWidthBlockPreview } from "../components/blocks/BblStickyPostFullWidthBlock" // eslint-disable-line
import {  BblStickyPostSplitBlockFragment,  BblStickyPostSplitBlockPreview } from "../components/blocks/BblStickyPostSplitBlock" // eslint-disable-line
import {  BblTextIntroBlockFragment,  BblTextIntroBlockPreview } from "../components/blocks/BblTextIntroBlock" // eslint-disable-line
import {  BblTwoColumnTextIntroBlockFragment,  BblTwoColumnTextIntroBlockPreview } from "../components/blocks/BblTwoColumnTextIntroBlock" // eslint-disable-line
import {  BblTwoColumnWrapperBlockFragment,  BblTwoColumnWrapperBlockPreview } from "../components/blocks/BblTwoColumnWrapperBlock" // eslint-disable-line
import {  BblTwoColumnWrapperInnerBlockFragment,  BblTwoColumnWrapperInnerBlockPreview } from "../components/blocks/BblTwoColumnWrapperInnerBlock" // eslint-disable-line

import {  GravityformsFormBlockFragment,  GravityformsFormBlockPreview } from "../components/blocks/GravityformsFormBlock" // eslint-disable-line

export const CoreFields = graphql`
  fragment CoreFields on WpBlock {
    name
    ...CoreParagraphBlock
    ...CoreHeadingBlock
    ...CoreGalleryBlock
    ...CoreImageBlock
    ...CoreListBlock
    ...CoreSpacerBlock
    ...AcfAdvertSliderBlock
    ...AcfBackgroundContainerBlock
    ...AcfBackgroundImageContainerBlock
    ...AcfBrewGalleryBlock
    ...AcfButtonBlock
    ...AcfFacebookPagePluginBlock
    ...AcfGoogleReviewsBlock
    ...AcfGlobalContactInformationImageBlock
    ...AcfImageSliderBlock
    ...AcfJobsListBlock
    ...AcfLatestPostsGridBlock
    ...AcfLatestPostsSliderBlock
    ...AcfPageListingBlock
    ...AcfPdfReaderBlock
    ...AcfPeopleGridBlock
    ...AcfTimedContainerBlock
    ...BblBannerMessageBlock
    ...BblBannerMessageSliderBlock
    ...BblBannerStackedBlock
    ...BblBranchIntroBlock
    ...BblBranchListingBlock
    ...BblBrandDetailBlock
    ...BblBrandLogosBlock
    ...BblCalloutCtaBlock
    ...BblCardBlock
    ...BblCardRowBlock
    ...BblContactInformationImageBlock
    ...BblContactInformationMapBlock
    ...BblFiftyStackedContentBlock
    ...BblFormIntroBlock
    ...BblGoogleMapBlock
    ...BblIconRowBlock
    ...BblIconRowInnerBlock
    ...BblIntroGalleryBlock
    ...BblImageContentRowsBlock
    ...BblImageContentRowsInnerBlock
    ...BblJobDetailBlock
    ...BblManagerInfoBlock
    ...BblMediaItemBlock
    ...BblPageTitleBlock
    ...BblPostBannerBlock
    ...BblPostListingFullWidthBlock
    ...BblPostListingCardGridBlock
    ...BblPostWrapBlock
    ...BblPartsGridBlock
    ...BblSideCardBlock
    ...BblSideCardGridBlock
    ...BblSplitPageTitleBlock
    ...BblSplitPostTitleBlock
    ...BblStatBreakdownBlock
    ...BblStatBreakdownInnerBlock
    ...BblStickyPostFullWidthBlock
    ...BblStickyPostSplitBlock
    ...BblTextIntroBlock
    ...BblTwoColumnTextIntroBlock
    ...BblTwoColumnWrapperBlock
    ...BblTwoColumnWrapperInnerBlock
    ...GravityformsFormBlock
  }
`

export const PreviewBlocks = `
  ${CoreParagraphBlockPreview}
  ${CoreHeadingBlockPreview}
  ${CoreGalleryBlockPreview}
  ${CoreImageBlockPreview}
  ${CoreListBlockPreview}
  ${CoreSpacerBlockPreview}

  ${AcfAdvertSliderBlockPreview}
  ${AcfBackgroundContainerBlockPreview}
  ${AcfBackgroundImageContainerBlockPreview}
  ${AcfBrewGalleryBlockPreview}
  ${AcfButtonBlockPreview}
  ${AcfFacebookPagePluginBlockPreview}
  ${AcfGoogleReviewsBlockPreview}
  ${AcfGlobalContactInformationImageBlockPreview}
  ${AcfImageSliderBlockPreview}
  ${AcfJobsListBlockPreview}
  ${AcfLatestPostsGridBlockPreview}
  ${AcfLatestPostsSliderBlockPreview}
  ${AcfPageListingBlockPreview}
  ${AcfPdfReaderBlockPreview}
  ${AcfPeopleGridBlockPreview}
  ${AcfTimedContainerBlockPreview}

  ${BblBannerMessageBlockPreview}
  ${BblBannerMessageSliderBlockPreview}
  ${BblBannerStackedBlockPreview}
  ${BblBranchIntroBlockPreview}
  ${BblBranchListingBlockPreview}
  ${BblBrandDetailBlockPreview}
  ${BblBrandLogosBlockPreview}
  ${BblCalloutCtaBlockPreview}
  ${BblCardBlockPreview}
  ${BblCardRowBlockPreview}
  ${BblContactInformationImageBlockPreview}
  ${BblContactInformationMapBlockPreview}
  ${BblFiftyStackedContentBlockPreview}
  ${BblFormIntroBlockPreview}
  ${BblGoogleMapBlockPreview}
  ${BblIconRowBlockPreview}
  ${BblIconRowInnerBlockPreview}
  ${BblIntroGalleryBlockPreview}
  ${BblImageContentRowsBlockPreview}
  ${BblImageContentRowsInnerBlockPreview}
  ${BblJobDetailBlockPreview}
  ${BblManagerInfoBlockPreview}
  ${BblMediaItemBlockPreview}
  ${BblPageTitleBlockPreview}
  ${BblPostBannerBlockPreview}
  ${BblPostListingCardGridBlockPreview}
  ${BblPostListingFullWidthBlockPreview}
  ${BblPostWrapBlockPreview}
  ${BblPartsGridBlockPreview}
  ${BblSideCardBlockPreview}
  ${BblSideCardGridBlockPreview}
  ${BblSplitPageTitleBlockPreview}
  ${BblSplitPostTitleBlockPreview}
  ${BblStatBreakdownBlockPreview}
  ${BblStatBreakdownInnerBlockPreview}
  ${BblStickyPostFullWidthBlockPreview}
  ${BblStickyPostSplitBlockPreview}
  ${BblTextIntroBlockPreview}
  ${BblTwoColumnTextIntroBlockPreview}
  ${BblTwoColumnWrapperBlockPreview}
  ${BblTwoColumnWrapperInnerBlockPreview}

  ${GravityformsFormBlockPreview}
  
  `
