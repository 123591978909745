import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';
import ReactHtmlParser from 'react-html-parser';

import {CoreHeadingBlock} from "../CoreHeadingBlock";
import {CoreParagraphBlock} from "../CoreParagraphBlock";

const useStyles = makeStyles(theme => styles(theme));

export const BblStatBreakdownBlock = (props) => {

    const data = useStaticQuery(graphql`
        query statBreakdownOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        statBreakdown {
                            backgroundColour
                            headerColour
                            paragraphColour
                            numberColour
                            descriptionColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.statBreakdown;

    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.statBreakdown} `}
                data-override-background={overrides.backgroundColour !== 'tertiary' ? overrides.backgroundColour : null}
    >
        <div className="wrapper">
            <CoreHeadingBlock 
                attributes={{
                    textAlign: "center",
                    anchor: "",
                    className: "",
                    content: ReactHtmlParser(props.attributes.heading),
                    level: 3,
                    textColor: overrides.headerColour !== 'on-tertiary' ? overrides.headerColour : 'on-tertiary',
                    backgroundColor: "",
                    __typename: "WpCoreHeadingBlockAttributes",
                }}
                innerBlocks={[]} 
            />
            <CoreParagraphBlock 
                attributes={{
                    align: "center",
                    className: "",
                    content: ReactHtmlParser(props.attributes.paragraph),
                    textColor: overrides.paragraphColour !== 'on-tertiary' ? overrides.paragraphColour : 'on-tertiary',
                    backgroundColor: "",
                    fontSize: "",
                    __typename: "WpCoreParagraphBlockAttributes",
                }}
                innerBlocks={[]} 
            />
            <div className="stats">
                {blocks.map((block) => {
                    return block;
                })}
            </div>
        </div>
    </div>
};

export const BblStatBreakdownBlockFragment = graphql`
  fragment BblStatBreakdownBlock on WpBlock {
    ... on WpBblStatBreakdownBlock {
        name
        attributes {
            className
            heading
            paragraph
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblStatBreakdownBlockPreview = `
    ... on BblStatBreakdownBlock {
        name
        attributes {
            className
            heading
            paragraph
        }
    }
`;