import React, { Component } from "react"
import PreviewImage from "../../PreviewImage"
import { trackAdvertLinkClick } from '../../../services/gtm'

import Slider from "react-slick"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

export default class GallerySlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slider: [],
    }
  }

  componentDidMount() {
    this.setState({
      slider: this.props.images,
    });

  };




  render() {

    return (

      <Slider
        ref={slider => (this.slider1 = slider)}
        arrows={false}
        dots={true}
      >
        {this.state.slider.map(image => {
          // console.log(image.link)
          return (
            <a href={image.link} target="_blank" rel="noopener noreferrer" onClick={() => { trackAdvertLinkClick(image.link) }}
            >
              <PreviewImage
                image={image.image.localFile}
                fallbackUrl={image.image.uri}
                alt=""
                fixedPadding="28%"
              />
            </a>
          )
        })}
      </Slider>
    )
  }
}
