import { navigate } from 'gatsby';
import React from 'react';
import { isBrowser } from '../../helpers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default class GoBack extends React.Component {

    constructor(props) {
        super(props);

        this.back = this.back.bind(this);
    }

    back(){
        if (isBrowser) {
            if (!window.history.state) {
                navigate(this.props.fallback);
            } else {
                window.history.back();
            }
        }
    }

    render() {
        return (
            <button className="go-back" onClick={() => this.back()}><ArrowBackIcon />Back</button>
        );
    }
}