import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks, mergeBlocks} from "../../../services/builder";
import SocialShare from "../../SocialShare";
import styles from './styles';
import GoBack from '../../GoBack';

import TodayIcon from '@material-ui/icons/Today';

const useStyles = makeStyles(theme => styles(theme));

export const BblPostBannerBlock = (props) => {
    // console.log(props);

    const data = useStaticQuery(graphql`
        query postBannerOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        postBanner {
                            backgroundColour
                            headerColour
                            excerptColour
                            dateColour
                            categoryColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.postBanner;

    if(props.innerBlocks[0].attributes.textColor === "" && overrides.headerColour !== 'on-primary') {
        props.innerBlocks[0].attributes.textColor = overrides.headerColour;
    }

    if(props.innerBlocks[1].attributes.textColor === "" && overrides.excerptColour !== 'on-primary') {
        props.innerBlocks[1].attributes.textColor = overrides.excerptColour;
    }

    const mergedBlocks = mergeBlocks(props.innerBlocks, props.block.innerImage);
    const blocks = buildBlocks(mergedBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    // console.log(props.pageContext);

    return <div className={`${props.attributes.className ? props.attributes.className : ''} ${classes.postBanner}`}>
        <div className="banner"
             data-override-background={ overrides.backgroundColour !== 'primary' ? overrides.backgroundColour : null }  
        >
            <div class="wrapper">
                <p className="date" data-override-color={ overrides.dateColour !== 'on-primary' ? overrides.dateColour : null } ><TodayIcon /> {props.pageContext.date}</p>
                <p className="category" data-override-color={ overrides.categoryColour !== 'on-primary' ? overrides.categoryColour : null } >{props.pageContext.cats?.nodes[0].name}</p>
                {blocks.map((block) => {
                    return block;
                })}
            </div>
        </div>
        <div className="extras wrapper">
            <div className="go-back">
                <GoBack />
            </div>
            <div className="share">
                <SocialShare
                    path={props.pageContext.pagePath}
                    title={props.pageContext.pageTitle}
                />
            </div>
        </div>
    </div>
};

BblPostBannerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const BblPostBannerBlockFragment = graphql`
    fragment BblPostBannerBlock on WpBlock {
        ... on WpBblPostBannerBlock {
            name
            originalContent
            attributes {
                __typename
                ... on WpBblPostBannerBlockAttributes {
                    className
                }
            }
            innerImage: innerBlocks {
                ... on WpCoreImageBlock {
                    name
                    originalContent 
                    attributes {
                        __typename
                        ... on WpCoreImageBlockAttributes {
                            url
                            alt
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(
                                      width: 1140,
                                      aspectRatio: 2,
                                      placeholder: BLURRED,
                                      formats: [AUTO, WEBP, AVIF],
                                      transformOptions: {fit: COVER, cropFocus: CENTER},
                                      quality: 90
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblPostBannerBlockPreview = `
    ... on BblPostBannerBlock {
        name
        originalContent
        attributes {
            __typename
            ... on BblPostBannerBlockAttributes {
                className
            }
        }
    }
`;