export default theme => ({ // eslint-disable-line
    postListingFullWidth: {
        padding: '24px 0',
        [theme.breakpoints.up('md')]: {
            padding: '64px 0 24px',
        },
        '& .list-item': {
            padding: '32px 0',
            borderBottom: '2px solid ' + theme.common.black,
            width: 'calc(100% - 24px)',
            margin: '0 12px',
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                padding: '48px 0',
                flexDirection: 'row-reverse',
            },
            '&:first-of-type': {
                borderTop: '2px solid ' + theme.common.black,
            },
            '& .gatsby-image-wrapper': {
                marginBottom: 32,
                flex: '0 0 100%',
                maxWidth: '100%',
                [theme.breakpoints.up('sm')]: {
                    marginBottom: 0,
                    flex: '0 0 calc(33.333% - 12px)',
                    maxWidth: 'calc(33.333% - 12px)',
                    marginLeft: 24,
                },
                [theme.breakpoints.up('md')]: {
                    marginBottom: 0,
                    flex: '0 0 calc(41.666% - 12px)',
                    maxWidth: 'calc(41.666% - 12px)',
                }
            },
            '& .con-wrap': {
                flex: '0 0 100%',
                maxWidth: '100%',
                [theme.breakpoints.up('sm')]: {
                    marginBottom: 0,
                    flex: '0 0 calc(66.666% - 12px)',
                    maxWidth: 'calc(66.666% - 12px)',
                },
                [theme.breakpoints.up('md')]: {
                    marginBottom: 0,
                    flex: '0 0 calc(58.3333% - 12px)',
                    maxWidth: 'calc(58.333% - 12px)',
                },
                '& .meta': {
                    '& > p': {
                        '&.date, &.category': {
                            display: 'inline-block',
                            width: '50%',
                            fontSize: 12,
                            letterSpacing: '0.2em',
                            lineHeight: '20px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            color: theme.palette.accent.main,
                            marginBottom: 32,
                            marginTop: 0,
                            '& svg': {
                                width: 16,
                                height: 16,
                                display: 'inline-block',
                                verticalAlign: 'top',
                                marginTop: 2,
                                marginRight: 5,
                            }
                        },
                        '&.category': {
                            textAlign: 'right',
                        }
                    }
                },
                '& .MuiTypography-root': {
                    marginBottom: 24,
                    padding: 0,
                    [theme.breakpoints.up('lg')]: {
                        maxWidth: '85%',
                        marginLeft: 0,
                        marginRight: 0,
                    }
                },
                '& p': {
                    padding: 0,
                    [theme.breakpoints.up('lg')]: {
                        maxWidth: '85%',
                        marginLeft: 0,
                        marginRight: 0,
                    }
                },
                '& .button': {
                    marginLeft: 0,
                    marginBottom: 0,
                }
            }
        },
        '& nav.MuiPagination-root': {
            flex: '0 0 100%',
            maxWidth: '100%',
            padding: '0 12px',
            margin: '50px 0',
            [theme.breakpoints.up('md')]: {
                margin: '100px 0',
            },
            '& ul': {
                justifyContent: 'center',
                '& .MuiPaginationItem-root': {
                    fontFamily: theme.headerFont,
                    borderRadius: theme.border.radius,
                    [theme.breakpoints.up('md')]: {
                        height: 44,
                        width: 44,
                        lineHeight: '44px',
                        fontSize: 18,
                    },
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                    }
                }
            }
        }
    },
})