const styles = theme => ({
  pageTitle: {
    backgroundColor: theme.palette.primary.main,
    margin: "0 auto",
    padding: "50px 0 34px",
    [theme.breakpoints.up("md")]: {
      padding: "80px 0 64px",
    },
    "& .MuiTypography-root": {
      color: theme.palette.primary.contrastText,
      marginBottom: 16,
    },
    "& p": {
      marginBottom: 16,
      color: theme.palette.primary.contrastText,
      '&[data-align="center"]': {
        marginLeft: "8.333%",
        marginRight: "8.333%",
      },
    },
    "& .gatsby-image-wrapper": {
      marginBottom: 16,
      marginLeft: 12,
      marginRight: 12,
      [theme.breakpoints.up("md")]: {
        marginTop: 50,
      },
    },
  },
})
export default styles
