import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"

import { CoreParagraphBlock } from "../CoreParagraphBlock"

export const BblStatBreakdownInnerBlock = props => {
  const data = useStaticQuery(graphql`
    query statBreakdownInnerOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            statBreakdown {
              backgroundColour
              headerColour
              paragraphColour
              numberColour
              descriptionColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.statBreakdown

  return (
    <div
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      }`}
    >
      <div
        className="number"
        data-override-color={ overrides.numberColour !== "on-tertiary" ? overrides.numberColour : "on-tertiary" }
      >
        {props.attributes.number}
      </div>
      <CoreParagraphBlock
        attributes={{
          align: "center",
          className: "",
          content: ReactHtmlParser(props.attributes.desc),
          textColor:
            overrides.descriptionColour !== "on-tertiary" ? overrides.descriptionColour : "on-tertiary",
          backgroundColor: "",
          fontSize: "",
          __typename: "WpCoreParagraphBlockAttributes",
        }}
        innerBlocks={[]}
      />
    </div>
  )
}

export const BblStatBreakdownInnerBlockFragment = graphql`
  fragment BblStatBreakdownInnerBlock on WpBlock {
    ... on WpBblStatBreakdownInnerBlock {
      name
      attributes {
        className
        number
        desc
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblStatBreakdownInnerBlockPreview = `
    ... on BblStatBreakdownInnerBlock {
        name
        attributes {
            className
            number
            desc
        }
    }
`
