export default theme => ({ // eslint-disable-line
    splitPageTitle: {
        backgroundColor: theme.palette.primary.main,
        padding: '28px 0',
        '& .wrapper': {
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
            },
            '& .image-wrap': {
                margin: 12,
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 calc(50% - 24px)',
                    maxWidth: 'calc(50% - 24px)',
                },
            },
            '& .con-wrap': {
                backgroundColor: theme.palette.background.default,
                padding: 24,
                margin: 12,
                [theme.breakpoints.up('md')]: {
                    padding: 40,
                    paddingBottom: 24,
                    flex: '0 0 calc(50% - 24px)',
                    maxWidth: 'calc(50% - 24px)',
                },
                [theme.breakpoints.up('lg')]: {
                    padding: 72,
                    paddingBottom: 24,
                },
                '& > * ': {
                    padding: 0,
                },
                '& .MuiTypography-root': {
                    marginBottom: 32,
                }
            }
        }
    },
})