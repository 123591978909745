import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { transformLink } from '../../../helpers/';
import { graphql } from "gatsby";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CoreParagraphBlock = (props) => {
  // console.log(props);
  const classes = useStyles();

  if( props.attributes.content === "" || props.attributes.content === null ) return null;

  return <p
    className={classes.para + ' ' + props.attributes.fontSize + ' ' + props.attributes.className}
    data-align={props.attributes.align !== "" ? props.attributes.align : 'left'}
    data-override-color={props.attributes.textColor !== "" ? props.attributes.textColor : 'initial' }
    data-override-background={props.attributes.backgroundColor !== "" ? props.attributes.backgroundColor : 'initial' }
    data-drop-cap={props.attributes.dropCap}
  >
        {props.attributes.preParagraph && props.attributes.preParagraph}

    {ReactHtmlParser(props.attributes.content, {transform : transformLink})}
  </p>
};

CoreParagraphBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CoreParagraphBlockFragment = graphql`
fragment CoreParagraphBlock on WpBlock {
  ... on WpCoreParagraphBlock {
    name
    attributes {
      __typename
      ... on WpCoreParagraphBlockAttributes {
        backgroundColor
        textColor
        className
        align
        direction
        content
        fontSize
        dropCap
      }
    }
  }
}`;

export const CoreParagraphBlockPreview = `
... on CoreParagraphBlock {
  name
  attributes {
    __typename
    ... on CoreParagraphBlockAttributes {
      backgroundColor
      textColor
      className
      align
      direction
      content
      fontSize
      dropCap
    }
  }
}
`;