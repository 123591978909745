const styles = theme => ({
  peopleGrid: {
    padding: "40px 0",
    [theme.breakpoints.up("md")]: {
      padding: "40px 0 80px",
    },
    "& .wrapper": {
      "& > .MuiTypography-root": {
        marginBottom: 32,
        [theme.breakpoints.up("md")]: {
          marginBottom: 80,
        },
      },
      "& .listing": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flex: "0 0 100%",
        maxWidth: "100%",
        "& div.person": {
          padding: "0 0 32px",
          position: "relative",
          [theme.breakpoints.up("sm")]: {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
          [theme.breakpoints.up("md")]: {
            padding: "0 0 80px",
            flex: "0 0 25%",
            maxWidth: "25%",
          },
          "& .gatsby-image-wrapper": {
            marginBottom: 4,
            marginLeft: 12,
            marginRight: 12,
          },
          "& .split": {
            margin: "0 12px",
            display: "block",
            height: 4,
            backgroundColor: theme.palette.accent.main,
            marginBottom: 16,
          },
          "& > .MuiTypography-root": {
            marginBottom: 8,
          },
          "& .job-title": {
            marginBottom: 20,
          },
          "& .button": {
            marginTop: 50,
            marginLeft: 12,
            position: "absolute",
            bottom: 0,
          },
        },
      },
    },
  },
})
export default styles
