import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/styles';

import Marker from './Marker';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const BblGoogleMapBlock = (props) => {
    const classes = useStyles();

    const { latitude, longitude } = props.attributes;

    if (!latitude || !longitude) return null;

    const defaultCenter = Object.assign({}, {
        lat: Number(latitude),
        lng: Number(longitude)
      });

    return <div
        className={`${classes.googleMap} ${props.attributes.className ? props.attributes.className : ''}`}
    >
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCTv5gH57Owv4nY85Pw90H5_4iw3awM2HU' }}
          center={defaultCenter}
          defaultZoom={12}
          yesIWantToUseMapApiInternals
        >
          <Marker lat={latitude} lng={longitude} />
        </GoogleMapReact>
    </div>
};

BblGoogleMapBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const BblGoogleMapBlockFragment = graphql`
    fragment BblGoogleMapBlock on WpBlock {
        ... on WpBblGoogleMapBlock {
            name
            attributes {
                __typename
                ... on WpBblGoogleMapBlockAttributes {
                    className
                    latitude
                    longitude
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblGoogleMapBlockPreview = `
    ... on BblGoogleMapBlock {
        name
        attributes {
            __typename
            ... on BblGoogleMapBlockAttributes {
                className
                latitude
                longitude
            }
        }
    }
`;