export default theme => ({ // eslint-disable-line
    googleReviews: {
        backgroundColor: theme.palette.tertiary.main,
        '& .wrapper': {
            justifyContent: "space-between",
            padding: "50px 0",
            [theme.breakpoints.up("md")]: {
                padding: "100px 0",
            },
            [theme.breakpoints.up("lg")]: {
                padding: "200px 0",
            },
            "& .left-block": {
                flex: "0 0 100%",
                maxWidth: "100%",
                [theme.breakpoints.up("md")]: {
                    flex: "0 0 41.666%",
                    maxWidth: "41.666%",
                },
                [theme.breakpoints.up("lg")]: {
                    flex: "0 0 25%%",
                    maxWidth: "25%",
                    marginRight: "8.333%",
                },

                "& .MuiTypography-root": {
                    marginBottom: 32,
                },
                "& .overall-review": {
                    display: 'flex',
                    backgroundColor: 'white',
                    alignItems: "flex-start",
                    padding: 12,
                    marginTop: 8,
                    width: 240,
                    marginLeft: 12,
                    "& img": {
                        marginTop: 4,
                        marginLeft: 4,
                    },
                    "& .review-group-1": {
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: 12,
                        "& p": {
                            fontSize: 16,
                            fontFamily: "Arial",
                            margin: "0 0",
                            fontWeight: "bold"
                        },
                        "& .review-group-2": {
                            display: "flex",
                            alignItems: "center",
                            marginTop: -12,
                            "& p": {
                                fontSize: 32,
                                fontWeight: "bold",
                                marginTop: 12,
                                letterSpacing: '-0.032em',
                                paddingRight: 5,
                            },
                            "& .review-group-3": {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "center",
                                "& p": {
                                    fontFamily: "Arial",
                                    fontSize: 10,
                                    opacity: 0.3,
                                    margin: "-6px 4px"
                                }
                            }
                        }
                    }
                }
            },
            "& .reviews": {
                padding: "48px 64px",
                marginTop: 32,
                backgroundColor: theme.palette.accent.contrastText,
                flex: "0 0 calc(100% - 24px)",
                maxWidth: "calc(100% - 24px)",
                margin: '0 12px',
                [theme.breakpoints.up("md")]: {
                    flex: "0 0 calc(58.333% - 24px)",
                    maxWidth: "calc(58.333% - 24px)",
                    marginTop: 0,
                },
                [theme.breakpoints.up("lg")]: {
                    flex: "0 0 calc(66.666% - 24px)",
                    maxWidth: "calc(66.666% - 24px)",
                },
                "& .button": {
                    margin: "32px auto 0",
                    display: "table"
                },
                "& .review-relative-time": {
                    opacity: 0.3,
                    fontSize: 12,
                },
                '& p': {
                    lineHeight: 1.5,
                },
                "& hr": {
                    opacity: 0.3,
                    marginTop: 24,
                    height: 1,
                    border: 'none',
                },
                "& .stars-and-author": {
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "-6px",
                    marginBottom: "-8px",
                    '& p': {
                        marginLeft: 12,
                    }
                },

            },
        },
        "& .stars": {
            display: "flex",
            flexWrap: "nowrap",
            "& .empty-star": {
                color: "#c4c4c4"
            },
            "& .filled-star": {
                color: "#fec300"
            }
        },
    }
})
