import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import GoBack from '../../GoBack';

import { CoreParagraphBlock } from "../CoreParagraphBlock";
import { AcfButtonBlock } from "../AcfButtonBlock";

const useStyles = makeStyles(theme => styles(theme));

export const BblJobDetailBlock = (props) => {

    const classes = useStyles();

    const data = useStaticQuery(graphql`
    query JobDetailBlock {
      wp {
          themeOptions {
              ThemeOptions {
                  jobDetail {
                      backgroundColour
                      headerColour
                      paragraphColour
                  }
              }
          }
      }
    }
    `);

    const overrides = data.wp.themeOptions.ThemeOptions.jobDetail;


    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.jobDetail} `}
        data-override-background={overrides.backgroundColour !== 'background' ? overrides.backgroundColour : null}

    >
        <div className="wrapper">
            <div className="back">
                <GoBack />
            </div>
            <div className="content">
                <CoreParagraphBlock
                    attributes={{
                        align: "left",
                        fontSize: "lead",
                        className: "",
                        content: props.pageContext.job.Jobs.introductionSnippet,
                        textColor: overrides.headerColour !== 'black' ? overrides.headerColour : 'black',
                        backgroundColor: "",
                        __typename: "WpCoreParagraphBlockAttributes",
                    }}
                    innerBlocks={[]}
                />
                <div dangerouslySetInnerHTML={{ __html: props.pageContext.job.Jobs.jobDetails }}
                    data-override-color={overrides.paragraphColour !== 'black' ? overrides.paragraphColour : null}

                />
                <AcfButtonBlock
                    attributes={{
                        className: "",
                    }}
                    block={{
                        ButtonFields: {
                            buttonLink: {
                                target: "",
                                title: "Apply Now",
                                url: `mailto:${props.pageContext.job.Jobs.applicationEmail}`,
                            },
                            buttonStyle: "secondary",
                            icon: "email",
                            showIcon: "left",
                        }
                    }}
                    innerBlocks={[]}
                />
            </div>
        </div>
    </div>
};

export const BblJobDetailBlockFragment = graphql`
  fragment BblJobDetailBlock on WpBlock {
    ... on WpBblJobDetailBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblJobDetailBlockPreview = `
    ... on BblJobDetailBlock {
        name
        attributes {
            className
        }
    }
`;