import React from 'react';
import { graphql } from "gatsby";
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CoreGalleryBlock = (props) => {

  // console.log(props)

  const classes = useStyles();

  return <div className={"gallery " + classes.gallery}>
    {ReactHtmlParser(props.originalContent)}
  </div>
};

export const CoreGalleryBlockFragment = graphql`
  fragment CoreGalleryBlock on WpBlock {
    ...on WpCoreGalleryBlock {
      name
      originalContent
    }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CoreGalleryBlockPreview = `
...on CoreGalleryBlock {
    name
    originalContent
  }
}
`;