import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks, mergeBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const BblCardBlock = (props) => {

    // console.log(props);

    const data = useStaticQuery(graphql`
        query cardOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        cards {
                            backgroundColour
                            headerColour
                            paragraphColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.cards;

    if(props.innerBlocks[1].attributes.textColor === "" && overrides.headerColour !== 'on-secondary') {
        props.innerBlocks[1].attributes.textColor = overrides.headerColour;
    }

    if(props.innerBlocks[2].attributes.textColor === "" && overrides.paragraphColour !== 'on-secondary') {
        props.innerBlocks[2].attributes.textColor = overrides.paragraphColour;
    }

    const mergedBlocks = mergeBlocks(props.innerBlocks, props.block.innerImage);
    const blocks = buildBlocks(mergedBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.cardBlock} `}
                data-override-background={ overrides.backgroundColour !== 'secondary' ? overrides.backgroundColour : null }  
    >
        {blocks[0]}
        <div className="con-wrap">
            <div className="min-height">
                    {blocks[1]}
                    {blocks[2]}
            </div>
            <div className="links">
                {blocks[3]}
            </div>
        </div>
    </div>
};

export const BblCardBlockFragment = graphql`
  fragment BblCardBlock on WpBlock {
    ... on WpBblCardBlock {
        name
        attributes {
            className
        }
        innerImage: innerBlocks {
            ... on WpCoreImageBlock {
                name
                originalContent 
                attributes {
                    __typename
                    ... on WpCoreImageBlockAttributes {
                        url
                        alt
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                  width: 992,
                                  aspectRatio: 1.78,
                                  placeholder: BLURRED,
                                  formats: [AUTO, WEBP, AVIF],
                                  transformOptions: {fit: COVER, cropFocus: CENTER},
                                  quality: 90
                                )
                            }
                        }
                    }
                }
            }
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblCardBlockPreview = `
    ... on BblCardBlock {
        name
        attributes {
            className
        }
        inner: innerBlocks {
            ... on WpCoreImageBlock {
                name
                originalContent {
                    attributes {
                        __typename
                        ... on WpCoreImageBlockAttributes {
                            url
                        }
                    }
                }
            }
        }
    }
`;