import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks, mergeBlocks } from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const BblBannerStackedBlock = (props) => {

    // console.log(props);

    const data = useStaticQuery(graphql`
        query bannerStackedOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        bannerStacked {
                            backgroundColour
                            headerColour
                            paragraphColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.bannerStacked;

    if (props.innerBlocks[0].attributes.textColor === "" && overrides.headerColour !== 'on-primary') {
        props.innerBlocks[0].attributes.textColor = overrides.headerColour;
    }

    if (props.innerBlocks[1].attributes.textColor === "" && overrides.paragraphColour !== 'on-primary') {
        props.innerBlocks[1].attributes.textColor = overrides.paragraphColour;
    }

    const mergedBlocks = mergeBlocks(props.innerBlocks, props.block.innerImage);
    const blocks = buildBlocks(mergedBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.bannerStackedBlock} `}
        data-override-background={overrides.backgroundColour !== 'primary' ? overrides.backgroundColour : null}
    >
        <div className="wrapper">
            {blocks.map((block) => {
                return block;
            })}
        </div>
    </div>
};

export const BblBannerStackedBlockFragment = graphql`
  fragment BblBannerStackedBlock on WpBlock {
    ... on WpBblBannerStackedBlock {
        name
        attributes {
            className
        }
        innerImage: innerBlocks {
            ... on WpCoreImageBlock {
                name
                originalContent 
                attributes {
                    __typename
                    ... on WpCoreImageBlockAttributes {
                        url
                        alt
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                  width: 1140,
                                  aspectRatio: 1.78,
                                  placeholder: BLURRED,
                                  formats: [AUTO, WEBP, AVIF],
                                  transformOptions: {fit: COVER, cropFocus: CENTER},
                                  quality: 90
                                )
                            }
                        }
                    }
                }
            }
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblBannerStackedBlockPreview = `
    ... on BblBannerStackedBlock {
        name
        attributes {
            className
        }
        inner: innerBlocks {
            ... on WpCoreImageBlock {
                name
                originalContent {
                    attributes {
                        __typename
                        ... on WpCoreImageBlockAttributes {
                            url
                        }
                    }
                }
            }
        }
    }
`;