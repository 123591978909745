import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import GallerySlider from "./slider"

import { makeStyles } from "@material-ui/styles"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfAdvertSliderBlock = props => {
  const classes = useStyles()

  const ads = props.block.blockAdvertSlider.adverts

  return (
    <div className={`${props.attributes.className ? props.attributes.className : ""} ${props.block.blockAdvertSlider.contained ? "contained" : "" } ${classes.advertSlider} `}>
      <GallerySlider images={ads} />
    </div>
  )
}

AcfAdvertSliderBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfAdvertSliderBlockFragment = graphql`
  fragment AcfAdvertSliderBlock on WpBlock {
    ... on WpAcfAdvertSliderBlock {
      name
      attributes {
        className
      }
      blockAdvertSlider {
        contained
        adverts {
          link
          image {
            id
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 1660
                  aspectRatio: 3.5
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF],
                  quality: 90
                )
              }
            }
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfAdvertSliderBlockPreview = `
  ... on AcfAdvertSliderBlock {
      name
      attributes {
          className
      }
      blockAdvertSlider {
        contained
        adverts {
          link
          image {
            id
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 1160
                  aspectRatio: 3.5
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF],
                  quality: 90
                )
              }
            }
          }
        }
      }
    }
  }
`
