import React from "react"
import ReactHtmlParser from "react-html-parser"

class PdfPageNumberBar extends React.Component {
  leadingZeroForSingleDigitPages = number => {
    if (number !== null && number.toString().length < 2) {
      return `0${number}`
    } else {
      return number
    }
  }

  buildNumPageBar = (currentPageNumber, numberPages) => {
    let barComponent = ""
    for (let i = 0; i < numberPages; i++) {
      if (currentPageNumber - 1 === i) {
        barComponent =
          barComponent +
          `<div class="active"       
            ></div>`
      } else {
        barComponent = barComponent + `<div></div>`
      }
    }
    return barComponent
  }

  render() {
    return (
      <div className="pageNumberBar">
        {ReactHtmlParser(
          this.buildNumPageBar(this.props.pageNumber, this.props.numPages)
        )}
        <p
          data-override-colour={
            this.props.override !== "primary" ? this.props.override : null
          }
        >
          {this.leadingZeroForSingleDigitPages(this.props.pageNumber) ||
            (this.props.numPages ? 1 : "--")}{" "}
          / {this.leadingZeroForSingleDigitPages(this.props.numPages) || "--"}
        </p>
      </div>
    )
  }
}

export default PdfPageNumberBar
