export default theme => ({ // eslint-disable-line
    stickyPostFullWidth: {
        paddingTop: 32,
        [theme.breakpoints.up('md')]: {
            paddingTop: 88,
        },
        '& .sticky-post': {
            flex: '0 0 calc(100% - 24px)',
            maxWidth: 'calc(100% - 24px)',
            margin: '0 12px',
            padding: 24,
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                padding: '32px 24px 48px 48px',
            },
            '& .gatsby-image-wrapper': {
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                opacity: .3,
                zIndex: 1,
            },
            '& .meta': {
                position: 'relative',
                zIndex: 2,
                '& > p': {
                    '&.date, &.category': {
                        display: 'inline-block',
                        width: '50%',
                        fontSize: 12,
                        letterSpacing: '0.2em',
                        lineHeight: '20px',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        color: theme.palette.accent.main,
                        marginBottom: 32,
                        marginTop: 0,
                        padding: '12px 0',
                        '& svg': {
                            width: 16,
                            height: 16,
                            display: 'inline-block',
                            verticalAlign: 'top',
                            marginTop: 2,
                            marginRight: 5,
                        }
                    },
                    '&.category': {
                        textAlign: 'right',
                        backgroundColor: theme.palette.accent.main,
                        color: theme.palette.accent.contrastText,
                        marginLeft: 'auto',
                        display: 'inline-block',
                        padding: '12px 16px',
                        width: 'auto',
                        float: 'right',
                    }
                }
            },
            '& .con-wrap': {
                zIndex: 2,
                position: 'relative',
                [theme.breakpoints.up('md')]: {
                    marginTop: 120,
                },
                [theme.breakpoints.up('lg')]: {
                    marginTop: 160,
                },
                '& .MuiTypography-root': {
                    color: theme.palette.primary.contrastText,
                    padding: 0,
                    maxWidth: 400,
                    marginLeft: 0,
                    marginRight: 0,
                    [theme.breakpoints.up('md')]: {
                        marginBottom: 32,
                    }
                },
                '& p': {
                    color: theme.palette.primary.contrastText,
                    padding: 0,
                    maxWidth: 400,
                    marginLeft: 0,
                    marginRight: 0,
                },
                '& .button': {
                    marginLeft: 0,
                    marginBottom: 0,
                }
            }
        }
    },
})