import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const BblFiftyStackedContentBlock = (props) => {

    const data = useStaticQuery(graphql`
        query fiftyStackedContentBlock {
            wp {
                themeOptions {
                    ThemeOptions {
                        fiftyStackedContent {
                            backgroundColour
                            headerColour
                            paragraphColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.fiftyStackedContent;

    if(props.innerBlocks[1].attributes.textColor === "" && overrides.headerColour !== 'black') {
        props.innerBlocks[1].attributes.textColor = overrides.headerColour;
    }

    if(props.innerBlocks[2].attributes.textColor === "" && overrides.paragraphColour !== 'black') {
        props.innerBlocks[2].attributes.textColor = overrides.paragraphColour;
    }

    if(props.innerBlocks[5].attributes.textColor === "" && overrides.headerColour !== 'black') {
        props.innerBlocks[5].attributes.textColor = overrides.headerColour;
    }

    if(props.innerBlocks[6].attributes.textColor === "" && overrides.paragraphColour !== 'black') {
        props.innerBlocks[6].attributes.textColor = overrides.paragraphColour;
    }

    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.fiftyStackedContent} `}
                data-override-background={overrides.backgroundColour !== 'background' ? overrides.backgroundColour : null}
    >
        <div className="wrapper">
            <div>
                {blocks[0]}
                {blocks[1]}
                {blocks[2]}
                {blocks[3]}
            </div>
            <div>
                {blocks[4]}
                {blocks[5]}
                {blocks[6]}
                {blocks[7]}
            </div>
        </div>
    </div>
};

export const BblFiftyStackedContentBlockFragment = graphql`
  fragment BblFiftyStackedContentBlock on WpBlock {
    ... on WpBblFiftyStackedContentBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblFiftyStackedContentBlockPreview = `
    ... on BblFiftyStackedContentBlock {
        name
        attributes {
            className
        }
    }
`;