import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';
import ReactHtmlParser from 'react-html-parser';
import {CoreHeadingBlock} from "../CoreHeadingBlock";
import RoomIcon from '@material-ui/icons/Room';

const useStyles = makeStyles(theme => styles(theme));

export const BblBranchIntroBlock = (props) => {

    // console.log(props.pageContext);

    const data = useStaticQuery(graphql`
        query branchIntroOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        branchIntro {
                            backgroundColour
                            iconColour
                            headerColour
                            paragraphColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.branchIntro;

    if(props.innerBlocks[0].attributes.textColor === "" && overrides.paragraphColour !== 'on-primary') {
        props.innerBlocks[0].attributes.textColor = overrides.paragraphColour;
    }

    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.textIntro} `}
                data-override-background={overrides.backgroundColour !== 'primary' ? overrides.backgroundColour : null}
    >
        <div className="wrapper">
            <CoreHeadingBlock 
                attributes={{
                    textAlign: "left",
                    anchor: "",
                    className: "",
                    content: props.pageContext.acfFields.name !== null ? ReactHtmlParser(props.pageContext.acfFields.name) : "[branch.name]",
                    level: 1,
                    textColor: overrides.headerColour !== 'on-primary' ? overrides.headerColour : 'on-primary',
                    backgroundColor: "",
                    preHeading: <RoomIcon data-override-color={overrides.iconColour !== 'primary' ? overrides.iconColour : null} />,
                    __typename: "WpCoreHeadingBlockAttributes",
                }}
                innerBlocks={[]} 
            />
            {blocks[0]}
        </div>
    </div>
};

export const BblBranchIntroBlockFragment = graphql`
  fragment BblBranchIntroBlock on WpBlock {
    ... on WpBblBranchIntroBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblBranchIntroBlockPreview = `
    ... on BblBranchIntroBlock {
        name
        attributes {
            className
        }
    }
`;