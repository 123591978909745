import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import styles from './styles';
import PreviewImage from '../../PreviewImage';

const useStyles = makeStyles(theme => styles(theme));

export const BblBannerMessageBlock = (props) => {
    
    // console.log(props);
    
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div
        className={`${props.attributes.className ? props.attributes.className : ''} ${classes.bannerMessage}`}
    >
        <PreviewImage 
            image={props.attributes.localFile} 
            fallbackUrl={props.attributes.mediaUrl} 
            alt="" 
            fixedPadding="60%"
            className="desktop-image"
        />
        <PreviewImage 
            image={props.attributes.localFileMobile} 
            fallbackUrl={props.attributes.mediaUrlMobile} 
            alt="" 
            fixedPadding="125%"
            className="mobile-image"
        />
        <div className="wrapper" data-bg={props.attributes.hasBackground}>
            <div className="con-wrap">
                {blocks.map((block) => {
                    return block;
                })}
            </div>
        </div>
    </div>
};

BblBannerMessageBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const BblBannerMessageBlockFragment = graphql`
    fragment BblBannerMessageBlock on WpBlock {
        ... on WpBblBannerMessageBlock {
            name
            attributes {
                __typename
                ... on WpBblBannerMessageBlockAttributes {
                    className
                    hasBackground
                    mediaUrl
                    mediaUrlMobile
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                              width: 1660,
                              aspectRatio: 1.75,
                              placeholder: BLURRED,
                              formats: [AUTO, WEBP, AVIF],
                              transformOptions: {fit: COVER, cropFocus: CENTER},
                              quality: 90
                            )
                        }
                    }
                    localFileMobile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                              width: 768,
                              aspectRatio: 0.85,
                              placeholder: BLURRED,
                              formats: [AUTO, WEBP, AVIF],
                              transformOptions: {fit: COVER, cropFocus: CENTER},
                              quality: 90
                            )
                        }
                    }
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblBannerMessageBlockPreview = `
    ... on BblBannerMessageBlock {
        name
        attributes {
            __typename
            ... on BblBannerMessageBlockAttributes {
                className
                hasBackground
                mediaUrl
                mediaUrlMobile
            }
        }
    }
`;