export default theme => ({ // eslint-disable-line
    latestPostsGrid: {
        position: 'relative',
        backgroundColor: theme.palette.tertiary.main,
        padding: '50px 0',
        [theme.breakpoints.up('md')]: {
            padding: '85px 0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '120px 0',
        },
        '& .wrapper': {
            position: 'relative',
            '& > .MuiTypography-root': {
                marginBottom: 32,
                color: theme.palette.tertiary.contrastText,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 50,
                }
            }
        },
        '& .latest-post-grid': {
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },
            '& .newest': {
                marginBottom: 32,
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 58.333%',
                    padding: '0 12px',
                    maxWidth: '58.333%',
                    marginBottom: 0,
                },
                '& a': {
                    display: 'block',
                    textDecoration: 'none',
                    color: theme.palette.primary.contrastText,
                    marginBottom: 48,
                    position: 'relative',
                    '& .con-wrap': {
                        display: 'block',
                        padding: 24,
                        backgroundColor: theme.palette.primary.main,
                        position: 'relative',
                        [theme.breakpoints.up('md')]: {
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            padding: '24px 36px',
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'transparent !important',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                display: 'block',
                                zIndex: 1,
                                opacity: .4,
                                backgroundColor: theme.palette.primary.main,
                            },
                            '& *': {
                                position: 'relative',
                                zIndex: 2,
                            },
                            '&.background-primary': {
                                backgroundColor: theme.palette.primary.main,
                                '&::before': { backgroundColor: theme.palette.primary.main, }
                            },
                            '&.background-on-primary': {
                                backgroundColor: theme.palette.primary.main,
                                '&::before': { backgroundColor: theme.palette.primary.contrastText, }
                            },
                            '&.background-primary-highlight': {
                                backgroundColor: theme.palette.primary.highlight,
                                '&::before': { backgroundColor: theme.palette.primary.highlight, }
                            },
                            '&.background-secondary': {
                                backgroundColor: theme.palette.secondary.main,
                                '&::before': { backgroundColor: theme.palette.secondary.main, }
                            },
                            '&.background-on-secondary': {
                                backgroundColor: theme.palette.secondary.main,
                                '&::before': { backgroundColor: theme.palette.secondary.contrastText, }
                            },
                            '&.background-secondary-highlight': {
                                backgroundColor: theme.palette.secondary.highlight,
                                '&::before': { backgroundColor: theme.palette.secondary.highlight, }
                            },
                            '&.background-tertiary': {
                                backgroundColor: theme.palette.tertiary.main,
                                '&::before': { backgroundColor: theme.palette.tertiary.main, }
                            },
                            '&.background-on-tertiary': {
                                backgroundColor: theme.palette.tertiary.main,
                                '&::before': { backgroundColor: theme.palette.tertiary.contrastText, }
                            },
                            '&.background-tertiary-highlight': {
                                backgroundColor: theme.palette.tertiary.highlight,
                                '&::before': { backgroundColor: theme.palette.tertiary.highlight, }
                            },
                            '&.background-accent': {
                                backgroundColor: theme.palette.accent.main,
                                '&::before': { backgroundColor: theme.palette.accent.main, }
                            },
                            '&.background-on-accent': {
                                backgroundColor: theme.palette.accent.main,
                                '&::before': { backgroundColor: theme.palette.accent.contrastText, }
                            },
                            '&.background-accent-highlight': {
                                backgroundColor: theme.palette.accent.highlight,
                                '&::before': { backgroundColor: theme.palette.accent.highlight, }
                            },
                            '&.background-background': {
                                backgroundColor: theme.palette.background.default,
                                '&::before': { backgroundColor: theme.palette.background.default, }
                            },
                            '&.background-white': {
                                backgroundColor: theme.common.white,
                                '&::before': { backgroundColor: theme.common.white, }
                            },
                            '&.background-black': {
                                backgroundColor: theme.common.black,
                                '&::before': { backgroundColor: theme.common.black, }
                            },
                        },
                        '& .date': {
                            fontSize: 12,
                            lineHeight: 2,
                            letterSpacing: '0.200em',
                            marginBottom: 12,
                            display: 'block',
                            textTransform: 'uppercase',
                        },
                        '& .title': {
                            display: 'block',
                            marginBottom: 15,
                            fontSize: 20,
                            lineHeight: 1.4,
                            letterSpacing: '0.008em',
                            fontWeight: 'bold',
                            fontFamily: theme.headerFont,
                            [theme.breakpoints.up('md')]: {
                                fontSize: 32,
                                lineHeight: 1.5,
                            },
                        },
                        '& .excerpt': {
                            opacity: .8,
                            fontSize: 16,
                            lineHeight: 1.5,
                            height: 48,
                            overflow: 'hidden',
                            display: 'block',
                            letterSpacing: '0.016em',
                            textShadow: '0 0 2px ' + theme.palette.primary.main,
                        }
                    }
                }
            },
            '& .more': {
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 41.666%',
                    padding: '0 12px',
                    maxWidth: '41.666%',
                },
                '& a.more-link': {
                    display: 'block',
                    padding: 0,
                    textDecoration: 'none',
                    color: theme.palette.tertiary.contrastText,
                    '& hr': {
                        marginTop: 20,
                        marginBottom: 30,
                        opacity: .3,
                    },
                    '& .date': {
                        fontSize: 12,
                        lineHeight: 2,
                        letterSpacing: '0.200em',
                        marginBottom: 5,
                        display: 'block',
                        textTransform: 'uppercase',
                        opacity: 0.24,
                        color: theme.palette.tertiary.contrastText,
                    },
                    '& .title': {
                        display: 'block',
                        marginBottom: 12,
                        fontSize: 16,
                        lineHeight: 2,
                        letterSpacing: '0.008em',
                        fontWeight: '700',
                        fontFamily: theme.headerFont,
                    },
                    '& .excerpt': {
                        opacity: .5,
                        fontSize: 14,
                        lineHeight: '24px',
                        height: 48,
                        overflow: 'hidden',
                        display: 'block',
                        color: theme.palette.tertiary.contrastText,
                    }
                }
            }
        },
        '& .button': {
            [theme.breakpoints.up('md')]: {
                position: 'absolute',
                top: 0,
                right: 0,   
                margin: 0,
                marginLeft: 0,
            }
        },
    },
})