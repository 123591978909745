export default theme => ({ // eslint-disable-line
    para: {
        display: 'block',
        position: 'relative',
        fontSize: 16,
        lineHeight: 1.5,
        marginTop: 0,
        marginBottom: 16,
        fontFamily: theme.bodyFont,
        maxWidth: 1142,
        width: '100%',
        marginLeft: "auto",
        marginRight: "auto",
        padding: '0 12px',
        '&.small': {
            fontSize: 12,
            lineHeight: 1.33,
        },
        '&.lead': {
            fontSize: 22,
            lineHeight: 1.45,
            [theme.breakpoints.up('lg')]: {
                fontSize: 24,
                lineHeight: 1.33
            }
        },
        '& a': {
            textDecoration: 'underline !important',
            color: theme.palette.accent.main,
            '&:hover, &:focus': {
                color: theme.palette.accent.dark,
            }
        },
        // Alignment
        '&[data-align="left"]': { textAlign: "left" },
        '&[data-align="center"]': { textAlign: "center" },
        '&[data-align="right"]': { textAlign: "right" },
        // Background Colours
        '&:not([data-override-background="initial"])': {padding: 12},
        // DropCap
        '&[data-drop-cap="true"]': {
            '&:first-letter': {
                float: 'left',
                fontSize: '6rem',
                lineHeight: 0.65,
                margin: '0.1em 0.1em 0.2em 0'
              }
        }
    },
    paraTheme: {
        ...theme.paraTheme,
    }
})