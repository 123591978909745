export default theme => ({ // eslint-disable-line
    textIntro: {
        backgroundColor: theme.palette.primary.main,
        margin: '0 auto',
        padding: '40px 0',
        [theme.breakpoints.up('md')]: {
            padding: '80px 0',
            '& .MuiTypography-root, & p': {
                maxWidth: '83.333%',
                marginRight: '16.666%',
            },
        },
        '& .MuiTypography-root': {
            marginBottom: 32,
            color: theme.palette.primary.contrastText,
            '& svg': {
                marginRight: 32,
                width: 54,
                height: 54,
                verticalAlign: 'bottom',
                [theme.breakpoints.up('md')]: {
                    width: 72,
                    height: 72
                }
            }
        },
        '& p': {
            color: theme.palette.primary.contrastText,
        },
    },
})