export default theme => ({ // eslint-disable-line
sideCardBlock: {
        borderRadius: theme.border.radius,
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadow,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            '& .gatsby-image-wrapper': {
                flex: '0 0 225px',
                maxWidth: 225,
            }
        },
        '& .con-wrap': {
            padding: 32,
            display: 'flex',
            flexDirection: 'column',
            '& .min-height': {
                minHeight: 150,
                paddingBottom: 1,
            },
            '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
                color: theme.palette.secondary.contrastText,
                padding: 0,
            },
        },
        '& .links': {
            '& > a': {
                margin: 0,
            }
        }
    },
})