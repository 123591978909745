import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { buildBlocks } from "../../../services/builder"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import ReactHtmlParser from "react-html-parser"
import PreviewImage from "../../PreviewImage"

import { CoreHeadingBlock } from "../CoreHeadingBlock"
import { CoreParagraphBlock } from "../CoreParagraphBlock"
import { AcfButtonBlock } from "../AcfButtonBlock"

const useStyles = makeStyles(theme => styles(theme))

export const AcfPeopleGridBlock = props => {
  // console.log(props);

  const data = useStaticQuery(graphql`
    query peopleGridBlock {
      allWpPerson(sort: { order: ASC, fields: menuOrder }) {
        nodes {
          id
          title
          personFields {
            jobTitle
            emailAddress
            shortBio
            photo {
              id
              uri
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 640
                    aspectRatio: 1.25
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    quality: 90
                  )
                }
              }
            }
          }
          departments {
            nodes {
              termTaxonomyId
            }
          }
        }
      }
      wp {
        themeOptions {
          ThemeOptions {
            peopleGrid {
              backgroundColour
              headerColour
              underPhotoLineColour
              textColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.peopleGrid

  if (
    props.innerBlocks[0].attributes.textColor === "" &&
    overrides.headerColour !== "black"
  ) {
    props.innerBlocks[0].attributes.textColor = overrides.headerColour
  }

  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  )
  const classes = useStyles()

  const departmentId = props.block.blockPeopleGrid.department

  return (
    <div
      className={`${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.peopleGrid} `}
      data-override-background={
        overrides.backgroundColour !== "background"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="wrapper">
        {blocks.map(block => {
          return block
        })}
        <div className="listing">
          {data.allWpPerson.nodes.map(person => {
            if (
              departmentId === null ||
              person.departments.nodes.some(
                element =>
                  element.termTaxonomyId === departmentId.termTaxonomyId
              )
            ) {
              return (
                <div className="person" key={person.id}>
                  <PreviewImage
                    image={person.personFields.photo.localFile}
                    fallbackUrl={person.personFields.photo.uri}
                    alt={person.title}
                    fixedPadding="80%"
                  />
                  <div
                    className="split"
                    data-override-background={
                      overrides.underPhotoLineColour !== "accent"
                        ? overrides.backgroundColour
                        : null
                    }
                  />
                  <CoreHeadingBlock
                    attributes={{
                      textAlign: "left",
                      anchor: "",
                      className: "",
                      content: ReactHtmlParser(person.title),
                      level: 4,
                      textColor:
                        overrides.textColour !== "black"
                          ? overrides.textColour
                          : "black",
                      backgroundColor: "",
                      __typename: "WpCoreHeadingBlockAttributes",
                    }}
                    innerBlocks={[]}
                  />
                  <CoreParagraphBlock
                    attributes={{
                      align: "left",
                      className: "job-title",
                      content: ReactHtmlParser(person.personFields.jobTitle),
                      textColor:
                        overrides.textColour !== "black"
                          ? overrides.textColour
                          : "black",
                      backgroundColor: "",
                      fontSize: "small",
                      __typename: "WpCoreParagraphBlockAttributes",
                    }}
                    innerBlocks={[]}
                  />
                  <CoreParagraphBlock
                    attributes={{
                      align: "left",
                      className: "",
                      content: ReactHtmlParser(person.personFields.shortBio),
                      textColor:
                        overrides.textColour !== "black"
                          ? overrides.textColour
                          : "black",
                      backgroundColor: "",
                      __typename: "WpCoreParagraphBlockAttributes",
                    }}
                    innerBlocks={[]}
                  />
                  {person.personFields.emailAddress && (
                    <AcfButtonBlock
                      attributes={{
                        className: "",
                      }}
                      block={{
                        ButtonFields: {
                          buttonLink: {
                            target: "",
                            title: "Email Me",
                            url: "mailto:" + person.personFields.emailAddress,
                          },
                          buttonStyle: "secondary",
                          icon: "email",
                          showIcon: "left",
                        },
                      }}
                      innerBlocks={[]}
                    />
                  )}
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      </div>
    </div>
  )
}

AcfPeopleGridBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfPeopleGridBlockFragment = graphql`
  fragment AcfPeopleGridBlock on WpBlock {
    ... on WpAcfPeopleGridBlock {
      name
      attributes {
        className
      }
      blockPeopleGrid {
        department {
          termTaxonomyId
          id
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfPeopleGridBlockPreview = `
  ... on AcfPeopleGridBlock {
      name
      attributes {
          className
      }
      blockPeopleGrid {
        department {
          termTaxonomyId
          id
        }
      }
  }
`
