import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { buildBlocks } from "../../../services/builder"
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import { AcfButtonBlock } from "../AcfButtonBlock";

import gBubble from "../../../../static/google-reviews-logo.png";

const useStyles = makeStyles(theme => styles(theme))

const Stars = props => {
  // component return number of stars out of five
  if (props.rating <= 5) {
    let numberOfStars = props.rating
    let numberOfFilledStars = Math.floor(numberOfStars)
    let numberOfEmptyStars = 5 - numberOfFilledStars
    return (
      <div className="stars">
        {[...Array(numberOfFilledStars)].map((e, i) => <span className="filled-star" key={"sif-" + i}><StarIcon /></span>)}
        {[...Array(numberOfEmptyStars)].map((e, i) => <span className="empty-star" key={"sie-" + i}><StarIcon /></span>)}
      </div>
    )
  } else {
    return null
  }
}

const OverallReview = props => {
  // Component returns overall review rating includng stars, rating and number of reviews
  return (
    <div className="overall-review">
      <img src={gBubble} alt="google reviews logo" />
      <div className="review-group-1">
        <p>Google Review</p>
        <div className="review-group-2">
          <p>{props.rating}</p>
          <div className="review-group-3">
            <Stars rating={props.rating} />
            <p>{props.reviewCount} reviews</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const IndividualReview = props => {
  // Component returns review including stars, rating, relative date and review

  const overrides = props.overrides;

  return (
    <div
      data-override-background={overrides.reviewBackgroundColour !== 'white' ? overrides.reviewBackgroundColour : null}

    >
      <div className="stars-and-author">
        <Stars rating={props.rating} /><p
          data-override-color={overrides.reviewTextColour !== 'black' ? overrides.reviewTextColour : null}

        >By {props.authorName}</p>
      </div>
      <div>
        <span className="review-relative-time"
          data-override-color={overrides.reviewTextColour !== 'black' ? overrides.reviewTextColour : null}

        >{props.relativeTime}</span>
        <p
          data-override-color={overrides.reviewTextColour !== 'black' ? overrides.reviewTextColour : null}

        >{props.reviewText}</p>
      </div>

    </div>
  )
}

export const AcfGoogleReviewsBlock = props => {


  const data = useStaticQuery(graphql`
 query googleReviewsBlock {
  wp {
    themeOptions {
      ThemeOptions {
        googleReviews {
          backgroundColour
          fieldGroupName
          headerColour
          reviewTaglineColour
          reviewBackgroundColour
          reviewTextColour
          
        }
      }
    }
  }
}
`);


  const overrides = data.wp.themeOptions.ThemeOptions.googleReviews;


  if (props.innerBlocks[0].attributes.textColor === "" && overrides.reviewTagColour !== 'black') {
    props.innerBlocks[0].attributes.textColor = overrides.headerColour;
  }

  if (props.innerBlocks[1].attributes.textColor === "" && overrides.headerColour !== 'black') {
    props.innerBlocks[1].attributes.textColor = overrides.headerColour;
  }
  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  )
  const classes = useStyles()

  const { reviewCount, rating, reviews, placeId } = props.block.blockGoogleReviews.location.ReviewData

  return (
    <div className={`${props.attributes.className ? props.attributes.className : ""
      } ${classes.googleReviews} `}
      data-override-background={overrides.backgroundColour !== 'tertiary' ? overrides.backgroundColour : null}
    >
      <div className="wrapper">
        <div className="left-block" >
          {blocks.map(block => {
            return block
          })}
          <OverallReview reviewCount={reviewCount} rating={rating} />
        </div>
        <div className="reviews"
          data-override-background={overrides.reviewBackgroundColour !== 'background' ? overrides.reviewBackgroundColour : null}

        >
          {reviews.reverse().slice(0, 3).map((review, index) =>
            <div key={"grbir-" + index}>
              <IndividualReview reviewText={review.reviewText} rating={review.rating} relativeTime={review.relativeTime} authorName={review.authorName} overrides={overrides} />
              <hr
                data-override-background={overrides.reviewTextColour !== 'black' ? overrides.reviewTextColour : null}

              ></hr>
            </div>
          )}
          <AcfButtonBlock
            attributes={{
              className: "",
            }}
            block={{
              ButtonFields: {
                buttonLink: {
                  target: "_blank",
                  title: "Read more reviews",
                  url: `https://search.google.com/local/reviews?placeid=${placeId}`,
                },
                buttonStyle: "secondary",
                icon: "empty",
                showIcon: "none",
              }
            }}
            innerBlocks={[]}
          />
        </div>
      </div>
    </div>
  )
}

AcfGoogleReviewsBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfGoogleReviewsBlockFragment = graphql`
  fragment AcfGoogleReviewsBlock on WpBlock {
    ... on WpAcfGoogleReviewsBlock {
      name
      attributes {
        className
      }
      blockGoogleReviews {
        location {
              ... on WpReview {
                id
                ReviewData {
                  reviewCount
                  reviews {
                    rating
                    authorName
                    relativeTime
                    reviewText
                  }
                  name
                  rating
                  placeId
                }
              }
          }
    }
  }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfGoogleReviewsBlockPreview = `
  ... on WpAcfGoogleReviewsBlock {
      name
      attributes {
          className
      }
      blockGoogleReviews {
        location {
              ... on WpReview {
                id
                ReviewData {
                  reviewCount
                  reviews {
                    rating
                    authorName
                    relativeTime
                    reviewText
                  }
                  name
                  rating
                  placeId
                }
              }
      }
  }
}

`
