import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks, mergeBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const BblMediaItemBlock = (props) => {

    // console.log(props);

    const data = useStaticQuery(graphql`
        query MediaItemBlock {
        wp {
            themeOptions {
                ThemeOptions {
                    mediaItem {
                        backgroundColour
                        headerColour
                        textColour
                    }
                }
            }
        }
        }
    `);

    let overrides = data.wp.themeOptions.ThemeOptions.mediaItem;

    if(props.innerBlocks[1].attributes.textColor === "" && overrides.headerColour !== 'black') {
      props.innerBlocks[1].attributes.textColor = overrides.headerColour;
    }

    if(props.innerBlocks[2].attributes.textColor === "" && overrides.textColour !== 'black') {
      props.innerBlocks[2].attributes.textColor = overrides.textColour;
    }

    const mergedBlocks = mergeBlocks(props.innerBlocks, props.block.innerImage);
    const blocks = buildBlocks(mergedBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`media-item ${props.attributes.className ? props.attributes.className : ''} ${classes.mediaItemBlock} `}
                data-override-background={ overrides.backgroundColour !== 'background' ? overrides.backgroundColour : null } 
    >
        {blocks[0]}
        <div className="con-wrap">
            {blocks[1]}
            {blocks[2]}
        </div>
    </div>
};

export const BblMediaItemBlockFragment = graphql`
  fragment BblMediaItemBlock on WpBlock {
    ... on WpBblMediaItemBlock {
        name
        attributes {
            className
        }
        innerImage: innerBlocks {
            ... on WpCoreImageBlock {
                name
                originalContent 
                attributes {
                    __typename
                    ... on WpCoreImageBlockAttributes {
                        url
                        alt
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                  width: 100,
                                  aspectRatio: 1,
                                  placeholder: BLURRED,
                                  formats: [AUTO, WEBP, AVIF],
                                  transformOptions: {fit: COVER, cropFocus: CENTER},
                                  quality: 90
                                )
                            }
                        }
                    }
                }
            }
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblMediaItemBlockPreview = `
    ... on BblMediaItemBlock {
        name
        attributes {
            className
        }
        inner: innerBlocks {
            ... on WpCoreImageBlock {
                name
                originalContent {
                    attributes {
                        __typename
                        ... on WpCoreImageBlockAttributes {
                            url
                        }
                    }
                }
            }
        }
    }
`;