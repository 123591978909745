
export default theme => ({ // eslint-disable-line
    calloutCta: {
        padding: '50px 0',
        backgroundColor: theme.palette.tertiary.main,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '110px 0 84px',
        },
        '& .wrapper': {
            alignItems: 'baseline',
            [theme.breakpoints.up('md')]: {
                justifyContent: 'space-between',
            },
            '& .MuiTypography-root': {
                color: theme.palette.tertiary.contrastText,
                textAlign: 'center',
                marginBottom: 24,
                flex: '0 0 100%',
                [theme.breakpoints.up('md')]: {
                    textAlign: 'left',
                    flex: '0 0 75%',
                    marginLeft: 0,
                    marginRight: 0,
                }
            },
            '& > a': {
                margin: '0 auto',
                [theme.breakpoints.up('md')]: {
                    margin: '0 12px',
                }
            }
        }
    }
})