import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import Slider from "react-slick"
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

export default class GallerySlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
      slider: [],
    }
  }

  componentDidMount() {
    this.setState({
      slider: this.props.images,
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  render() {
    const { overrides } = this.props;

    return (
      <div className="slider-main">
        <Slider
          ref={slider => (this.slider1 = slider)}
          asNavFor={this.state.nav2}
          arrows={true}
          nextArrow={<ArrowRightIcon 
            data-override-background={ overrides.arrowColour !== 'accent' ? overrides.arrowColour : null }
            data-override-color={ overrides.arrowFill !== 'on-accent' ? overrides.arrowFill : null }
            />}
          prevArrow={<ArrowLeftIcon 
            data-override-background={ overrides.arrowColour !== 'accent' ? overrides.arrowColour : null }
            data-override-color={ overrides.arrowFill !== 'on-accent' ? overrides.arrowFill : null }
          />}
        >
          {this.state.slider.map(image => {
            return (
              <GatsbyImage
                key={image.id}
                image={image.localFile.childImageSharp.gatsbyImageData}
              />
            )
          })}
        </Slider>

        <Slider
          ref={slider => (this.slider2 = slider)}
          asNavFor={this.state.nav1}
          dots={true}
          arrows={false}
          infinite={false}
          slidesToShow={4}
          swipeToSlide={true}
          focusOnSelect={true}
          className={`slider-nav ${ overrides.dotsColour }-dots`}
          responsive={[
            {
              breakpoint: 969,
              settings: {
                slidesToShow: 3,
              },
            },
          ]}
        >
          {this.state.slider.map(image => {
            return (
              <GatsbyImage
                key={'nav-' + image.id}
                image={image.localFile.childImageSharp.gatsbyImageData}
              />
            )
          })}
        </Slider>
      </div>
    )
  }
}
