const styles = theme => ({
  gravityForms: {
    margin: "0 auto",
    width: "calc(100% - 24px)",
    maxWidth: 1118,
    "& .gfield": {
      marginTop: 8,
      "& .ginput_container": {
        position: "relative",
        fontSize: 0,
        fontFamily: theme.bodyFont,
        overflow: "hidden",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        "& > label": {
          position: "absolute",
          top: 6,
          color: theme.common.black,
          transition: "all .3s ease-in-out",
          fontSize: 12,
          lineHeight: 1.2,
          left: 16,
          fontFamily: theme.bodyFont,
          "& .gfield_required": {
            color: "red",
            marginLeft: 5,
          },
        },
        '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
          fontSize: 14,
          color: theme.common.black,
          lineHeight: 1.5,
          width: "100%",
          border: "none",
          padding: "25px 16px 12px",
          outline: "none",
          transition: "all .3s ease-in-out",
          appearance: "none",
          margin: 0,
          fontFamily: theme.bodyFont,
          "&:focus": {
            outline: "none",
            // backgroundColor: "rgba(0,0,0,0.2) !important",
            "& ~ .after": {
              backgroundPosition: "0 0 !important",
            },
          },
        },
        "& textarea": {
          height: 175,
        },
        "& select": {
          backgroundImage:
            "linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)",
          backgroundPosition: "calc(100% - 23px) 32px, calc(100% - 15px) 32px",
          backgroundSize: "9px 6px, 9px 6px",
          backgroundRepeat: "no-repeat",
          "& option": {
            backgroundColor: "white !important",
            color: theme.common.black + " !important",
          },
        },
        "& .after": {
          width: "100%",
          height: 2,
          background:
            "linear-gradient(to right, " +
            theme.palette.primary.main +
            " 50%, rgba(0,0,0,0.3) 50%)",
          backgroundSize: "200% 100% !important",
          backgroundPosition: "100% 0 !important",
          transition: "all .6s ease",
          margin: 0,
        },
      },
      "&.gravityform__field__checkbox, &.gravityform__field__radio": {
        "& label": {
          fontSize: 12,
          lineHeight: 1.2,
          marginLeft: 16,
          fontFamily: theme.bodyFont,
        },
        "& .gfield_checkbox, & .gfield_radio": {
          '& [type="checkbox"], & [type="radio"]': {
            position: "absolute",
            left: 0,
            opacity: 0.01,
            "& + label": {
              position: "relative",
              paddingLeft: 26,
              fontSize: 14,
              lineHeight: 1.5,
              cursor: "pointer",
              marginRight: 12,
              marginBottom: 12,
              display: "inline-block",
              "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                width: 18,
                height: 18,
                borderRadius: 2,
                border: "2px solid rgba(0,0,0,0.38)",
                background: "white",
                transition: "all .3s ease-in-out",
              },
              "&::after": {
                content: '""',
                position: "absolute",
                top: 4,
                left: 3,
                width: 12,
                height: 7,
                transition: "all .3s ease-in-out",
                borderLeft: "2px solid transparent",
                borderBottom: "2px solid transparent",
                transform: "rotate(-45deg)",
              },
            },
            "&:checked": {
              "& + label": {
                position: "relative",
                paddingLeft: 26,
                fontSize: 14,
                lineHeight: 1.5,
                cursor: "pointer",
                "&::before": {
                  backgroundColor: theme.palette.accent.main + " !important",
                  borderColor: theme.palette.accent.main + " !important",
                },
                "&::after": {
                  borderLeft: "2px solid white",
                  borderBottom: "2px solid white",
                },
              },
            },
          },
          '& input[type="radio"]': {
            "& + label": {
              "&::before": {
                borderRadius: 9,
                backgroundColor: "transparent !important",
              },
              "&::after": {
                top: 4,
                left: 4,
                width: 10,
                height: 10,
                borderRadius: 5,
                border: "none !important",
              },
            },
            "&:checked": {
              "& + label": {
                "&::before": {
                  backgroundColor: "transparent !important",
                  borderColor: theme.palette.accent.main,
                },
                "&::after": {
                  backgroundColor: theme.palette.accent.main,
                },
              },
            },
          },
        },
      },
      "& .gravityforms__description": {
        fontSize: 12,
        padding: "12px 16px",
        color: theme.common.black,
        fontFamily: theme.bodyFont,
      },
      "& .gravityform__error_message": {
        fontSize: 12,
        padding: "12px 16px",
        color: "#B00020",
        fontFamily: theme.bodyFont,
      },
      "&.gravityform__field--error": {
        "& .ginput_container": {
          "& .after": {
            background: "#B00020",
          },
        },
      },
    },
    "& .gform_footer": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      "& p": {
        margin: 0,
        fontSize: 12,
        fontFaily: theme.bodyFont,
        textTransform: "uppercase",
        color: "red",
        letterSpacing: "0.2em",
      },
      "& button": {
        fontSize: 18,
        lineHeight: 1.5,
        fontFamily: theme.subHeaderFont,
        fontWeight: "bold",
        display: "inline-block",
        margin: "16px 0",
        maxWidth: 1118,
        padding: "8px 24px",
        position: "relative",
        color: theme.palette.accent.contrastText,
        backgroundColor: theme.palette.accent.main,
        borderWidth: theme.border.thickness,
        borderRadius: theme.border.radius,
        borderColor: theme.palette.accent.main,
        border: "solid " + theme.palette.accent.main,
        transition: "background-color .3s ease-in-out, color .3s ease-in-out",
        boxShadow: theme.shadow,
        "& span": {
          zIndex: 1,
          position: "relative",
        },
        "& span.material-icons": {
          width: 14,
          height: 14,
          fontSize: 14,
        },
        "&:hover, &:focus": {
          outline: "none",
          backgroundColor: theme.palette.accent.contrastText,
          color: theme.palette.accent.main,
        },
      },
    },
    '&[data-form-shape="none"]': {
      "& .gfield": {
        "& .ginput_container": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      },
    },
    '&[data-form-shape="rounded"]': {
      "& .gfield": {
        "& .ginput_container": {
          borderRadius: 32,
          "& > label": {
            left: 48,
          },
          '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
            padding: "25px 48px 12px",
          },
          "& .after": {
            width: "calc(100% - 64px)",
            margin: "-2px 32px 0",
            zIndex: 2,
            position: "relative",
          },
        },
        "& .gravityforms__description": {
          padding: "12px 48px",
        },
        "& .gravityform__error_message": {
          padding: "12px 48px",
        },
        "&.gravityform__field__checkbox, &.gravityform__field__radio": {
          "& label": {
            marginLeft: 48,
          },
        },
      },
      "& .gform_footer": {
        padding: "0 32px",
      },
    },

    '&[data-form-style="outline"]': {
      "& .gfield": {
        "& .ginput_container": {
          overflow: "visible",
          "& > label": {
            top: -6,
            // backgroundColor: theme.palette.background.default,
            zIndex: 2,
            padding: "0 8px",
            marginLeft: -8,
          },
          '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
            border: "1px solid rgba(0,0,0,0.12)",
            borderRadius: 4,
            paddingTop: 12,
            // backgroundColor: "transparent !important",
            "&:focus": {
              // borderColor: theme.palette.primary.main,
            },
          },
          "& .after": {
            display: "none",
          },
        },
        "&.gravityform__field--error": {
          "& .ginput_container": {
            '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
              borderColor: "#B00020",
            },
          },
        },
      },
      '&[data-form-shape="none"]': {
        "& .gfield": {
          "& .ginput_container": {
            '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
              borderRadius: 0,
            },
          },
        },
      },
    },
  },
})
export default styles
