const styles = theme => ({
  latestPostsSlider: {
    padding: "32px 0",
    [theme.breakpoints.up("md")]: {
      padding: "50px 0",
    },
    [theme.breakpoints.up("md")]: {
      padding: "100px 0",
    },
    "& .card": {
      borderRadius: theme.border.radius,
      overflow: "hidden",
      backgroundColor: theme.palette.secondary.main,
      boxShadow: theme.shadow,
      "& .con-wrap": {
        padding: 32,
        "& .min-height": {
          minHeight: 150,
          height: 265,
          paddingBottom: 1,
          "& .MuiTypography-h4": {
            display: "inline-block",
            color: theme.palette.secondary.contrastText,
          },
          "& .post-excerpt": {
            display: "inline-block",
            color: theme.palette.secondary.contrastText,
            maxHeight: 180,
            overflow: "hidden",
          },
          "& .post-date": {
            color: theme.palette.secondary.contrastText,
            letterSpacing: ".2em",
            textTransform: "uppercase",
            lineHeight: "16px",
            "& .MuiSvgIcon-root": {
              verticalAlign: "bottom",
              marginRight: 8,
              width: 16,
              height: 16,
            },
          },
        },
        "& h1, & h2, & h3, & h4, & h5, & h6, & p": {
          padding: 0,
        },
      },
      "& .links": {
        display: "flex",

        "& > a": {
          margin: 0,
        },
      },
    },
    "& .slider-main": {
      position: "relative",
      paddingBottom: 24,

      "& .slick-arrow": {
        color: theme.palette.accent.contrastText,
        fill: "currentColor",
        backgroundColor: theme.palette.accent.main,
        width: 48,
        height: 48,
        margin: "0 0",
        zIndex: 2,
        top: "100%",
        transform: "none",
        borderRadius: theme.border.radius,
        overflow: "hidden",
        boxShadow: theme.shadow,
        [theme.breakpoints.up("sm")]: {
          width: 64,
          height: 64,
        },
        "&.slick-prev": {
          left: 12,
        },
        "&.slick-next": {
          right: 12,
        },
        "&:hover": {
          backgroundColor: theme.palette.accent.main,
        },
      },
    },
    "& img:first-of-type": {
      paddingBottom: 12,
    },
    "& .slide-wrap": {
      display: "block",
      flex: "0 0 100%",
      maxWidth: "100%",
      marginBottom: "60px",
    },
    "& .slick-slider": {
      margin: "0 auto",
      "& .slick-slide": {
        padding: 12,
      },
      "&.primary-dots .slick-dots li button": {
        backgroundColor: theme.palette.primary.main,
      },
      "&.on-primary-dots .slick-dots li button": {
        backgroundColor: theme.palette.primary.contrastText,
      },
      "&.primary-highlight-dots .slick-dots li button": {
        backgroundColor: theme.palette.primary.highlight,
      },
      "&.secondary-dots .slick-dots li button": {
        backgroundColor: theme.palette.secondary.main,
      },
      "&.on-secondary-dots .slick-dots li button": {
        backgroundColor: theme.palette.secondary.contrastText,
      },
      "&.secondary-highlight-dots .slick-dots li button": {
        backgroundColor: theme.palette.secondary.highlight,
      },
      "&.tertiary-dots .slick-dots li button": {
        backgroundColor: theme.palette.tertiary.main,
      },
      "&.on-tertiary-dots .slick-dots li button": {
        backgroundColor: theme.palette.tertiary.contrastText,
      },
      "&.tertiary-highlight-dots .slick-dots li button": {
        backgroundColor: theme.palette.tertiary.highlight,
      },
      "&.accent-dots .slick-dots li button": {
        backgroundColor: theme.palette.accent.main,
      },
      "&.on-accent-dots .slick-dots li button": {
        backgroundColor: theme.palette.accent.contrastText,
      },
      "&.accent-highlight-dots .slick-dots li button": {
        backgroundColor: theme.palette.accent.highlight,
      },
    },
    "& .wrapper": {
      display: "flex",
      justifyContent: "center",
      "& > .MuiTypography-root": {
        marginBottom: 32,
        [theme.breakpoints.up("md")]: {
          marginBottom: 50,
        },
      },
    },

    "& .slider-nav": {
      width: "calc(100% + 24px)",
      margin: "0 -12px",
      paddingTop: 12,
      "& .slick-slide": {
        padding: 12,
      },
    },
    "& .slick-dots": {
      bottom: "-50px",

      "& li": {
        margin: 8,
        width: 16,
        height: 16,
        overflow: "hidden",
        "& button": {
          margin: 0,
          padding: 0,
          backgroundColor: theme.palette.accent.main,
          borderRadius: 8,
          width: 16,
          height: 16,
          fontSize: 0,
          opacity: 0.25,
          "&::before": {
            display: "none",
            color: theme.palette.accent.main,
          },
        },
        "&.slick-active button": {
          opacity: 1,
        },
      },
    },
  },
})
export default styles
