export default theme => ({ // eslint-disable-line
    sideCardGrid: {
        padding: '24px 0 0',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: theme.palette.tertiary.main,
        [theme.breakpoints.up('md')]: {
            padding: '48px 0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '90px 0',
        },
        '& .wrapper': {
            '& > .MuiTypography-root': {
                marginBottom: 32,
                color: theme.palette.tertiary.contrastText,
            },
            '& .cards': {
                display: 'flex',
                flex: '0 0 100%',
                flexWrap: 'wrap',
                flexDirection: 'row',
                '& > div': {
                    flex: '0 0 calc(100% - 24px)',
                    margin: '0 12px 24px',
                    [theme.breakpoints.up('md')]: {
                        flex: '0 1 calc(50% - 24px)',
                        margin: '12px 12px',
                    },
                }
            }
        }
    },
})