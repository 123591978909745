import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { buildBlocks } from "../../../services/builder"
import LatestPostsSlider from "./slider"

import { makeStyles } from "@material-ui/styles"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfLatestPostsSliderBlock = props => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
  query LatestPostsSliderBlock {
    
    allWpPost(sort: {fields: date, order: DESC}) {
      nodes {
        id
        slug
        title
        link
        date(formatString: "DD MMMM YYYY")
        excerpt
        categories {
          nodes {
            id
            termTaxonomyId
          }
        }
        featuredImage {
          node {
            mediaItemUrl
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 960
                  aspectRatio: 1.35
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF],
                  quality: 90
                )
              }
            }
          }
        }
      }
    }
    wp {
        themeOptions {
            ThemeOptions {
                latestPostsSlider {
                    backgroundColour
                    headerColour
                    arrowColour
                    arrowFill
                    dotsColour
                    cardBackgroundColour
                    cardHeaderColour
                    cardDateColour
                    cardExcerptColour
                }
            }
        }
    }
    
    }
    `);

  const overrides = data.wp.themeOptions.ThemeOptions.latestPostsSlider;

  // loop through all block and set heading color 

  if(props.innerBlocks[0].attributes.textColor === "" && overrides.headerColour !== 'black') {
    props.innerBlocks[0].attributes.textColor = overrides.headerColour;
  }

  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  )

  const cards = data.allWpPost.nodes;

  return (
    <div className={`${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.latestPostsSlider} `}
       data-override-background={ overrides.backgroundColour !== 'background' ? overrides.backgroundColour : null }  
    >
      <div className="wrapper">
        {blocks.map(block => {
          return block
        })}
        <div className="slide-wrap">
          <LatestPostsSlider cards={cards} overrides={overrides} />
        </div>
      </div>
    </div>
  )
}

 

AcfLatestPostsSliderBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfLatestPostsSliderBlockFragment = graphql`
  fragment AcfLatestPostsSliderBlock on WpBlock {
    ... on WpAcfLatestPostsSliderBlock {
      name
      attributes {
        className
      }
      LatestPostsSlider {
        fieldGroupName
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfLatestPostsSliderBlockPreview = `
  ... on WpAcfLatestPostsSliderBlock {
      name
      attributes {
          className
      }
      LatestPostsSlider {
        fieldGroupName
      }
  }
`
