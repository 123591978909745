import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import {buildBlocks} from "../../../services/builder";

export const AcfBackgroundContainerBlock = (props) => {
  const blocks = buildBlocks(props.innerBlocks, props.attributes, props.pageContext);

  // console.log(props);

  return <div data-override-background={props.block.blockBackgroundContent.backgroundColour} style={{padding:1}}>
    {blocks.map((block) => {
      return block;
    })}
  </div>
};

AcfBackgroundContainerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const AcfBackgroundContainerBlockFragment = graphql`
  fragment AcfBackgroundContainerBlock on WpBlock {
    ... on WpAcfBackgroundContainerBlock {
      name
      attributes {
          className
      }
      blockBackgroundContent {
        backgroundColour
      }
    } 
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const AcfBackgroundContainerBlockPreview = `
  ... on AcfBackgroundContainerBlock {
      name
      attributes {
          className
      }
      blockBackgroundContent {
        backgroundColour
      }
  }
`;