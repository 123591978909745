export default theme => ({ // eslint-disable-line
    socialShare: {
        position: 'relative',
        // width: 136,
        borderRadius: theme.border.radius,
        backgroundColor: theme.palette.background.default,
        transition: 'box-shadow .3s ease-in-out',
        zIndex: 10,
        '&[open]': {
            boxShadow: theme.shadow,
            '& .buttons': {
                height: 'auto',
                opacity: 1,
            }
        },
        '& button': {
            color: theme.palette.accent.main,
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            fontSize: 18,
            textDecoration: 'underline',
            lineHeight: '23px',
            fontWeight: 'bold',
            fontFamily: theme.bodyFont,
            padding: '12px 24px',
            textAlign: 'center',
            letterSpacing: '0.008em',
            whiteSpace: 'no-wrap',
            '& svg': {
                display: 'inline-block',
                verticalAlign: 'top',
                marginLeft: 5,
                width: 16,
                height: 16,
                marginTop: 2,
                [theme.breakpoints.up('md')]: {
                    marginTop: 4,
                }
            },
        },
        '& .buttons': {
            padding: '0 12px',
            margin: '12px 0',
            opacity: 0,
            height: 0,
            overflow: 'hidden',
            transition: 'opacity .3s ease-in-out',
            '& a': {
                display: 'block',
                margin: '0 0 5px',
                fontSize: 14,
                textTransform: 'uppercase',
                fontFamily: theme.bodyFont,
                fontWeight: 'bold',
                color: 'white',
                textAlign: 'center',
                width: '100%',
                borderRadius: theme.border.radius,
                padding: '12px 0',
                textDecoration: 'none',
                transition: 'opacity .3s ease-in-out',
                '&:hover, &:focus': {
                    opacity: 0.8,
                },
                '& svg': {
                    display: 'inline-block',
                    verticalAlign: 'sub',
                    marginRight: 2,
                    width: 16,
                    height: 16,
                },
                '&.email': { backgroundColor: theme.palette.accent.main},
                '&.facebook': { backgroundColor: "#385C8E"},
                '&.whatsapp': { backgroundColor: "#30BA45"},
                '&.twitter': { backgroundColor: "#03A9F4"},
            }
        }
    },
});