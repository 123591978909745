export default theme => ({ // eslint-disable-line
    introGallery: {
        backgroundColor: theme.palette.background.main,
        padding: '50px 0',
        [theme.breakpoints.up('md')]: {
            padding: '100px 0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '130px 0',
        },
        '& .wrapper': {
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                '& .gallery, & .con-wrap': {
                    flex: '0 0 50%',
                    maxWidth: '50%',
                    margin: 0,
                },
            },
            '& .gallery': {
                marginTop: -12,
                marginBottom: 24,
                display: 'flex',
                padding: 0,
                flexWrap: 'wrap',
                flexDirection: 'row',
            },
            '& .con-wrap': {
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 41.666%',
                    maxWidth: '41.666%',
                },
                '& > a': {
                    margin: 0,
                    marginLeft: 12,
                },
                '& .MuiTypography-root, & p': {
                    marginBottom: 32,
                }
            }
        }
    },
})