export default theme => ({ // eslint-disable-line
    facebookPagePlugin: {
        padding: '50px 0',
        backgroundColor: theme.palette.tertiary.main,
        '& .wrapper': {
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .blocks': {
                flex: '0 0 100%',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 50%',
                },
                [theme.breakpoints.up('lg')]: {
                    flex: '0 0 33.333%',
                    '& .MuiTypography-root': {
                        marginBottom: 24,
                    },
                    '& p': {
                        marginBottom: 36,
                    }
                },
                '& .button': {
                    marginLeft: 12,
                }
            },
            "& .facebook-frame": {
                display: 'flex',
                justifyContent: 'space-around',
                margin: "12px auto",
                flex: '0 0 100%',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 50%',
                    margin: 0,
                    justifyContent: 'flex-end',
                }
            },
        }
    }
})