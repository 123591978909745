import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks, mergeBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const BblPageTitleBlock = (props) => {

    // console.log(props.pageContext);

    const data = useStaticQuery(graphql`
        query pageTitleOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        pageTitle {
                            backgroundColour
                            headerColour
                            blurbColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.pageTitle;

    if(overrides.headerColour !== 'on-primary') {
        for (let index = 0; index < props.innerBlocks.length; index++) {
            if(props.innerBlocks[index].name === "core/heading") {
                if(props.innerBlocks[index].attributes.textColor === "") {
                    props.innerBlocks[index].attributes.textColor = overrides.headerColour;
                }
            }      
        }
    }

    if(overrides.textColour !== 'on-primary') {
        for (let index = 0; index < props.innerBlocks.length; index++) {
            if(props.innerBlocks[index].name === "core/paragraph") {
                if(props.innerBlocks[index].attributes.textColor === "") {
                    props.innerBlocks[index].attributes.textColor = overrides.textColour;
                }
            }      
        }
    }

    if(props.pageContext.catName) {
        for (let index = 0; index < props.innerBlocks.length; index++) {
            if(props.innerBlocks[index].name === "core/heading") {
                props.innerBlocks[index].attributes.content = props.pageContext.catName;
            }      
        }
    }

    if(props.pageContext.brand) {
        for (let index = 0; index < props.innerBlocks.length; index++) {
            if(props.innerBlocks[index].name === "core/heading") {
                props.innerBlocks[index].attributes.content = props.pageContext.brand.name;
            }      
        }
    }

    if(props.pageContext.job) {
        for (let index = 0; index < props.innerBlocks.length; index++) {
            if(props.innerBlocks[index].name === "core/heading") {
                props.innerBlocks[index].attributes.content = props.pageContext.job.title;
            }      
        }
    }

    const mergedBlocks = mergeBlocks(props.innerBlocks, props.block.innerImage);
    const blocks = buildBlocks(mergedBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.pageTitle} `}
                data-override-background={overrides.backgroundColour !== 'background' ? overrides.backgroundColour : null}
    >
        <div className="wrapper">
            {blocks.map((block) => {
                return block;
            })}
        </div>
    </div>
};

export const BblPageTitleBlockFragment = graphql`
  fragment BblPageTitleBlock on WpBlock {
    ... on WpBblPageTitleBlock {
        name
        attributes {
            className
        }
        innerImage: innerBlocks {
            ... on WpCoreImageBlock {
                name
                originalContent 
                attributes {
                    __typename
                    ... on WpCoreImageBlockAttributes {
                        url
                        alt
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                  width: 1140,
                                  aspectRatio: 2,
                                  placeholder: BLURRED,
                                  formats: [AUTO, WEBP, AVIF],
                                  transformOptions: {fit: COVER, cropFocus: CENTER},
                                  quality: 90
                                )
                            }
                        }
                    }
                }
            }
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblPageTitleBlockPreview = `
    ... on BblPageTitleBlock {
        name
        attributes {
            className
        }
    }
`;