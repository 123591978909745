import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { makeStyles } from "@material-ui/styles"

import styles from "./styles"
import PreviewImage from "../../PreviewImage"

const useStyles = makeStyles(theme => styles(theme))

export const AcfBrewGalleryBlock = props => {

    const classes = useStyles()

    const numberOfColumns = props.block.blockBrewGallery.numberOfColumns;

    let images = props.block.blockBrewGallery.brewGallery;

    if(images === null) {
        return null;
    }

    return (
        <div className={"gallery " + classes.brewGallery}>
            <figure className={`wp-block-gallery columns-${numberOfColumns}`}>
                <ul className="blocks-gallery-grid">{
                    images.map((image, index) =>
                        <li className="blocks-gallery-item" key={"bgi-" + index}>
                            <PreviewImage alt={image.id} className="blocks-gallery-item" fallbackUrl image={image.localFile} />
                        </li>
                    )
                }
                </ul>
            </figure>
        </div>
    )
}

AcfBrewGalleryBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
}

export const AcfBrewGalleryBlockFragment = graphql`
fragment AcfBrewGalleryBlock on WpBlock {
    ...on WpAcfBrewGalleryBlock {
        name
        attributes {
            className
        }
        blockBrewGallery {
            brewGallery {
                id
                uri
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            width: 920,
                            placeholder: BLURRED,
                            formats: [AUTO, WEBP, AVIF],
                            transformOptions: {fit: COVER, cropFocus: CENTER},
                            aspectRatio: 1,
                            quality: 90
                        )
                    }
                }
            }
        numberOfColumns
        }
    }
}`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfBrewGalleryBlockPreview = `
    ... on AcfBrewGalleryBlock {
        name
        attributes {
            className
        }
        blockBrewGallery {
            brewGallery {
                id
                uri
            }           
        }
        numberOfColumns
    }
}`
