import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks, mergeBlocks} from "../../../services/builder";
import styles from "./styles";

import SocialShare  from '../../SocialShare';
import GoBack  from '../../GoBack';
import TodayIcon from '@material-ui/icons/Today';


const useStyles = makeStyles(theme => styles(theme));

export const BblSplitPostTitleBlock = (props) => {

    const data = useStaticQuery(graphql`
        query splitPostTitleQuery {
            wp {
                themeOptions {
                    ThemeOptions {
                        splitPostTitle {
                            backgroundColour
                            titleBackgroundColour
                            headerColour
                            paragraphColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.splitPostTitle;

    if(props.innerBlocks[1].attributes.textColor === "" && overrides.headerColour !== 'black') {
        props.innerBlocks[1].attributes.textColor = overrides.headerColour;
    }

    if(props.innerBlocks[2].attributes.textColor === "" && overrides.paragraphColour !== 'black') {
        props.innerBlocks[2].attributes.textColor = overrides.paragraphColour;
    }

    const mergedBlocks = mergeBlocks(props.innerBlocks, props.block.innerImage);
    const blocks = buildBlocks(mergedBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    // console.log(props);
    
    return <div className={`inner ${props.attributes.className ? props.attributes.className : ''} ${classes.splitPostTitle} `}
                data-override-background={overrides.backgroundColour !== 'primary' ? overrides.backgroundColour : null}
    >
        <div className="wrapper">

            <div className="image-wrap">
                {blocks[0]}
            </div>
            <div className="con-wrap"
                data-override-background={overrides.titleBackgroundColour !== 'background' ? overrides.titleBackgroundColour : null}
            >
                <p className="date"><TodayIcon /> {props.pageContext.date}</p>
                <p className="category">{props.pageContext.cats?.nodes[0].name}</p>
                {blocks[1]}
                {blocks[2]}
            </div>
        </div>
        <div className="extras wrapper">
            <div className="go-back">
                <GoBack fallback='/' />
            </div>
            <div className="share">
                <SocialShare
                    path={props.pageContext.pagePath}
                    title={props.pageContext.pageTitle}
                />
            </div>
        </div>
    </div>
};

export const BblSplitPostTitleBlockFragment = graphql`
  fragment BblSplitPostTitleBlock on WpBlock {
    ... on WpBblSplitPostTitleBlock {
        name
        attributes {
            ... on WpBblSplitPostTitleBlockAttributes {
                className
            }
        }
        innerImage: innerBlocks {
            ... on WpCoreImageBlock {
                name
                originalContent
                attributes {
                  __typename
                  ... on WpCoreImageBlockAttributes {
                    url     
                    alt
                    localFile {
                      publicURL
                      childImageSharp {
                          gatsbyImageData(
                            width: 992,
                            aspectRatio: 1.25,
                            placeholder: BLURRED,
                            formats: [AUTO, WEBP, AVIF],
                            transformOptions: {fit: COVER, cropFocus: CENTER},
                            quality: 90
                          )
                      }
                    }
                  }
                }
            }            
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblSplitPostTitleBlockPreview = `
    ... on BblSplitPostTitleBlock {
        name
        attributes {
            ... on BblSplitPostTitleBlockAttributes {
                className
            }
        }
    }
`;