import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const BblFormIntroBlock = props => {
  const data = useStaticQuery(graphql`
    query formIntroOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            formIntro {
              backgroundColour
              headerColour
              paragraphColour
              formBackgroundColour
            }
            forms {
              fieldColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.formIntro

  if (
    props.innerBlocks[0].attributes.textColor === "" &&
    overrides.headerColour !== "black"
  ) {
    props.innerBlocks[0].attributes.textColor = overrides.headerColour
  }

  if (
    props.innerBlocks[1].attributes.textColor === "" &&
    overrides.paragraphColour !== "black"
  ) {
    props.innerBlocks[1].attributes.textColor = overrides.paragraphColour
  }

  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()

  return (
    <div
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.formIntro} `}
      data-override-background={
        overrides.backgroundColour !== "background"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="wrapper">
        {blocks[0]}
        {blocks[1]}
        <div
          className="form-wrap"
          data-override-background={
            overrides.formBackgroundColour !== "primary"
              ? overrides.formBackgroundColour
              : null
          }
          data-override-form-field-color={
            data.wp.themeOptions.ThemeOptions.forms.fieldColour !== "background"
              ? data.wp.themeOptions.ThemeOptions.forms.fieldColour
              : "background"
          }
        >
          {blocks[2]}
        </div>
      </div>
    </div>
  )
}

export const BblFormIntroBlockFragment = graphql`
  fragment BblFormIntroBlock on WpBlock {
    ... on WpBblFormIntroBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP Prefixes
export const BblFormIntroBlockPreview = `
    ... on BblFormIntroBlock {
        name
        attributes {
            className
        }
    }
`
