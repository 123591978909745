import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const BblTwoColumnWrapperInnerBlock = (props) => {

    // console.log(props);

    const data = useStaticQuery(graphql`
        query twoColumnWrapperInnerOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        twoColumnWrapper {
                            headerColour
                            textColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.twoColumnWrapper;

    if(overrides.headerColour !== 'black') {
        for (let index = 0; index < props.innerBlocks.length; index++) {
            if(props.innerBlocks[index].name === "core/heading") {
                if(props.innerBlocks[index].attributes.textColor === "") {
                    props.innerBlocks[index].attributes.textColor = overrides.headerColour;
                }
            }      
        }
    }

    if(overrides.textColour !== 'black') {
        for (let index = 0; index < props.innerBlocks.length; index++) {
            if(props.innerBlocks[index].name === "core/paragraph" || props.innerBlocks[index].name === "core/list") {
                if(props.innerBlocks[index].attributes.textColor === "") {
                    props.innerBlocks[index].attributes.textColor = overrides.textColour;
                }
            }      
        }
    }

    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.twoColumnWrapperInner} `}
        data-is-thin={props.attributes.isThin}
    >
        {blocks.map((block) => {
            return block;
        })}
    </div>
};

export const BblTwoColumnWrapperInnerBlockFragment = graphql`
  fragment BblTwoColumnWrapperInnerBlock on WpBlock {
    ... on WpBblTwoColumnWrapperInnerBlock {
        name
        attributes {
            className
            isThin
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblTwoColumnWrapperInnerBlockPreview = `
    ... on BblTwoColumnWrapperInnerBlock {
        name
        attributes {
            className
            isThin
        }
    }
`;