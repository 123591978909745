import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks, mergeBlocks } from "../../../services/builder"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const BblSideCardBlock = props => {
  const data = useStaticQuery(graphql`
    query sideCardOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            sideCards {
              backgroundColour
              headerColour
              paragraphColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.sideCards

  if (
    props.innerBlocks[1].attributes.textColor === "" &&
    overrides.headerColour !== "on-secondary"
  ) {
    props.innerBlocks[1].attributes.textColor = overrides.headerColour
  }

  if (
    props.innerBlocks[2].attributes.textColor === "" &&
    overrides.paragraphColour !== "on-secondary"
  ) {
    props.innerBlocks[2].attributes.textColor = overrides.paragraphColour
  }

  const mergedBlocks = mergeBlocks(props.innerBlocks, props.block.innerImage)
  const blocks = buildBlocks(
    mergedBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()

  return (
    <div
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.sideCardBlock} `}
      data-override-background={
        overrides.backgroundColour !== "secondary"
          ? overrides.backgroundColour
          : null
      }
    >
      {blocks[0]}
      <div className="con-wrap">
        <div className="min-height">
          {blocks[1]}
          {blocks[2]}
        </div>
        <div className="links">{blocks[3]}</div>
      </div>
    </div>
  )
}

export const BblSideCardBlockFragment = graphql`
  fragment BblSideCardBlock on WpBlock {
    ... on WpBblSideCardBlock {
      name
      attributes {
        className
      }
      innerImage: innerBlocks {
        ... on WpCoreImageBlock {
          name
          originalContent
          attributes {
            __typename
            ... on WpCoreImageBlockAttributes {
              url
              alt
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 768
                    aspectRatio: 0.85
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                    quality: 90
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblSideCardBlockPreview = `
    ... on BblSideCardBlock {
        name
        attributes {
            className
        }
        inner: innerBlocks {
            ... on WpCoreImageBlock {
                name
                originalContent {
                    attributes {
                        __typename
                        ... on WpCoreImageBlockAttributes {
                            url
                        }
                    }
                }
            }
        }
    }
`
