import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { buildBlocks } from "../../../services/builder"
import EventIcon from "@material-ui/icons/Event"
import { CoreHeadingBlock } from "../CoreHeadingBlock"
import { CoreParagraphBlock } from "../CoreParagraphBlock"
import { AcfButtonBlock } from "../AcfButtonBlock"

import ReactHtmlParser from "react-html-parser"

import { makeStyles } from "@material-ui/styles"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfJobsListBlock = props => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query JobsListBlock {
      allWpJob {
        edges {
          node {
            id
            slug
            title
            link
            date(formatString: "DD-MM-YYYY")
            Jobs {
              introductionSnippet
              jobDetails
              applicationEmail
            }
          }
        }
      }
      wp {
        themeOptions {
          ThemeOptions {
            jobsList {
              backgroundColour
              headerColour
              jobHeaderColour
              jobDateColour
              jobExcerptColour
              jobTagColour
              jobHrLineColour
            }
          }
        }
      }
    }
  `)

  const overrides = data.wp.themeOptions.ThemeOptions.jobsList

  const buttonProps = props.block.blockJobsList

  // loop through all block and set heading color

  if (
    props.innerBlocks[0].attributes.textColor === "" &&
    overrides.headerColour !== "black"
  ) {
    props.innerBlocks[0].attributes.textColor = overrides.headerColour
  }

  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  )

  const jobs = data.allWpJob

  return (
    <div
      className={`${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.jobsList} `}
      data-override-background={
        overrides.backgroundColour !== "tertiary"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="wrapper">
        {blocks.map(block => {
          return block
        })}
        <div className="jobs-list">
          {jobs.edges.map(job => {
            return (
              <div className="job" key={job.node.id}>
                <div className="date-and-post-type">
                  <span
                    data-override-color={
                      overrides.jobTagColour !== "primary"
                        ? overrides.jobTagColour
                        : null
                    }
                  >
                    JOB
                  </span>
                  <span
                    data-override-color={
                      overrides.jobDateColour !== "primary"
                        ? overrides.jobDateColour
                        : null
                    }
                  >
                    <EventIcon />
                    {job.node.date}
                  </span>
                </div>
                <CoreHeadingBlock
                  attributes={{
                    textAlign: "left",
                    anchor: "",
                    className: "",
                    content: ReactHtmlParser(job.node.title),
                    level: 3,
                    textColor:
                      overrides.jobHeaderColour !== "black"
                        ? overrides.jobHeaderColour
                        : "black",
                    backgroundColor: "",
                    __typename: "WpCoreHeadingBlockAttributes",
                  }}
                  innerBlocks={[]}
                />
                <CoreParagraphBlock
                  attributes={{
                    align: "left",
                    className: "",
                    content: ReactHtmlParser(job.node.Jobs.introductionSnippet),
                    textColor:
                      overrides.jobExcerptColour !== "black"
                        ? overrides.jobExcerptColour
                        : "black",
                    backgroundColor: "",
                    fontSize: "",
                    __typename: "WpCoreParagraphBlockAttributes",
                  }}
                  innerBlocks={[]}
                />
                <AcfButtonBlock
                  attributes={{
                    className: "",
                  }}
                  block={{
                    ButtonFields: {
                      buttonLink: {
                        target: "_blank",
                        title: buttonProps.buttonText,
                        url: job.node.link,
                      },
                      buttonStyle: buttonProps.buttonStyle,
                      icon: "empty",
                      showIcon: "none",
                    },
                  }}
                  innerBlocks={[]}
                />
                <hr
                  data-override-background={
                    overrides.jobHrLineColour !== "white"
                      ? overrides.jobHrLineColour
                      : null
                  }
                ></hr>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

AcfJobsListBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfJobsListBlockFragment = graphql`
  fragment AcfJobsListBlock on WpBlock {
    ... on WpAcfJobsListBlock {
      name
      attributes {
        className
      }
      blockJobsList {
        buttonText
        buttonStyle
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfJobsListBlockPreview = `
  ... on WpAcfJobsListBlock {
    name
    attributes {
      className
    }
    blockJobsList {
      buttonText
      buttonStyle
    }
    
  }
`
