import React from 'react';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import RoomIcon from '@material-ui/icons/Room';

const useStyles = makeStyles(theme => styles(theme));

export default function Marker() {
  const classes = useStyles();

  return <div className={classes.mapMarker}><RoomIcon /></div>;
}
