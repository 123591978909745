import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';
import PreviewImage from '../../PreviewImage';

import { CoreHeadingBlock } from "../CoreHeadingBlock";
import { CoreParagraphBlock } from "../CoreParagraphBlock";
import { AcfButtonBlock } from "../AcfButtonBlock";

import TodayIcon from '@material-ui/icons/Today';

const useStyles = makeStyles(theme => styles(theme));

export const BblStickyPostSplitBlock = (props) => {

  // console.log(props.pageContext);

  const data = useStaticQuery(graphql`
        query stickyPostsSplit {
            allWpPost(filter: {isSticky: {eq: true}}) {
                nodes {
                  id
                  title
                  uri
                  excerpt
                  date(formatString: "DD MMMM YYYY")
                  categories {
                    nodes {
                      slug
                      name
                    }
                  }
                  featuredImage {
                    node {
                      mediaItemUrl
                      altText
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            width: 960
                            placeholder: BLURRED,
                            aspectRatio: 1.5,
                            formats: [AUTO, WEBP, AVIF],
                            transformOptions: {fit: COVER, cropFocus: CENTER},
                            quality: 90
                          )
                        }
                      }
                    }
                  }
                }
              }
              wp {
          themeOptions {
              ThemeOptions {
                  stickyPostSplit {
                      backgroundColour     
                      postBackgroundColour                 
                      categoryBackgroundColour
                      postHeaderColour
                      postDateColour
                      postExcerptColour
                      postCategoryColour                      
                  }
              }
          }
      }
        }`);

  const overrides = data.wp.themeOptions.ThemeOptions.stickyPostSplit;


  const classes = useStyles();

  let stickyPost = [];

  if (props.pageContext.catSlug) {
    for (const post of data.allWpPost.nodes) {
      for (const cat of post.categories.nodes) {
        if (cat.slug === props.pageContext.catSlug) {
          stickyPost = post;
          break;
        }
      }
    }
  } else {
    stickyPost = data.allWpPost.nodes[0];
  }

  return (
    <div className={`${props.attributes.className ? props.attributes.className : ''} ${classes.stickyPostSplit}`}
      data-override-background={overrides.backgroundColour !== 'background' ? overrides.backgroundColour : null}
    >
      <div className="wrapper">
        <div className="sticky-post-split"
          data-override-background={overrides.postBackgroundColour !== 'primary' ? overrides.postBackgroundColour : null}

        >
          <PreviewImage
            image={stickyPost.featuredImage.node.localFile}
            fallbackUrl={stickyPost.featuredImage.node.mediaItemUrl}
            alt={stickyPost.featuredImage.node.altText}
            fixedPadding="65%"
          />
          <p className="category"
            data-override-color={overrides.postCategoryColour !== 'on-accent' ? overrides.postCategoryColour : null}
            data-override-background={overrides.categoryBackgroundColour !== 'accent' ? overrides.categoryBackgroundColour : null}
          >{stickyPost.categories?.nodes[0].name}</p>
          <div className="con-wrap">
            <p className="date"
              data-override-color={overrides.postDateColour !== 'accent' ? overrides.postDateColour : null}

            ><TodayIcon /> {stickyPost.date}</p>
            <CoreHeadingBlock
              attributes={{
                textAlign: "left",
                anchor: "",
                className: "",
                content: ReactHtmlParser(stickyPost.title),
                level: 3,
                textColor: overrides.postHeaderColour !== 'on-primary' ? overrides.postHeaderColour : 'on-primary',
                backgroundColor: "",
                __typename: "WpCoreHeadingBlockAttributes",
              }}
              innerBlocks={[]}
            />
            <CoreParagraphBlock
              attributes={{
                align: "left",
                className: "",
                content: ReactHtmlParser(stickyPost.excerpt.replace('<p>', '').replace('</p>', '')),
                textColor: overrides.postExcerptColour !== 'on-primary' ? overrides.postExcerptColour : 'on-primary',
                backgroundColor: "",
                fontSize: "",
                __typename: "WpCoreParagraphBlockAttributes",
              }}
              innerBlocks={[]}
            />
            <AcfButtonBlock
              attributes={{
                className: "",
              }}
              block={{
                ButtonFields: {
                  buttonLink: {
                    target: "",
                    title: props.attributes.btnText,
                    url: stickyPost.uri,
                  },
                  buttonStyle: "secondary",
                  icon: "",
                  showIcon: "none",
                }
              }}
              innerBlocks={[]}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

BblStickyPostSplitBlock.propTypes = {
  attributes: PropTypes.object.isRequired
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const BblStickyPostSplitBlockFragment = graphql`
  fragment BblStickyPostSplitBlock on WpBlock {
    ... on WpBblStickyPostSplitBlock {
      name
      attributes {
        __typename
        ... on WpBblStickyPostSplitBlockAttributes {
            className
            btnText
        }
      }
    }
  }`;

export const BblStickyPostSplitBlockPreview = `
... on BblStickyPostSplitBlock {
  name
  attributes {
    __typename
    ... on BblStickyPostSplitBlockAttributes {
      className
      btnText
    }
  }
}
`;