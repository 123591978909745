import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import ReactHtmlParser from "react-html-parser"
import { CoreHeadingBlock } from "../CoreHeadingBlock"

const useStyles = makeStyles(theme => styles(theme))

export const BblSideCardGridBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query sideCardGridOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            sideCardGrid {
              backgroundColour
              headerColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.sideCardGrid

  return (
    <div
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.sideCardGrid} `}
      data-override-background={
        overrides.backgroundColour !== "tertiary"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="wrapper">
        {props.attributes.hasHeading && (
          <CoreHeadingBlock
            attributes={{
              align: "left",
              anchor: "",
              className: "",
              content: ReactHtmlParser(props.attributes.heading),
              level: 3,
              textColor:
                overrides.headerColour !== "on-tertary"
                  ? overrides.headerColour
                  : "",
              backgroundColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]}
          />
        )}
        <div className="cards">
          {blocks.map(block => {
            return block
          })}
        </div>
      </div>
    </div>
  )
}

export const BblSideCardGridFragment = graphql`
  fragment BblSideCardGridBlock on WpBlock {
    ... on WpBblSideCardGridBlock {
      name
      attributes {
        className
        hasHeading
        heading
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblSideCardGridBlockPreview = `
    ... on BblSideCardGridBlock {
        name
        attributes {
            className
            hasHeading
            heading
        }
    }
`
