import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { trackSocialShare } from '../../services/gtm';
import styles from './styles';

import ShareIcon from '@material-ui/icons/Share';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles(theme => styles(theme));

export default function SocialShare(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(!open);
    }

    // console.log(props);

    return (
        <div className={`SocialShare ${classes.socialShare}`} open={open}>
            <button onClick={handleOpen} onKeyDown={handleOpen}>Share<ShareIcon /></button>
            <div className="buttons">
                <a className="email" onClick={() => trackSocialShare('email')} href={"mailhref:?subject=" + props.title + "&amp;body=" + process.env.GATSBY_FRONTEND_URL + '/tyde' + props.path}><EmailIcon /> Share</a>
                <a className="whatsapp" onClick={() => trackSocialShare('WhatsApp')} href={"whatsapp://send?text=" + process.env.GATSBY_FRONTEND_URL + '/tyde' + props.path}><WhatsAppIcon /> Share</a>
                <a className="facebook" onClick={() => trackSocialShare('Facebook')} href={"https://www.facebook.com/sharer/sharer.php?u=" + process.env.GATSBY_FRONTEND_URL + '/tyde' + props.path}><FacebookIcon /> Share</a>
                <a className="twitter" onClick={() => trackSocialShare('Twitter')} href={"https://twitter.com/intent/tweet?text=" + props.title + "&url=" + process.env.GATSBY_FRONTEND_URL + '/tyde' + props.path}><TwitterIcon /> Share</a>
            </div>
        </div>
    )
}