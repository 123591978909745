import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby";
import {buildBlocks} from "../../../services/builder";
import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import ReactHtmlParser from 'react-html-parser';
import PreviewImage from '../../PreviewImage';

import {CoreHeadingBlock} from "../CoreHeadingBlock";
import {CoreParagraphBlock} from "../CoreParagraphBlock";
import {AcfButtonBlock} from "../AcfButtonBlock";

const useStyles = makeStyles(theme => styles(theme));

export const AcfPageListingBlock = (props) => {

  // console.log(props);

  const data = useStaticQuery(graphql`
    query PageListingBlock {
      wp {
          themeOptions {
              ThemeOptions {
                  pageListing {
                      backgroundColour
                      headerColour
                      cardBackgroundColour
                      cardHeaderColour
                      cardParagraphColour
                  }
              }
          }
      }
    }
  `);

  let overrides = data.wp.themeOptions.ThemeOptions.pageListing;

  if(props.innerBlocks[0].attributes.textColor === "" && overrides.headerColour !== 'on-tertiary') {
    props.innerBlocks[0].attributes.textColor = overrides.headerColour;
  }

  const blocks = buildBlocks(props.innerBlocks, props.attributes, props.pageContext);
  const classes = useStyles();
  
  return <div className={`${ props.attributes.className ? props.attributes.className : "" } ${classes.pageListing} `} 
              data-override-background={ overrides.backgroundColour !== 'tertiary' ? overrides.backgroundColour : null }  
  >
    <div className="wrapper">
      {blocks.map((block) => {
        return block;
      })}
      <div className="listing">
        {props.block.PageListing.pages.map((page) => {
          return <div className="side-card" 
                      key={page.id}
                      data-override-background={ overrides.cardBackgroundColour !== 'tertiary' ? overrides.cardBackgroundColour : null }  
          >
            <PreviewImage
                image={page.featuredImage.node.localFile}
                fallbackUrl={page.featuredImage.node.mediaItemUrl}
                alt={page.featuredImage.node.altText}
                fixedPadding="72%"
            />
            <div className="con-wrap">
              <div className="min-height">
                <CoreHeadingBlock 
                  attributes={{
                    textAlign: "left",
                    anchor: "",
                    className: "",
                    content: ReactHtmlParser(page.title),
                    level: 4,
                    textColor: overrides.cardHeaderColour !== 'on-secondary' ? overrides.cardHeaderColour : '',
                    __typename: "WpCoreHeadingBlockAttributes",
                  }}
                  innerBlocks={[]} 
                />
                <CoreParagraphBlock 
                  attributes={{
                    align: "left",
                    className: "",
                    content: ReactHtmlParser(page.excerpt.replace('<p>', '').replace('</p>', '')),
                    backgroundColor: "",
                    textColor: overrides.cardParagraphColour !== 'on-secondary' ? overrides.cardParagraphColour : '',
                    fontSize: "",
                    __typename: "WpCoreParagraphBlockAttributes",
                  }}
                  innerBlocks={[]} 
                />
              </div>
              <div className="links">
              <AcfButtonBlock
                attributes={{
                  className: "",
                }}
                block={{
                  ButtonFields: {
                    buttonLink: {
                      target: "",
                      title: "Find Out More",
                      url: page.uri,
                    },
                    buttonStyle: "secondary",
                    icon: "",
                    showIcon: "none",
                  }
                }}
                innerBlocks={[]} 
              />
              </div>
            </div>
          </div>
        })}
      </div>
    </div>
  </div>
};

AcfPageListingBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const AcfPageListingBlockFragment = graphql`
  fragment AcfPageListingBlock on WpBlock {
    ... on WpAcfPageListingBlock {
      name
      attributes {
          className
      }
      PageListing {
        fieldGroupName
        pages {
          ... on WpPage {
            id
            title
            excerpt
            uri
            featuredImage {
              node {
                mediaItemUrl
                altText
                localFile {
                  publicURL
                  childImageSharp {
                      gatsbyImageData(
                        width: 768,
                        aspectRatio: 0.85,
                        placeholder: BLURRED,
                        formats: [AUTO, WEBP, AVIF],
                        transformOptions: {fit: COVER, cropFocus: CENTER},
                        quality: 90
                      )
                  }
                }
              }
            }
          }
        }
      }
    } 
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const AcfPageListingBlockPreview = `
  ... on AcfPageListingBlock {
      name
      attributes {
          className
      }
      blockPageListing {
        department {
          termTaxonomyId
          id
        }
      }
  }
`;