export default theme => ({ // eslint-disable-line
    twoColumnWrapperInner: {
        flex: '0 0 100%',
        [theme.breakpoints.up('md')]: {
            flex: '0 0 50%',
        },
        '& > .MuiTypography-root': {
            marginBottom: 36,
            '&:not([data-override-background="initial"])': {
                marginBottom: 24,
            },
        },
        '& > p': {
            marginBottom: 36,
        },
        '& > a': {
            marginLeft: 12,
            marginBottom: 36,
        },
        '&[data-is-thin="true"]': {
            flex: '0 0 83.333%',
            margin: '0 8.333%',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 33.333%'
            },
            '& > .MuiTypography-root': {
                width: '110%',
                '&:not([data-override-background="initial"])': {
                    width: 'calc(120% - 24px)',
                    marginLeft: 'calc(0px - (10% - 12px))',
                    padding: '8.333%',
                },
                [theme.breakpoints.up('md')]: {
                    width: '125%',
                    '&:not([data-override-background="initial"])': {
                        width: 'calc(150% - 24px)',
                        marginLeft: 'calc(0px - (25% - 12px))',
                        padding: '16.666%',
                    },
                }
            },
            '& > .gatsby-image-wrapper': {
                width: 'calc(120% - 24px)',
                marginLeft: 'calc(0px - (10% - 12px))',
                padding: '8.333%',
                [theme.breakpoints.up('md')]: {
                    width: 'calc(150% - 24px)',
                    marginLeft: 'calc(0px - (25% - 12px))',
                    padding: '16.666%',
                }
            },
            '& > .media-item': {
                width: 'calc(120% - 24px)',
                marginLeft: 'calc(0px - (10% - 12px))',
                [theme.breakpoints.up('md')]: {
                    width: 'calc(150% - 24px)',
                    marginLeft: 'calc(0px - (25% - 12px))',
                }
            }
        },
    },
})