import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { buildBlocks } from "../../../services/builder"
import GallerySlider from "./slider"

import { makeStyles } from "@material-ui/styles"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfImageSliderBlock = props => {

  const data = useStaticQuery(graphql`
    query imageSliderOverrides {
        wp {
            themeOptions {
                ThemeOptions {
                    imageSlider {
                        backgroundColour
                        headerColour
                        arrowFill
                        arrowColour
                        dotsColour
                    }
                }
            }
        }
    }`);

    let overrides = data.wp.themeOptions.ThemeOptions.imageSlider;

    if(props.innerBlocks[0].attributes.textColor === "" && overrides.headerColour !== 'black') {
        props.innerBlocks[0].attributes.textColor = overrides.headerColour;
    }

  const classes = useStyles()

  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  )

  //   console.log(props.block.blockImageSlider);
  const images = props.block.blockImageSlider.imageSlider

  return (
    <div
      className={`${ props.attributes.className ? props.attributes.className : "" } ${classes.imageSlider} `}
      data-override-background={ overrides.backgroundColour !== 'background' ? overrides.backgroundColour : null }  
    >
      <div className="wrapper">
        {blocks.map(block => {
          return block
        })}
        <div className="slide-wrap">
          <GallerySlider images={images} overrides={overrides}/>
        </div>
      </div>
    </div>
  )
}

AcfImageSliderBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfImageSliderBlockFragment = graphql`
  fragment AcfImageSliderBlock on WpBlock {
    ... on WpAcfImageSliderBlock {
      name
      attributes {
        className
      }
      blockImageSlider {
        imageSlider {
          id
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1142
                aspectRatio: 1.78
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfImageSliderBlockPreview = `
  ... on AcfImageSliderBlock {
      name
      attributes {
          className
      }
      blockImageSlider {
        imageSlider {
            id
            localFile {
                publicURL
                childImageSharp {
                    fluid(maxWidth: 500, quality: 100) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize                }
              }
        }
      }
  }

`
