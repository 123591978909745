export default theme => ({ // eslint-disable-line
    twoColumnTextIntro: {
        backgroundColor: theme.palette.background.main,
        margin: '0 auto',
        padding: '50px 0 34px',
        [theme.breakpoints.up('md')]: {
            padding: '110px 0 104px',
        },
        '& .MuiTypography-root': {
            marginBottom: 24,
            [theme.breakpoints.up('md')]: {
                maxWidth: '66.6666%',
                marginRight: '33.333%',
                marginBottom: 32,
            }
        },
        '& p': {
            maxWidth: '91.666%',
            marginRight: '8.333%',
            marginBottom: 24,
            [theme.breakpoints.up('md')]: {
                maxWidth: '41.666%',
                marginRight: '8.333%',
            },
        },
        '&[data-offset="true"]': {
            '& p': {
                marginLeft: '8.333%',
                marginRight: 0,
                [theme.breakpoints.up('md')]: {
                    marginRight: 0,
                },
            },
        }
    },
})