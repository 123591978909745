export default theme => ({ // eslint-disable-line
    branchListing: {
        padding: '32px 0',
        [theme.breakpoints.up('md')]: {
            padding: '72px 0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '120px 0',
        },
        '& .wrapper': {
            '& > .MuiTypography-root': {
                marginBottom: 24,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 48,
                }
            },
            '& .branches': {
                flex: '0 0 100%',
                maxWidth: '100%',
                [theme.breakpoints.up('md')]: {
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                },
                '& .branch': {
                    display: 'block',
                    width: 'calc(100% - 24px)',
                    margin: 12,
                    backgroundColor: theme.palette.primary.main,
                    padding: '24px 12px',
                    borderRadius: theme.border.radius,
                    [theme.breakpoints.up('md')]: {
                        flex: '0 0 calc(50% - 24px)',
                        maxWidth: 'calc(50% - 24px)',
                        padding: 36,
                    },
                    '& .gatsby-image-wrapper': {
                        margin: '0 auto 24px',
                        borderRadius: '50%',
                        display: 'block',
                        width: 110,
                        overflow: 'hidden',
                        [theme.breakpoints.up('sm')]: {
                            float: 'right',
                            margin: '0 12px 0 24px',
                        }
                    },
                    '& .info': {
                        paddingLeft: 50,
                        position: 'relative',
                        color: theme.palette.primary.contrastText,
                        '& svg': {
                            width: 24,
                            height: 24,
                            position: 'absolute',
                            top: 12,
                            left: 12,
                        },
                        '& .button': {
                            marginLeft: 12,
                        },
                        '& .MuiTypography-root, & p': {
                            color: theme.palette.primary.contrastText,
                        }
                    }
                }
            },
            '& > .button': {
                marginTop: 24,
                marginLeft: 'auto',
                marginRight: 'auto',
                [theme.breakpoints.up('md')]: {
                    marginTop: 48,
                }
            },
        }
    },
})