export default theme => ({ // eslint-disable-line
    postBanner: {
        '& .banner': {
            backgroundColor: theme.palette.primary.main,
            padding: '32px 0',
            [theme.breakpoints.up('md')]: {
                padding: '80px 0',
            },
            '& .wrapper': {
                maxWidth: 960,
                width: 'calc(100% - 24px)',
                '& .MuiTypography-root': {
                    color: theme.palette.primary.contrastText,
                    marginBottom: 32,
                },
                '& > p': {
                    color: theme.palette.primary.contrastText,
                    marginBottom: 32,
                    '&.date, &.category': {
                        display: 'inline-block',
                        width: '50%',
                        fontSize: 12,
                        letterSpacing: '0.2em',
                        lineHeight: '20px',
                        fontWeight: 'bold',
                        padding: '0 12px',
                        textTransform: 'uppercase',
                        '& svg': {
                            width: 16,
                            height: 16,
                            display: 'inline-block',
                            verticalAlign: 'top',
                            marginTop: 2,
                            marginRight: 5,
                        }
                    },
                    '&.category': {
                        textAlign: 'right',
                    }
                },
                '& .gatsby-image-wrapper': {
                    margin: '0 12px',
                }
            }
        },
        '& .wrapper.extras': {
            padding: 12,
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                paddingTop: 48,
                marginBottom: -60,
            },
            '& button.go-back': {
                color: theme.palette.accent.main,
                textDecoration: 'underline',
                fontWeight: 'bold',
                fontFamily: theme.bodyFont,
                backgroundColor: 'transparent',
                padding: '12px 0',
                border: 'none',
                letterSpacing: '0.008em',
                fontSize: 16,
                [theme.breakpoints.up('md')]: {
                    fontSize: 18,
                },
                '& svg': {
                    width: 16,
                    height: 16,
                    display: 'inline-block',
                    marginRight: 5,
                    marginTop: 2,
                    verticalAlign: 'top',
                    [theme.breakpoints.up('md')]: {
                        marginTop: 4,
                    },
                }
            },
            '& .SocialShare': {
                position: 'absolute',
                top: 12,
                right: 12,
                [theme.breakpoints.up('md')]: {
                    top: 48,
                }
            }
        }
    },
});