import React, { Component } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { AcfButtonBlock } from "../AcfButtonBlock"
import { CoreHeadingBlock } from "../CoreHeadingBlock"
import { CoreParagraphBlock } from "../CoreParagraphBlock"

import Slider from "react-slick"
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import EventIcon from "@material-ui/icons/Event"
import ReactHtmlParser from "react-html-parser"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

export default class LatestPostsSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slider: [],
    }
  }

  componentDidMount() {
    this.setState({
      slider: this.props.cards,
    })
  }

  render() {
    const { overrides } = this.props

    return (
      <div className="slider-main">
        <Slider
          dots={true}
          arrows={true}
          nextArrow={
            <ArrowRightIcon
              data-override-background={
                overrides.arrowColour !== "accent"
                  ? overrides.arrowColour
                  : null
              }
              data-override-color={
                overrides.arrowFill !== "on-accent" ? overrides.arrowFill : null
              }
            />
          }
          prevArrow={
            <ArrowLeftIcon
              data-override-background={
                overrides.arrowColour !== "accent"
                  ? overrides.arrowColour
                  : null
              }
              data-override-color={
                overrides.arrowFill !== "on-accent" ? overrides.arrowFill : null
              }
            />
          }
          infinite={false}
          slidesToShow={3}
          swipeToSlide={true}
          focusOnSelect={true}
          responsive={[
            {
              breakpoint: 920,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
          className={`slider ${
            overrides.dotsColour !== "accent" ? overrides.dotsColour : null
          }-dots`}
        >
          {this.state.slider.map(card => {
            const image = getImage(card.featuredImage.node.localFile)
            return (
              <div
                className="card"
                key={card.id}
                data-override-background={
                  overrides.cardBackgroundColour !== "secondary"
                    ? overrides.cardBackgroundColour
                    : null
                }
              >
                <GatsbyImage image={image} alt={card.slug} />
                <div className="con-wrap">
                  <div className="min-height">
                    <CoreParagraphBlock
                      attributes={{
                        align: "left",
                        className: "post-date",
                        content: ReactHtmlParser(card.date),
                        textColor:
                          overrides.cardDateColour !== "black"
                            ? overrides.cardDateColour
                            : "black",
                        backgroundColor: "",
                        fontSize: "small",
                        preParagraph: <EventIcon />,

                        __typename: "WpCoreParagraphBlockAttributes",
                      }}
                      innerBlocks={[]}
                    />
                    <CoreHeadingBlock
                      attributes={{
                        textAlign: "left",
                        anchor: "",
                        className: "",
                        content: ReactHtmlParser(card.title),
                        level: 4,
                        textColor:
                          overrides.cardHeaderColour !== "black"
                            ? overrides.cardHeaderColour
                            : "black",
                        backgroundColor: "",
                        __typename: "WpCoreHeadingBlockAttributes",
                      }}
                      innerBlocks={[]}
                    />
                    <CoreParagraphBlock
                      attributes={{
                        align: "left",
                        className: "post-excerpt",
                        content: ReactHtmlParser(
                          card.excerpt.replace("<p>", "").replace("</p>", "")
                        ),
                        textColor:
                          overrides.cardExcerptColour !== "black"
                            ? overrides.cardExcerptColour
                            : "black",
                        backgroundColor: "",
                        fontSize: "",
                        __typename: "WpCoreParagraphBlockAttributes",
                      }}
                      innerBlocks={[]}
                    />
                  </div>
                  <div className="links">
                    <AcfButtonBlock
                      attributes={{
                        className: "post-link",
                      }}
                      block={{
                        ButtonFields: {
                          buttonLink: {
                            target: "",
                            title: "Find Out More",
                            url: card.link,
                          },
                          buttonStyle: "secondary",
                          icon: "",
                          showIcon: "none",
                        },
                      }}
                      innerBlocks={[]}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }
}

//ReactHtmlParser(card.excerpt.replace('<p>', '<span>').replace('</p>','</span>')
