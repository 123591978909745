export default theme => ({ // eslint-disable-line
    cardBlock: {
        borderRadius: theme.border.radius,
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadow,
        '& .con-wrap': {
            padding: 32,
            '& .min-height': {
                minHeight: 150,
                paddingBottom: 1,
            },
            '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
                color: theme.palette.secondary.contrastText,
                padding: 0,
            },
        },
        '& .links': {
            '& > a': {
                margin: 0,
            }
        }
    },
})