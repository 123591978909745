const styles = theme => ({
  formIntro: {
    padding: "50px 0",
    [theme.breakpoints.up("md")]: {
      padding: "100px 0",
    },
    "& .wrapper": {
      flexDirection: "column",
      "& .MuiTypography-root": {
        marginBottom: 24,
        [theme.breakpoints.up("md")]: {
          width: "66.666%",
          margin: "0 auto 24px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "50%",
        },
      },
      "& > p": {
        marginBottom: 40,
        [theme.breakpoints.up("md")]: {
          width: "66.666%",
          margin: "0 auto 40px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "50%",
        },
      },
      "& .form-wrap": {
        padding: 1,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up("md")]: {
          width: "66.666%",
          margin: "0 auto 40px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "50%",
        },
        "& > div": {
          margin: 24,
          width: "calc(100% - 48px)",
          [theme.breakpoints.up("md")]: {
            margin: "6.25% 12.5%",
            width: "75%",
          },
          [theme.breakpoints.up("lg")]: {
            margin: "8.333% 16.666%",
            width: "66.666%",
          },
          "& .gfield": {
            "& .ginput_container": {
              "& > label": {
                color: theme.palette.primary.contrastText,
              },
              '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
                color: theme.palette.primary.contrastText,
                backgroundColor: "transparent",
                // "-webkit-text-fill-color":
                //   theme.palette.primary.contrastText + " !important",
                // "-webkit-box-shadow": "0 0 0px 100px " +    theme.palette.primary.main +     " inset !important",
              },
              "& select": {
                backgroundImage:
                  "linear-gradient(45deg, transparent 50%, " +
                  theme.palette.primary.contrastText +
                  " 50%), linear-gradient(135deg, " +
                  theme.palette.primary.contrastText +
                  " 50%, transparent 50%)",
              },
              "& .after": {
                background:
                  "linear-gradient(to right, " +
                  theme.palette.primary.highlight +
                  " 50%, " +
                  theme.palette.primary.contrastText +
                  " 50%)",
              },
            },
            "&.gravityform__field__checkbox, &.gravityform__field__radio": {
              "& label": {
                color: theme.palette.primary.contrastText,
              },
              "& .gfield_checkbox, & .gfield_radio": {
                '& [type="checkbox"], & [type="radio"]': {
                  "& + label": {
                    "&::before": {
                      border: "2px solid " + theme.palette.primary.contrastText,
                      background: "transparent",
                    },
                  },
                },
              },
            },
            "& .gravityforms__description": {
              color: theme.palette.primary.contrastText,
            },
          },
          "& .gform_confirmation_message": {
            color: theme.palette.primary.contrastText,
          },
          '&[data-form-style="outline"]': {
            "& .gfield": {
              "& .ginput_container": {
                '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
                  border: "1px solid " + theme.palette.primary.contrastText,
                },
              },
            },
          },
          '&[data-override-background="secondary"]': {
            "& .gfield": {
              "& .ginput_container": {
                "& > label": {
                  color: theme.palette.secondary.contrastText,
                },
                '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
                  color: theme.palette.secondary.contrastText,
                  backgroundColor: "transparent",
                  // "-webkit-text-fill-color":
                  //   theme.palette.secondary.contrastText + " !important",
                  // '-webkit-box-shadow': '0 0 0px 100px ' + theme.palette.secondary.main + ' inset !important',
                },
                "& select": {
                  backgroundImage:
                    "linear-gradient(45deg, transparent 50%, " +
                    theme.palette.secondary.contrastText +
                    " 50%), linear-gradient(135deg, " +
                    theme.palette.secondary.contrastText +
                    " 50%, transparent 50%)",
                },
                "& .after": {
                  background:
                    "linear-gradient(to right, " +
                    theme.palette.secondary.highlight +
                    " 50%, " +
                    theme.palette.secondary.contrastText +
                    " 50%)",
                },
              },
              "&.gravityform__field__checkbox, &.gravityform__field__radio": {
                "& label": {
                  color: theme.palette.secondary.contrastText,
                },
                "& .gfield_checkbox, & .gfield_radio": {
                  '& [type="checkbox"], & [type="radio"]': {
                    "& + label": {
                      "&::before": {
                        border:
                          "2px solid " + theme.palette.secondary.contrastText,
                        background: "transparent",
                      },
                    },
                  },
                },
              },
              "& .gravityforms__description": {
                color: theme.palette.secondary.contrastText,
              },
            },
            "& .gform_confirmation_message": {
              color: theme.palette.secondary.contrastText,
            },
            '&[data-form-style="outline"]': {
              "& .gfield": {
                "& .ginput_container": {
                  '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
                    border: "1px solid " + theme.palette.secondary.contrastText,
                  },
                },
              },
            },
          },
          '&[data-override-background="tertiary"]': {
            "& .gfield": {
              "& .ginput_container": {
                "& > label": {
                  color: theme.palette.tertiary.contrastText,
                },
                '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
                  color: theme.palette.tertiary.contrastText,
                  backgroundColor: "transparent",
                  // "-webkit-text-fill-color":
                  //   theme.palette.tertiary.contrastText + " !important",
                  //   "-webkit-box-shadow": "0 0 0px 100px " +  theme.palette.tertiary.main +   " inset !important",
                },
                "& select": {
                  backgroundImage:
                    "linear-gradient(45deg, transparent 50%, " +
                    theme.palette.tertiary.contrastText +
                    " 50%), linear-gradient(135deg, " +
                    theme.palette.tertiary.contrastText +
                    " 50%, transparent 50%)",
                },
                "& .after": {
                  background:
                    "linear-gradient(to right, " +
                    theme.palette.tertiary.highlight +
                    " 50%, " +
                    theme.palette.tertiary.contrastText +
                    " 50%)",
                },
              },
              "&.gravityform__field__checkbox, &.gravityform__field__radio": {
                "& label": {
                  color: theme.palette.tertiary.contrastText,
                },
                "& .gfield_checkbox, & .gfield_radio": {
                  '& [type="checkbox"], & [type="radio"]': {
                    "& + label": {
                      "&::before": {
                        border:
                          "2px solid " + theme.palette.tertiary.contrastText,
                        background: "transparent",
                      },
                    },
                  },
                },
              },
              "& .gravityforms__description": {
                color: theme.palette.tertiary.contrastText,
              },
            },
            "& .gform_confirmation_message": {
              color: theme.palette.tertiary.contrastText,
            },
            '&[data-form-style="outline"]': {
              "& .gfield": {
                "& .ginput_container": {
                  '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
                    border: "1px solid " + theme.palette.tertiary.contrastText,
                  },
                },
              },
            },
          },
          '&[data-override-background="background"]': {
            "& .gfield": {
              "& .ginput_container": {
                "& > label": {
                  color: theme.common.black,
                },
                '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
                  color: theme.common.black,
                  backgroundColor: "transparent",
                  // "-webkit-text-fill-color":
                  //   theme.palette.common.black + " !important",
                  //   "-webkit-box-shadow":       "0 0 0px 100px " + theme.common.white + " inset !important",
                },
                "& select": {
                  backgroundImage:
                    "linear-gradient(45deg, transparent 50%, " +
                    theme.common.black +
                    " 50%), linear-gradient(135deg, " +
                    theme.common.black +
                    " 50%, transparent 50%)",
                },
                "& .after": {
                  background:
                    "linear-gradient(to right, " +
                    theme.palette.primary.main +
                    " 50%, " +
                    theme.common.black +
                    " 50%)",
                },
              },
              "&.gravityform__field__checkbox, &.gravityform__field__radio": {
                "& label": {
                  color: theme.common.black,
                },
                "& .gfield_checkbox, & .gfield_radio": {
                  '& [type="checkbox"], & [type="radio"]': {
                    "& + label": {
                      "&::before": {
                        border: "2px solid " + theme.common.black,
                        background: "transparent",
                      },
                    },
                  },
                },
              },
              "& .gravityforms__description": {
                color: theme.common.black,
              },
            },
            "& .gform_confirmation_message": {
              color: theme.palette.common.black,
            },
            '&[data-form-style="outline"]': {
              "& .gfield": {
                "& .ginput_container": {
                  '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
                    border: "1px solid " + theme.common.black,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
})
export default styles
