import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import PreviewImage from "../../PreviewImage"
import Link from "../../Link"

import ListIcon from "@material-ui/icons/List"
import AppsIcon from "@material-ui/icons/Apps"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const BblBrandLogosBlock = props => {
  const classes = useStyles()

  const [filter, setFilter] = useState("")
  const [layout, setLayout] = useState("grid")
  const [type, setType] = useState(undefined)

  if (!props.attributes.grid) {
    setLayout("list")
  }

  const data = useStaticQuery(graphql`
    query brandLogos {
      allWpBrand(limit: 100) {
        edges {
          node {
            id
            name
            slug
            uri
            BrandExtras {
              type
              image {
                sourceUrl
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 250
                      aspectRatio: 1.25
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: { fit: COVER, cropFocus: CENTER }
                      quality: 90
                    )
                  }
                }
              }
            }
          }
        }
      }

      wp {
        themeOptions {
          ThemeOptions {
            brandLogo {
              backgroundColour
              brandBackgroundColour
              headerColour
              activeIconColour
              textColour
            }
          }
        }
      }
    }
  `)

  const brands = data.allWpBrand.edges.map(node => node.node)
  const letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ]

  const overrides = data.wp.themeOptions.ThemeOptions.brandLogo

  // loop through all block and set heading color

  if (
    props.innerBlocks[0].attributes.textColor === "" &&
    overrides.headerColour !== "black"
  ) {
    props.innerBlocks[0].attributes.textColor = overrides.headerColour
  }

  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  )

  return (
    <div
      className={`${classes.brandLogos} ${
        props.attributes.className ? props.attributes.className : ""
      } filter-${overrides.activeIconColour}`}
      data-override-background={
        overrides.backgroundColour !== "background"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="wrapper">
        {blocks.map(block => {
          return block
        })}
        <nav>
          {letters.map(letter => (
            <button
              key={letter}
              onClick={() => setFilter(letter)}
              data-active={filter === letter ? true : false}
            >
              {letter}
            </button>
          ))}
          <button
            onClick={() => setFilter("")}
            data-active={filter === "" ? true : false}
          >
            All
          </button>
        </nav>

        <div className="list-style">
          <button
            onClick={() => setLayout("list")}
            data-active={layout === "list" ? true : false}
          >
            <ListIcon /> List
          </button>
          <button
            onClick={() => setLayout("grid")}
            data-active={layout === "grid" ? true : false}
          >
            <AppsIcon /> Grid
          </button>
        </div>

        {props.attributes.filter && (
          <div className="list-filters">
            <button
              onClick={() => {
                setType(undefined)
              }}
              data-override-background={
                overrides.activeIconColour !== "accent"
                  ? overrides.activeIconColour
                  : "accent"
              }
              data-override-color={
                overrides.backgroundColour !== "background"
                  ? overrides.backgroundColour
                  : "background"
              }
            >
              All
            </button>
            <button
              onClick={() => {
                if (type === "light") {
                  setType(undefined)
                } else {
                  setType("light")
                }
              }}
              data-active={type === "light"}
              data-override-background={
                overrides.activeIconColour !== "accent"
                  ? overrides.activeIconColour
                  : "accent"
              }
              data-override-color={
                overrides.backgroundColour !== "background"
                  ? overrides.backgroundColour
                  : "background"
              }
            >
              Light Vehicles
            </button>
            <button
              onClick={() => {
                if (type === "commercial") {
                  setType(undefined)
                } else {
                  setType("commercial")
                }
              }}
              data-active={type === "commercial"}
              data-override-background={
                overrides.activeIconColour !== "accent"
                  ? overrides.activeIconColour
                  : "accent"
              }
              data-override-color={
                overrides.backgroundColour !== "background"
                  ? overrides.backgroundColour
                  : "background"
              }
            >
              Commercial Vehicles
            </button>
          </div>
        )}

        <div className={`brand-list ${layout}`}>
          {brands
            .filter(brand => {
              if (filter) {
                return brand.slug[0].toLowerCase() === filter.toLowerCase()
              }

              return brand
            })
            .filter(brand => {
              if (!type) return brand
              return brand.BrandExtras.type === type
            })
            .map(brand => {
              return (
                <div key={brand.id}>
                  <Link
                    to={brand.uri}
                    data-override-background={
                      overrides.brandBackgroundColour !== "primary"
                        ? overrides.brandBackgroundColour
                        : null
                    }
                  >
                    <PreviewImage
                      image={brand.BrandExtras.image.localFile}
                      fallbackUrl={brand.BrandExtras.image.sourceUrl}
                      alt={`${brand.name} logo`}
                      fixedPadding="80%"
                    />
                    <span
                      data-override-color={
                        overrides.textColour !== "on-primary"
                          ? overrides.textColour
                          : null
                      }
                    >
                      {brand.name}
                    </span>
                  </Link>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

BblBrandLogosBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

//FIXME add filter on queries

export const BblBrandLogosBlockFragment = graphql`
  fragment BblBrandLogosBlock on WpBlock {
    ... on WpBblBrandLogosBlock {
      name
      attributes {
        __typename
        ... on WpBblBrandLogosBlockAttributes {
          className
          grid
          filter
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblBrandLogosBlockPreview = `
    ... on BblBrandLogosBlock {
        name
        attributes {
            __typename
            ... on BblBrandLogosBlockAttributes {
                className
                grid
                filter
            }
        }
    }
`
