export default theme => ({ // eslint-disable-line
    postListingCardGrid: {
        padding: '24px 0',
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up('md')]: {
            padding: '64px 0 24px',
        },
        '& .card': {
            position: 'relative',
            borderRadius: theme.border.radius,
            overflow: 'hidden',
            backgroundColor: theme.palette.secondary.main,
            boxShadow: theme.shadow,
            margin: 12,
            [theme.breakpoints.up('sm')]: {
                flex: '0 0 calc(50% - 24px)',
                maxWidth: '50%'
            },
            [theme.breakpoints.up('md')]: {
                flex: '0 0 calc(33% - 24px)',
                maxWidth: '33%'
            },

            '& > p': {
                '&.category': {
                    position: 'absolute',
                    zIndex: 2,
                    top: 12,
                    right: 12,
                    fontSize: 12,
                    letterSpacing: '0.2em',
                    lineHeight: '20px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    marginBottom: 32,
                    marginTop: 0,
                    textAlign: 'right',
                    backgroundColor: theme.palette.accent.main,
                    color: theme.palette.accent.contrastText,
                    marginLeft: 'auto',
                    display: 'inline-block',
                    padding: '12px 16px',
                    width: 'auto',
                    float: 'right',
                }
            },
            '& .con-wrap': {
                padding: 32,
                '& .min-height': {
                    minHeight: 150,
                    paddingBottom: 1,
                },
                '& .meta': {
                    position: 'relative',
                    zIndex: 2,
                    '& > p': {
                        '&.date': {
                            display: 'inline-block',
                            fontSize: 12,
                            letterSpacing: '0.2em',
                            lineHeight: '20px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            color: theme.palette.secondary.contrastText,
                            marginBottom: 32,
                            marginTop: 0,
                            '& svg': {
                                width: 16,
                                height: 16,
                                display: 'inline-block',
                                verticalAlign: 'top',
                                marginTop: 2,
                                marginRight: 5,
                            }
                        },
                    }
                },
                '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
                    color: theme.palette.secondary.contrastText,
                    padding: 0,
                },
            },
            '& .links': {
                '& > a': {
                    margin: 0,
                }
            }
        },
        '& nav.MuiPagination-root': {
            flex: '0 0 100%',
            maxWidth: '100%',
            padding: '0 12px',
            margin: '50px 0',
            [theme.breakpoints.up('md')]: {
                margin: '100px 0',
            },
            '& ul': {
                justifyContent: 'center',
                '& .MuiPaginationItem-root': {
                    fontFamily: theme.headerFont,
                    borderRadius: theme.border.radius,
                    [theme.breakpoints.up('md')]: {
                        height: 44,
                        width: 44,
                        lineHeight: '44px',
                        fontSize: 18,
                    },
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                    }
                }
            }
        }
    },
})