import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import PreviewImage from "../../PreviewImage"
import GoBack from "../../GoBack"

import { CoreHeadingBlock } from "../CoreHeadingBlock"
import { CoreParagraphBlock } from "../CoreParagraphBlock"

const Categories = ({ categories }) => {
  if (!categories) return null

  return categories.map(category => {
    return <Link to={category.node.uri}>{category.node.name}</Link>
  })
}

export const BblBrandDetailBlock = props => {
  const data = useStaticQuery(graphql`
    query BrandDetailBlock {
      wp {
        themeOptions {
          ThemeOptions {
            brandDetail {
              backgroundColour
              textColour
              partsHeaderColour
              partsBackgroundColour
              partsTextColour
            }
          }
        }
      }
    }
  `)

  const overrides = data.wp.themeOptions.ThemeOptions.brandDetail

  const useStyles = makeStyles(theme => styles(theme, overrides))
  const classes = useStyles()

  const name = props.pageContext.brand.name
  const categories = props.pageContext.productCategories

  const brandCategories =
    categories &&
    categories.filter(category => {
      if (!category.node.ProductCategoryExtras.brands) return false
      if (category.node.wpParent) return false
      return category.node.ProductCategoryExtras.brands.find(
        brand => brand.name === name
      )
    })

  return (
    <div
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.brandDetail} `}
    >
      <div className="wrapper">
        <div className="back">
          <GoBack fallback="/" />
        </div>
        <div className="brand-logo">
          <PreviewImage
            image={props.pageContext.brand.BrandExtras.image.localFile}
            fallbackUrl={props.pageContext.brand.BrandExtras.image.sourceUrl}
            alt={props.pageContext.brand.name}
            fixedPadding="62.5%"
          />
        </div>
        <div className="brand-description">
          <CoreParagraphBlock
            attributes={{
              align: "left",
              className: "",
              content: props.pageContext.brand.description,
              textColor: "",
              backgroundColor: "",
              __typename: "WpCoreParagraphBlockAttributes",
            }}
            innerBlocks={[]}
          />
        </div>
        {brandCategories.length > 0 && (
          <div className="parts">
            <CoreHeadingBlock
              attributes={{
                textAlign: "left",
                anchor: "",
                className: "",
                content: "Parts for this brand",
                level: 3,
                textColor: "",
                backgroundColor: "",
                __typename: "WpCoreHeadingBlockAttributes",
              }}
              innerBlocks={[]}
            />
            <div className="product-cat-list">
              <Categories categories={brandCategories} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export const BblBrandDetailBlockFragment = graphql`
  fragment BblBrandDetailBlock on WpBlock {
    ... on WpBblBrandDetailBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblBrandDetailBlockPreview = `
    ... on BblBrandDetailBlock {
        name
        attributes {
            className
        }
    }
`
