import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { graphql, useStaticQuery } from "gatsby"
import Link from "../../Link"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactHtmlParser from "react-html-parser"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfLatestPostsGridBlock = props => {
  const classes = useStyles()

  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )

  const data = useStaticQuery(graphql`
    query LatestPostsGridBlock {
      wp {
        themeOptions {
          blogPath
          ThemeOptions {
            latestPostsGrid {
              backgroundColour
              headerColour
              featuredPostBackgroundColour
              featuredPostTextColour
              postLinkTextColour
            }
          }
        }
      }
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          id
          slug
          title
          date(formatString: "DD MMMM YYYY")
          excerpt
          categories {
            nodes {
              id
              termTaxonomyId
              name
            }
          }
          featuredImage {
            node {
              mediaItemUrl
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 960
                    aspectRatio: 1.35
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    quality: 90
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  if (props.block.BlockLatestPostsGrid.category !== null) {
    let tempPosts = []

    for (let index = 0; index < data.allWpPost.nodes.length; index++) {
      // console.log(data.allWpPost.nodes[index].categories);
      for (
        let i = 0;
        i < data.allWpPost.nodes[index].categories.nodes.length;
        i++
      ) {
        // console.log(data.allWpPost.nodes[index].categories.nodes[i].termTaxonomyId);
        if (
          data.allWpPost.nodes[index].categories.nodes[i].termTaxonomyId ===
          props.block.BlockLatestPostsGrid.category.termTaxonomyId
        ) {
          tempPosts.push(data.allWpPost.nodes[index])
          if (tempPosts.length === 4) {
            break
          }
        }
      }
    }

    data.allWpPost.nodes = tempPosts
  }

  let image = null

  if (data.allWpPost.nodes[0].featuredImage) {
    if (data.allWpPost.nodes[0].featuredImage.node.localFile) {
      if (
        data.allWpPost.nodes[0].featuredImage.node.localFile.childImageSharp
      ) {
        image = (
          <GatsbyImage
            image={
              data.allWpPost.nodes[0].featuredImage.node.localFile
                .childImageSharp.gatsbyImageData
            }
            alt=""
          />
        )
      } else {
        // Gifs do not have a childImageSharp
        image = (
          <img
            src={data.allWpPost.nodes[0].featuredImage.node.localFile}
            alt=""
          />
        )
      }
    } else {
      // This is a preview image
      image = (
        <div className="gatsby-image-wrapper">
          <div style={{ paddingBottom: "73%" }} />
          <img
            src={data.allWpPost.nodes[0].featuredImage.mediaItemUrl}
            alt=""
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
            }}
          />
        </div>
      )
    }
  } else {
  }

  let overrides = data.wp.themeOptions.ThemeOptions.latestPostsGrid

  if (
    props.innerBlocks[0].attributes.textColor === "" &&
    overrides.headerColour !== "on-tertiary"
  ) {
    props.innerBlocks[0].attributes.textColor = overrides.headerColour
  }

  return (
    <div
      className={`${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.latestPostsGrid}`}
      data-override-background={
        overrides.backgroundColour !== "tertiary"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="wrapper">
        {blocks[0]}
        <div className="latest-post-grid">
          <div className="newest">
            <Link
              to={
                "/" +
                data.wp.themeOptions.blogPath +
                "/" +
                data.allWpPost.nodes[0].slug +
                "/"
              }
              data-override-color={
                overrides.featuredPostTextColour !== "on-primary"
                  ? overrides.featuredPostTextColour
                  : null
              }
            >
              {image}
              <span
                className={
                  "con-wrap background-" +
                  overrides.featuredPostBackgroundColour
                }
              >
                <span className="date">{data.allWpPost.nodes[0].date}</span>
                <span className="title">{data.allWpPost.nodes[0].title}</span>
                {ReactHtmlParser(
                  data.allWpPost.nodes[0].excerpt
                    .replace("<p>", '<span class="excerpt">')
                    .replace("</p>", "</span>")
                )}
              </span>
            </Link>
          </div>
          <div className="more">
            {data.allWpPost.nodes[1] ? (
              <>
                <Link
                  to={
                    "/" +
                    data.wp.themeOptions.blogPath +
                    "/" +
                    data.allWpPost.nodes[1].slug +
                    "/"
                  }
                  className="more-link"
                  data-override-color={
                    overrides.postLinkTextColour !== "on-tertiary"
                      ? overrides.featuredPostTextColour
                      : null
                  }
                >
                  <span className="date">{data.allWpPost.nodes[1].date}</span>
                  <span className="title">{data.allWpPost.nodes[1].title}</span>
                  {ReactHtmlParser(
                    data.allWpPost.nodes[1].excerpt
                      .replace("<p>", '<span class="excerpt">')
                      .replace("</p>", "</span>")
                  )}
                </Link>
              </>
            ) : (
              ""
            )}

            {data.allWpPost.nodes[2] ? (
              <>
                <Link
                  to={
                    "/" +
                    data.wp.themeOptions.blogPath +
                    "/" +
                    data.allWpPost.nodes[2].slug +
                    "/"
                  }
                  className="more-link"
                  data-override-color={
                    overrides.postLinkTextColour !== "on-tertiary"
                      ? overrides.featuredPostTextColour
                      : null
                  }
                >
                  <hr
                    data-override-background={
                      overrides.postLinkTextColour !== "on-tertiary"
                        ? overrides.featuredPostTextColour
                        : null
                    }
                  />
                  <span className="date">{data.allWpPost.nodes[2].date}</span>
                  <span className="title">{data.allWpPost.nodes[2].title}</span>
                  {ReactHtmlParser(
                    data.allWpPost.nodes[2].excerpt
                      .replace("<p>", '<span class="excerpt">')
                      .replace("</p>", "</span>")
                  )}
                </Link>
              </>
            ) : (
              ""
            )}

            {data.allWpPost.nodes[3] ? (
              <>
                <Link
                  to={
                    "/" +
                    data.wp.themeOptions.blogPath +
                    "/" +
                    data.allWpPost.nodes[3].slug +
                    "/"
                  }
                  className="more-link"
                  data-override-color={
                    overrides.postLinkTextColour !== "on-tertiary"
                      ? overrides.featuredPostTextColour
                      : null
                  }
                >
                  <hr
                    data-override-background={
                      overrides.postLinkTextColour !== "on-tertiary"
                        ? overrides.featuredPostTextColour
                        : null
                    }
                  />
                  <span className="date">{data.allWpPost.nodes[3].date}</span>
                  <span className="title">{data.allWpPost.nodes[3].title}</span>
                  {ReactHtmlParser(
                    data.allWpPost.nodes[3].excerpt
                      .replace("<p>", '<span class="excerpt">')
                      .replace("</p>", "</span>")
                  )}
                </Link>
              </>
            ) : (
              ""
            )}
            {blocks[1]}
          </div>
        </div>
      </div>
    </div>
  )
}

AcfLatestPostsGridBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

// This is the GraphQL Fragment that will be used within Gatsby queries
export const AcfLatestPostsGridBlockFragment = graphql`
  fragment AcfLatestPostsGridBlock on WpBlock {
    ... on WpAcfLatestPostsGridBlock {
      name
      attributes {
        __typename
        ... on WpAcfLatestPostsGridBlockAttributes {
          className
        }
      }
      BlockLatestPostsGrid {
        category {
          id
          termTaxonomyId
        }
      }
    }
  }
`

export const AcfLatestPostsGridBlockPreview = `
... on AcfLatestPostsGridBlock {
  name
  attributes {
    __typename
    ... on AcfLatestPostsGridBlockAttributes {
      className
    }
  }
  BlockLatestPostsGrid {
    category {
      id
      termTaxonomyId
    }
  }
}
`
