export default theme => ({ // eslint-disable-line
    iconRow: {
        padding: '24px 0 0',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: theme.palette.tertiary.main,
        [theme.breakpoints.up('md')]: {
            padding: '48px 0 24px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '120px 0 96px',
        },
        '& .wrapper': {
            display: 'flex',
            flex: '0 0 100%',
            flexWrap: 'wrap',
            flexDirection: 'row',
            [theme.breakpoints.up('md')]: {
                flexWrap: 'nowrap',
            },
            '& > div': {
                flex: '0 0 100%',
                margin: '0 0 24px',
                [theme.breakpoints.up('sm')]: {
                    flex: '0 0 50%',
                },
                [theme.breakpoints.up('md')]: {
                    flex: '0 1 auto',
                },
            }
        }
    },
})