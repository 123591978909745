export default theme => ({ // eslint-disable-line
    pageListing: {
        padding: '24px 0 0',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: theme.palette.tertiary.main,
        [theme.breakpoints.up('md')]: {
            padding: '48px 0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '90px 0',
        },
        '& .wrapper': {
            '& > .MuiTypography-root': {
                marginBottom: 32,
                color: theme.palette.tertiary.contrastText,
            },
            '& .listing': {
                display: 'flex',
                flex: '0 0 100%',
                flexWrap: 'wrap',
                flexDirection: 'row',
                '& > .side-card': {
                    flex: '0 0 calc(100% - 24px)',
                    margin: '0 12px 24px',borderRadius: theme.border.radius,
                    overflow: 'hidden',
                    backgroundColor: theme.palette.secondary.main,
                    boxShadow: theme.shadow,
                    [theme.breakpoints.up('sm')]: {
                        display: 'flex',
                        '& .gatsby-image-wrapper': {
                            flex: '0 0 225px',
                            maxWidth: 225,
                        }
                    },
                    [theme.breakpoints.up('md')]: {
                        flex: '0 1 calc(50% - 24px)',
                        margin: '12px 12px',
                    },
                    '& .con-wrap': {
                        padding: 32,
                        display: 'flex',
                        flexDirection: 'column',
                        '& .min-height': {
                            minHeight: 150,
                            paddingBottom: 1,
                        },
                        '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
                            color: theme.palette.secondary.contrastText,
                            padding: 0,
                        },
                    },
                    '& .links': {
                        '& > a': {
                            margin: 0,
                        }
                    }
                },
            }
        }
    }
})