import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';
import {CoreHeadingBlock} from "../CoreHeadingBlock";
import PreviewImage from '../../PreviewImage';

const useStyles = makeStyles(theme => styles(theme));

export const BblManagerInfoBlock = (props) => {

    const data = useStaticQuery(graphql`
        query managerInfoOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        managerInfo {
                            backgroundColour
                            tagColour
                            headerColour
                            paragraphColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.managerInfo;

    if(props.innerBlocks[0].attributes.textColor === "" && overrides.paragraphColour !== 'on-secondary') {
        props.innerBlocks[0].attributes.textColor = overrides.paragraphColour;
    }
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.managerInfo} `}
                data-override-background={overrides.backgroundColour !== 'secondary' ? overrides.backgroundColour : null}
    >
        <div className="wrapper">
            <PreviewImage 
              image={props.pageContext.acfFields.managerPhoto.localFile} 
              fallbackUrl={props.pageContext.acfFields.managerPhoto.sourceUrl} 
              alt={props.pageContext.acfFields.managerForename} 
              fixedPadding="100%"
            />
            <div className="info">
                <span className="tag" data-override-color={overrides.tagColour !== 'on-secondary' ? overrides.tagColour : null}>
                    Your Manager
                </span>
                <CoreHeadingBlock 
                    attributes={{
                        textAlign: "left",
                        anchor: "",
                        className: "",
                        content: props.pageContext.acfFields.managerForename + ' ' + props.pageContext.acfFields.managerSurname,
                        level: 3,
                        textColor: overrides.headerColour !== 'on-secondary' ? overrides.headerColour : 'on-secondary',
                        backgroundColor: "",
                        __typename: "WpCoreHeadingBlockAttributes",
                    }}
                    innerBlocks={[]} 
                />
                {blocks.map((block) => {
                    return block;
                })}
            </div>
        </div>
    </div>
};

export const BblManagerInfoBlockFragment = graphql`
  fragment BblManagerInfoBlock on WpBlock {
    ... on WpBblManagerInfoBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP Prefixes
export const BblManagerInfoBlockPreview = `
    ... on BblManagerInfoBlock {
        name
        attributes {
            className
        }
    }
`;