const styles = theme => ({
  bannerSlider: {
    padding: 12,

    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      padding: 16,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 450,
    },
    "& .slick-slider": {
      [theme.breakpoints.down("sm")]: {
        "& .slick-list": {
          overflow: "visible",
        },
      },
      "& .slick-dots": {
        top: 12,
        left: 24,
        right: 24,
        width: "calc(100% - 48px)",
        display: "flex !important",
        flexDirection: "row",
        justifyContent: "flex-end",
        [theme.breakpoints.up("md")]: {
          bottom: 12,
          left: 32,
          right: 32,
          top: "auto",
        },
        "& li": {
          margin: 8,
          width: 16,
          height: 16,
          overflow: "hidden",
          "& button": {
            margin: 0,
            padding: 0,
            backgroundColor: theme.palette.common.white,
            borderRadius: 8,
            width: 16,
            height: 16,
            fontSize: 0,
            opacity: 0.25,
            "&::before": {
              display: "none",
            },
          },
          "&.slick-active button": {
            opacity: 1,
          },
        },
      },
    },
  },
})

export default styles
