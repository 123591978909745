export default theme => ({ // eslint-disable-line
    textIntro: {
        backgroundColor: theme.palette.background.main,
        margin: '0 auto',
        padding: '50px 0 34px',
        [theme.breakpoints.up('md')]: {
            padding: '110px 0 104px',
            '& .MuiTypography-root': {
                maxWidth: '66.6666%',
                marginRight: '33.333%',
            },
            '& p': {
                maxWidth: '58.333%',
                marginRight: '41.666%',
            }
        },
        '& .MuiTypography-root, & p': {
            marginBottom: 24,
        },
        '&[data-thin="true"]': {
            [theme.breakpoints.up('lg')]: {
                '& .MuiTypography-root, & p': {
                    maxWidth: '41.666%',
                    marginRight: '58.333%',
                }
            },
        }
    },
})