export default theme => ({ // eslint-disable-line
    imageContentRows: {
        '& .inner:nth-of-type(even)': {
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
            },
            '& .con-wrap': {
                [theme.breakpoints.up('md')]: {
                    paddingLeft: 0,
                    paddingRight: '80px',
                },
            },
        },
        '&[data-override-starting-side="even"]': {
            '& .inner:nth-of-type(odd)': {
                [theme.breakpoints.up('md')]: {
                    flexDirection: 'row-reverse',
                },
                '& .con-wrap': {
                    [theme.breakpoints.up('md')]: {
                        paddingLeft: 0,
                        paddingRight: '80px',
                    },
                },
            },
            '& .inner:nth-of-type(even)': {
                [theme.breakpoints.up('md')]: {
                    flexDirection: 'row',
                },
                '& .con-wrap': {
                    [theme.breakpoints.up('md')]: {
                        paddingRight: 0,
                        paddingLeft: '80px',
                    },
                },
            },
        }
    },
})