import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const BblCalloutCtaBlock = (props) => {
    
    const data = useStaticQuery(graphql`
        query calloutCtaOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        calloutCta {
                            backgroundColour
                            textColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.calloutCta;

    if(props.innerBlocks[0].attributes.textColor === "" && overrides.textColour !== 'on-tertiary') {
        props.innerBlocks[0].attributes.textColor = overrides.textColour;
    }

    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={` ${props.attributes.className ? props.attributes.className : ''} ${classes.calloutCta} `}
                data-override-background={ overrides.backgroundColour !== 'tertiary' ? overrides.backgroundColour : null }  
    >
        <div className="wrapper">
            {blocks.map((block) => {
                return block;
            })}
        </div>
    </div>
};

export const BblCalloutCtaBlockFragment = graphql`
  fragment BblCalloutCtaBlock on WpBlock {
    ... on WpBblCalloutCtaBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblCalloutCtaBlockPreview = `
    ... on BblCalloutCtaBlock {
        name
        attributes {
            className
        }
    }
`;