export default theme => ({  // eslint-disable-line
  googleMap: {
    height: '100vh',
    minHeight: 320,
    maxHeight: 768,
    width: '100%'
  },
  mapMarker: {
    width: 44,
    height: 44,
    transform: 'translateY(-44px)',
    overflow: 'visible',
    color: theme.palette.primary.main,
    fontSize: 0,
    textAlign: 'center',
    filter: 'drop-shadow(' + theme.shadow + ')',
    '& svg': {
        width: 44,
        height: 44,
        display: 'inline-block',
        verticalAlign: 'baseline',
    }
  }
})