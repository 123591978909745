export default theme => ({ // eslint-disable-line
    imageContentRowsInner: {
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        '& .image-wrap': {
            [theme.breakpoints.up('md')]: {
                maxWidth: 'calc(50% + 12px)',
                flex: '0 0 calc(50% + 12px)',
            }
        },
        '& .con-wrap': {
            padding: '30px 0',
            [theme.breakpoints.up('md')]: {
                maxWidth: 'calc(50% - 24px)',
                flex: '0 0 calc(50% - 24px)',
                paddingLeft: '80px',
            },
            [theme.breakpoints.up(1140)]: {
                maxWidth: 559,
                flex: '0 0 559px',
            },
            '& .MuiTypography-root ': {
                color: theme.palette.primary.contrastText,
                marginBottom: 24,
            }, 
            '& p': {
                color: theme.palette.primary.contrastText,
                marginBottom: 24,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 40,
                },
            },
            '& > a': {
                margin: '0 12px !important',
            }
        },
    },
})