import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import {buildBlocks} from "../../../services/builder";
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const AcfBackgroundImageContainerBlock = (props) => {
  const blocks = buildBlocks(props.innerBlocks, props.attributes, props.pageContext);

  const classes = useStyles();

  // console.log(props);

  return <div className={`${props.attributes.className ? props.attributes.className : ""} ${classes.backgroundImageContainer} `}
              style={{ backgroundImage: 'url(' + props.block.blockBackgroundImageContent.backgroundImage?.localFile?.childImageSharp?.original?.src + ')' }}
  >
    {blocks.map((block) => {
      return block;
    })}
  </div>
};

AcfBackgroundImageContainerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const AcfBackgroundImageContainerBlockFragment = graphql`
  fragment AcfBackgroundImageContainerBlock on WpBlock {
    ... on WpAcfBackgroundImageContainerBlock {
      name
      attributes {
          className
      }
      blockBackgroundImageContent {
        backgroundImage {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    } 
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const AcfBackgroundImageContainerBlockPreview = `
  ... on AcfBackgroundImageContainerBlock {
    name
    attributes {
        className
    }
    blockBackgroundImageContent {
      backgroundImage {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`;