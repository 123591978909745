import React from "react"
import ReactHtmlParser from "react-html-parser"
import PropTypes from "prop-types"
import { transformLists } from "../../../helpers/"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const CoreListBlock = props => {
  // console.log(props);
  const classes = useStyles()
  if (props.attributes.ordered === true) {
    return (
      <ol
        className={` ${
          props.attributes.className ? props.attributes.className : ""
        } ${classes.list}`}
        start={props.attributes.start !== 0 ? props.attributes.start : 1}
        data-override-color={
          props.attributes.textColor !== ""
            ? props.attributes.textColor
            : "initial"
        }
        data-override-background={
          props.attributes.backgroundColor !== ""
            ? props.attributes.backgroundColor
            : "initial"
        }
      >
        {ReactHtmlParser(props.attributes.values, {
          transform: transformLists,
        })}
      </ol>
    )
  }

  return (
    <ul
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.list}`}
      data-override-color={
        props.attributes.textColor !== ""
          ? props.attributes.textColor
          : "initial"
      }
      data-override-background={
        props.attributes.backgroundColor !== ""
          ? props.attributes.backgroundColor
          : "initial"
      }
    >
      {ReactHtmlParser(props.attributes.values, { transform: transformLists })}
    </ul>
  )
}

CoreListBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CoreListBlockFragment = graphql`
  fragment CoreListBlock on WpBlock {
    ... on WpCoreListBlock {
      name
      attributes {
        ... on WpCoreListBlockAttributes {
          className
          ordered
          reversed
          start
          type
          values
          textColor
          backgroundColor
        }
      }
    }
  }
`

export const CoreListBlockPreview = `
  ...on CoreListBlock {
    name
    attributes {
      ...on CoreListBlockAttributes {
        className
        ordered
        reversed
        start
        type
        values
        textColor
        backgroundColor
      }
    }
  }
  `
