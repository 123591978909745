export default theme => ({ // eslint-disable-line
    splitPostTitle: {
        backgroundColor: theme.palette.background.default,
        padding: '28px 0',
        '& .wrapper': {
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
            },
            '& .image-wrap': {
                margin: 12,
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 calc(50% - 24px)',
                    maxWidth: 'calc(50% - 24px)',
                },
            },
            '& .con-wrap': {
                backgroundColor: theme.palette.primary.main,
                padding: 24,
                margin: 12,
                [theme.breakpoints.up('md')]: {
                    padding: 40,
                    paddingBottom: 24,
                    flex: '0 0 calc(50% - 24px)',
                    maxWidth: 'calc(50% - 24px)',
                },
                [theme.breakpoints.up('lg')]: {
                    padding: 72,
                    paddingBottom: 24,
                },
                '& > * ': {
                    padding: 0,
                },
                '& .MuiTypography-root': {
                    marginBottom: 32,
                    color: theme.palette.primary.contrastText,
                },
                '& p': {
                    color: theme.palette.primary.contrastText,
                    marginBottom: 32,
                    '&.date, &.category': {
                        display: 'inline-block',
                        width: '50%',
                        fontSize: 12,
                        letterSpacing: '0.2em',
                        lineHeight: '20px',
                        fontWeight: 'bold',
                        padding: '0 12px',
                        textTransform: 'uppercase',
                        '& svg': {
                            width: 16,
                            height: 16,
                            display: 'inline-block',
                            verticalAlign: 'top',
                            marginTop: 2,
                            marginRight: 5,
                        }
                    },
                    '&.category': {
                        textAlign: 'right',
                    }
                }
            }            
        },
        '& .wrapper.extras': {
            padding: 12,
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                paddingTop: 48,
                marginBottom: -60,
                flexDirection: 'row',
            },
            '& button.go-back': {
                color: theme.palette.accent.main,
                textDecoration: 'underline',
                fontWeight: 'bold',
                fontFamily: theme.bodyFont,
                backgroundColor: 'transparent',
                padding: '12px 0',
                border: 'none',
                letterSpacing: '0.008em',
                fontSize: 16,
                [theme.breakpoints.up('md')]: {
                    fontSize: 18,
                },
                '& svg': {
                    width: 16,
                    height: 16,
                    display: 'inline-block',
                    marginRight: 5,
                    marginTop: 2,
                    verticalAlign: 'top',
                    [theme.breakpoints.up('md')]: {
                        marginTop: 4,
                    },
                }
            },
            '& .SocialShare': {
                position: 'absolute',
                top: 12,
                right: 12,
                [theme.breakpoints.up('md')]: {
                    top: 48,
                }
            }
        }
    },
})