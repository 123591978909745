export default theme => ({ // eslint-disable-line
    postWrapper: {
        paddingBottom: 50,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 100,
        },
        '& .wrapper': {
            maxWidth: 768,
            '& .MuiTypography-root': {
                marginBottom: 32,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 50,
                    '&.MuiTypography-h1, &.MuiTypography-h2, &.MuiTypography-h3': {
                        width: '120%',
                        maxWidth: '120%',
                        marginLeft: '-10%',
                        marginRight: '-10%',
                    }
                }
            },
            '& > p, & > ul, & > ol': {
                lineHeight: 2,
                marginBottom: 32,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 50,
                },
                '&.lead': {
                    lineHeight: 1.66,
                }
            },
            '& > .button': {
                margin: '0 12px 32px',
                [theme.breakpoints.up('md')]: {
                    margin: '0 12px 50px',
                },
            },
            '& > .gatsby-image-wrapper': {
                width: 'calc(100% - 24px)',
                margin: '0 12px 32px',
                [theme.breakpoints.up('md')]: {
                    margin: '0 12px 50px',
                    width: '120%',
                    maxWidth: '120%',
                    marginLeft: '-10%',
                    marginRight: '-10%',
                },
            },
            '& .gallery': {
                marginBottom: 32,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 50,
                }
            }
        }
    },
})