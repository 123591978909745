import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { buildBlocks, mergeBlocks } from "../../../services/builder"
import SplitPageTitle from "../../SplitPageTitle"

export const BblSplitPageTitleBlock = props => {
  const data = useStaticQuery(graphql`
    query splitPageTitleQuery {
      wp {
        themeOptions {
          ThemeOptions {
            splitPageTitle {
              backgroundColour
              titleBackgroundColour
              headerColour
              paragraphColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.splitPageTitle

  if (
    props.innerBlocks[1].attributes.textColor === "" &&
    overrides.headerColour !== "black"
  ) {
    props.innerBlocks[1].attributes.textColor = overrides.headerColour
  }

  if (
    props.innerBlocks[2].attributes.textColor === "" &&
    overrides.paragraphColour !== "black"
  ) {
    props.innerBlocks[2].attributes.textColor = overrides.paragraphColour
  }

  const mergedBlocks = mergeBlocks(props.innerBlocks, props.block.innerImage)
  const blocks = buildBlocks(
    mergedBlocks,
    props.parentAttributes,
    props.pageContext
  )

  return (
    <SplitPageTitle
      className={`inner ${props.attributes.className}`}
      background={
        overrides.backgroundColour !== "primary"
          ? overrides.backgroundColour
          : null
      }
      titleBackgroundColour={
        overrides.titleBackgroundColour !== "background"
          ? overrides.titleBackgroundColour
          : null
      }
      image={blocks[0]}
    >
      {blocks[1]}
      {blocks[2]}
    </SplitPageTitle>
  )
}

export const BblSplitPageTitleBlockFragment = graphql`
  fragment BblSplitPageTitleBlock on WpBlock {
    ... on WpBblSplitPageTitleBlock {
      name
      attributes {
        ... on WpBblSplitPageTitleBlockAttributes {
          className
        }
      }
      innerImage: innerBlocks {
        ... on WpCoreImageBlock {
          name
          originalContent
          attributes {
            __typename
            ... on WpCoreImageBlockAttributes {
              url
              alt
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 992
                    aspectRatio: 1.25
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                    quality: 90
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblSplitPageTitleBlockPreview = `
    ... on BblSplitPageTitleBlock {
        name
        attributes {
            ... on BblSplitPageTitleBlockAttributes {
                className
            }
        }
    }
`
