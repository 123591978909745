import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby";
import { buildBlocks } from "../../../services/builder"
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack"
import { makeStyles } from "@material-ui/styles"
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import PdfPageNumberBar from "../../PdfPageNumberBar"
import styles from "./styles"

// fixes bug with pdf's failing to load: https://github.com/wojtekmaj/react-pdf/issues/321
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const useStyles = makeStyles(theme => styles(theme))

export const AcfPdfReaderBlock = props => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const classes = useStyles()

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  )

  const data = useStaticQuery(graphql`
        query pdfReaderOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        pdfReader {
                            backgroundColour
                            headerColour
                            blurbColour
                            buttonBackgroundColour
                            buttonArrowColour
                            countBarColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.pdfReader;

    if(props.innerBlocks[0].attributes.textColor === "" && overrides.headerColour !== 'black') {
        props.innerBlocks[0].attributes.textColor = overrides.headerColour;
    }

    if(props.innerBlocks[1].attributes.textColor === "" && overrides.paragraphColour !== 'black') {
        props.innerBlocks[1].attributes.textColor = overrides.paragraphColour;
    }

  // url of pdf:
  //   props.block.blockPdfReader.pdfUrl

  return (
    <div className={`${ props.attributes.className ? props.attributes.className : "" } ${classes.pdfReader} `}
         data-override-background={overrides.backgroundColour !== 'background' ? overrides.backgroundColour : null}
    >
      <div className="wrapper">
        {blocks.map(block => {
          return block
        })}
        <div className="pdf">
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
            className="previous"
            data-override-background={overrides.buttonBackgroundColour !== 'primary' ? overrides.buttonBackgroundColour : null}
            data-override-colour={overrides.buttonArrowColour !== 'on-primary' ? overrides.buttonArrowColour : null}
          >
            <ArrowLeftIcon />
          </button>
          <Document
            file={"/uploads/" + props.block.blockPdfReader.pdfFile.fileName}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {/* annotation layer rendering still so added css display: none */}
            <Page pageNumber={pageNumber} />
          </Document>

          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
            className="next"
            data-override-background={overrides.buttonBackgroundColour !== 'primary' ? overrides.buttonBackgroundColour : null}
            data-override-colour={overrides.buttonArrowColour !== 'on-primary' ? overrides.buttonArrowColour : null}
          >
            <ArrowRightIcon />
          </button>

          <PdfPageNumberBar numPages={numPages} pageNumber={pageNumber} override={overrides.countBarColour} />
        </div>
      </div>
    </div>
  )
}

AcfPdfReaderBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfPdfReaderBlockFragment = graphql`
  fragment AcfPdfReaderBlock on WpBlock {
    ... on WpAcfPdfReaderBlock {
      name
      attributes {
        className
      }
      blockPdfReader {
        pdfFile {
          fileName
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfPdfReaderBlockPreview = `
  ... on AcfPdfReaderBlock {
      name
      attributes {
          className
      }
      blockPdfReader {
        pdfFile {
          fileName
        }
      }
  }
`
